<ng-template #spinner>
   <mat-spinner diameter="30" style="margin-top: 0.5em;"></mat-spinner>
</ng-template>

<ng-template #label>
   <ng-container *ngIf="account.active | async as active">
      <mat-icon *ngIf="active.primary" class="star">star</mat-icon>
      <span class="label">
         <span>{{active.name}}</span>
         <ng-container *ngIf="active.accountNumber">
            <ng-container *ngIf="!breakpoints.small">
               <span style="margin: 0 2ex;">|</span>
               <span>ACCOUNT # {{active.accountNumber}}</span>
            </ng-container>
         </ng-container>
      </span>
   </ng-container>
</ng-template>

<ng-container *ngIf="!loading else spinner">
   <ng-container *ngIf="account.list | async as list else spinner">
      <ng-container *ngIf="list.length > 1 else single">
         <div class="account" *ngIf="account.active | async as active" [matMenuTriggerFor]="accountMenu" style="cursor: pointer;">
            <ng-container *ngTemplateOutlet="label"></ng-container>
            <mat-icon *ngIf="(account.list | async).length > 1">arrow_drop_down</mat-icon>

            <mat-menu #accountMenu="matMenu" xPosition="before" class="menu-account-select">
               <button  mat-menu-item *ngFor="let item of list | slice : 0 : account.max" (click)="setActive( item.id )" style="width: 100%; display: flex; justify-content: space-between; align-items: center;">
                  <mat-icon>{{item.id === active.id ? 'label_outline' : item.primary ? 'star' : '' }}</mat-icon>
                  <span style="flex-grow: 1; text-align: left; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">{{item.name}}</span>
                  <ng-container *ngIf="item.accountNumber">
                     <ng-container *ngIf="!breakpoints.small">
                        <span style="margin: 0 2ex;">|</span>
                        <span>ACCOUNT # {{item.accountNumber}}</span>
                     </ng-container>
                  </ng-container>
               </button>

               <button mat-menu-item routerLink="/user/account" *ngIf="list.length > account.max">
                  <mat-icon></mat-icon>More...
               </button>
            </mat-menu>
         </div>
      </ng-container>

      <ng-template #single>
         <div class="account">
            <ng-container *ngTemplateOutlet="label"></ng-container>
         </div>
      </ng-template>
   </ng-container>
</ng-container>
