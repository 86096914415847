<ng-template #spinner>
   <mat-spinner diameter="30"></mat-spinner>
</ng-template>

<div class="user-info" *ngIf="userService.user | async as user">
   <lib-user-avatar size="80"></lib-user-avatar>
   <br/>

   <div class="user-name">{{user.fname}} {{user.lname}}</div>

   <div class="info">
      <div class="user-email">{{user.email}}</div>
   </div>

   <div class="info" *ngIf="(accountService.primary | async) as primary">
      <div color="accent">Primary Account</div>
      <div>{{primary.accountNumber}} - {{primary.name}}</div>
   </div>

   <div class="info" *ngIf="startpage.startPageLabel$ | async as label">
      <div color="accent">Current Start Page</div>
      <div>{{label}}</div>
   </div>

   <form [formGroup]="userForm" *ngIf="userForm">
      <div class="half">
         <ng-container *ngTemplateOutlet="input; context: { title: 'First Name', control: 'fname' }"></ng-container>

         <ng-container *ngTemplateOutlet="input; context: { title: 'Last Name', control: 'lname' }"></ng-container>
      </div>

      <div class="full">
         <ng-container *ngTemplateOutlet="input; context: { title: 'Title', control: 'title' }"></ng-container>
      </div>

      <div class="full">
         <ng-container *ngTemplateOutlet="input; context: { title: 'Phone Number', control: 'phone' }"></ng-container>
      </div>
   </form>

   <div class="buttons" style="margin-top: 20px;" *ngIf="!saving else spinner">
      <button mat-raised-button color="primary" (click)="save()">Save Changes</button>
   </div>
</div>

<ng-template #input let-title="title" let-control="control">
   <mat-form-field *ngIf="userForm.get(control) as c">
      <input matInput [placeholder]="title" [formControl]="c" [maxlength]="lengths[control]" />
      <mat-error>
         <ng-container *ngIf="c.hasError('required')">Required</ng-container>
         <ng-container *ngIf="c.hasError('maxlength')">Too long</ng-container>
      </mat-error>
      <mat-hint align="end" *ngIf="c.dirty && c.value.length as l">{{l || 0}} / {{lengths[control]}}</mat-hint>
   </mat-form-field>
</ng-template>
