import { CartageBase } from './cartage-base';
import { PiecesUnits, WeightUomUnits } from '@library/common/models';

export interface CartageListItem extends CartageBase {
   pieces: number;
   piecesType: PiecesUnits; // piecesUOM
   weight: number;
   weightType: WeightUomUnits; // weightUOM

   chargeTotal: number;
   currency: string;
}

export class CartageListItem extends CartageBase {
   constructor( c?: CartageListItem ) {
      super( c );

      this.pieces = c?.pieces;
      this.piecesType = c?.piecesType;
      this.weight = c?.weight;
      this.weightType = c?.weightType;

      this.chargeTotal = c?.chargeTotal;
      this.currency = c?.currency;

      this.driver = c?.driver || 'Unassigned';
   }

   public toString = (): string => {
      const obj = ({ ...this, status: this.statuses.slice( -1 )[0], toString: null });

      return Object.keys( obj ).map( k => obj[k] ).join( '◬' );
   }
}
