<div class="item-wrapper">
   <mat-form-field *ngIf="form">
      <mat-label *ngIf="showLabel">Driver Name</mat-label>
      <input matInput [formControl]="form" [matAutocomplete]="auto" required />
      <mat-spinner matSuffix diameter="24" *ngIf="savingState === 'saving'"></mat-spinner>
      <span matSuffix [@checkfade] *ngIf="savingState === 'saved'">
         <mat-icon color="primary">check</mat-icon>
      </span>
   </mat-form-field>

   <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngFor="let driver of drivers.getDrivers() | async" [value]="driver">{{driver}}</mat-option>
   </mat-autocomplete>
</div>
