import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

import { Calculator, VolUomUnits } from '@library/common/models';
import { VolConvert, volProp, DimConvert } from './convert.pipe';

@Pipe({ name: 'convertvolume' })
export class ConvertVolumePipe implements PipeTransform {
   transform( value: number, from: VolUomUnits, to: VolUomUnits ): number {
      return VolConvert( value, from, to );
   }
}

@Pipe({ name: 'dualvolume' })
export class DualVolumePipe extends DecimalPipe implements PipeTransform {
   transform( value: number, valueIs: VolUomUnits = 'cft' ): string {
      const imperial = super.transform( VolConvert( value, valueIs, 'cft' ).toFixed( 2 ));
      const metric = super.transform( VolConvert( value, valueIs, 'cbm' ).toFixed( 2 ));

      return `${imperial} cft, ${metric} cbm`;
   }
}

@Pipe({ name: 'totalvolume', pure: false })
export class TotalVolumePipe implements PipeTransform {
   transform( value: Calculator[], output: VolUomUnits = 'cft' ): number {
      return value.map( item => {
         return [ item.length, item.width, item.height ]
            .map( dim => DimConvert( dim, item.measure_type, volProp( output )))
            .reduce(( a, c ) => a * c ) * 3 || 0;
      }).reduce(( a, c ) => a + c, 0 );
   }
}
