import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, tap, shareReplay, catchError } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { ResponseWrapper } from '@library/common/http';

import { CacheService } from './cache.service';

import { ShipmentTypes, ShipmentModes, Country, OceanPort } from '@library/common/models';

interface ServiceLevels {
   name: string;
   shipType: string;
   mode: string;
   book: string;
}

@Injectable({
   providedIn: 'root',
})
export class EnumsService {
   url = 'enum';

   _observables: any = {};

   private _prefetchMap: {[ key: string ]: Observable<any>[] } = {
      servicelevels: [ this.servicelevels() ],
      currencies: [ this.currencies() ],
      incoterms: [ this.incoterms() ],
      attachmentTypes: [ this.attachmentTypes() ],
      countries: [ this.countries(), this.oceanPorts() ],
   };

   constructor( private http: HttpClient, private cache: CacheService ) { }

   prefetch( items: string[] ): void {
      items.map( i => this._prefetchMap[i]?.map( e => e.subscribe().unsubscribe() ));
   }

   servicelevels( shipType?: ShipmentTypes, mode?: ShipmentModes, book: boolean = false ): Observable<string[]> {
      return this._get( 'servicelevels' ).pipe(
         map( s => s as any[] ),
         map(( slevels: ServiceLevels[] ) => slevels
            .filter( s => shipType ? s.shipType === shipType : true )
            .filter( s => mode ? s.mode === mode : true )
            .filter( s => book ? s.book : true )
            .map( s => s.name )
         ),
         map( s => [ ...new Set( s ) ]),
         shareReplay(),
      );
   }

   currencies(): Observable<string[]> {
      return this._get( 'currencies' );
   }

   incoterms( mode?: ShipmentModes ): Observable<string[]> {
      return this._get( [ 'incoterms', mode ].filter( _ => _ ).join( '/' ) );
   }

   attachmentTypes( shipType?: ShipmentTypes, mode?: ShipmentModes ): Observable<string[]> {
      return this._get( [ 'document_types', shipType, mode ].filter( _ => _ ).join( '/' ) );
   }

   containerTypes(): Observable<string[]> {
      return this._get( 'container_types' );
   }

   countries(): Observable<Country[]> {
      return this._get( 'countries' ) as any;
   }

   oceanPorts(): Observable<OceanPort[]> {
      return this._get( 'ocean_ports' ) as any;
   }

   refParties(): Observable<string[]> {
      return of([ 'shipper', 'consignee', 'bill_to', 'project_number', 'foreign_house_bill' ]);
   }

   reftypes(): Observable<string[]> {
      return of([ 'PO', 'SKU', 'CC', 'SO' ]);
   }

   _get( type: string ): Observable<string[]> {
      if ( this.cache.check( type )) { return of( this.cache.retrieve( type )); }
      if ( this._observables[ type ] ) { return this._observables[ type ]; }

      return this._observables[ type ] = this.http.get<ResponseWrapper<string>>( this.url + '/' + type )
         .pipe(
            map( rw => rw.response.collection ),
            tap( s => this.cache.store( type, s, 1400 )), // store for 24 hours
            tap( _ => this._observables[ type ] = null ),
            map( list => list.sort(( a, b ) => a < b ? -1 : a > b ? 1 : 0 )),
            catchError( _ => of([])),
            shareReplay(),
         );
   }
}
