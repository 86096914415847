export const TradeshowLocations = [ 'from', 'to', ] as const;
export type TLocations = typeof TradeshowLocations[number];

export const EmptyTradeshow: Tradeshow[] = [{ location: 'from' }, { location: 'to' }];

export interface TradeshowInterface {
   location: TLocations;
   name?: string;
   booth?: string;
   exhibitor?: string;
}

export class Tradeshow implements TradeshowInterface {
   location: TLocations;
   name?: string;
   booth?: string;
   exhibitor?: string;

   constructor( t?: Tradeshow ) {
      this.location = t?.location || TradeshowLocations[0];
      this.name = t?.name;
      this.booth = t?.booth;
      this.exhibitor = t?.exhibitor;
   }
}

import { FormGroup, FormControl, FormArray } from '@angular/forms';
export function tradeshowGroup( tradeshow: Tradeshow = new Tradeshow() ): FormGroup {
   return new FormGroup({
      location: new FormControl( tradeshow['location'] ),
      name: new FormControl( tradeshow['name'] ),
      booth: new FormControl( tradeshow['booth'] ),
      exhibitor: new FormControl( tradeshow['exhibitor'] ),
   });
}

export function tradeshowArray( tradeshow: Tradeshow[] = EmptyTradeshow ): FormArray {
   const tsArray = new FormArray( [] );

   tradeshow.map( t => tsArray.push( tradeshowGroup( new Tradeshow( t ))));

   return tsArray;
}
