import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { ResponseWrapper } from '@library/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
   providedIn: 'root'
})
export class RecoveryService {

   url = 'activityfeed/recover/';

   constructor( public http: HttpClient ) { }

   recoverItem<T>( id: string ): Observable<T> {
      if ( !id ) { throw new Error( 'Missing ID' ); }

      return this.http.get<ResponseWrapper<T>>( `${this.url}${id}` ).pipe(
         map( rw => rw.response.collection[0] ),
      );
   }
}
