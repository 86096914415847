import { Leg } from './cartage-leg';
import { BillingParty } from '@library/common/models';
import { CartageStatus } from './cartage-status';

import { CartageItemTypes } from './cartage-item-types';

export const CartageBlockedActionTypes = [ 'status', 'driver', 'manifest', 'note', 'attachment','charge' ] as const;
export type CartageBlockedActionTypes = typeof CartageBlockedActionTypes[ number ];

export interface CartageBase {
   type: CartageItemTypes;
   job: number; // freight order number

   proNumber: string; // freight order pro number

   predecessorLeg?: Leg; // predecessor freight order, used for delivery type
   successorLeg?: Leg; // successor freight order, used for pickup type

   pickupLocation: BillingParty;
   pickupDate: string;
   pickupTime: string;

   deliveryLocation: BillingParty;
   deliveryDate: string;
   deliveryTime: string;

   serviceLevel: string;

   driver?: string;

   dangerousGoods?: boolean;

   origin?: string; // Forwarding order origin port
   destination?: string; // Forwarding order destination port

   blockedActions: CartageBlockedActionTypes[];

   statuses: CartageStatus[];
}

export class CartageBase implements CartageBase {
   constructor( c?: CartageBase ) {
      this.type = c?.type || CartageItemTypes[0];
      this.job = c?.job;

      this.proNumber = c?.proNumber;

      if ( c?.predecessorLeg ) { this.predecessorLeg = c?.predecessorLeg; }
      if ( c?.successorLeg ) { this.successorLeg = c?.successorLeg; }

      this.pickupLocation = c?.pickupLocation;
      this.pickupDate = c?.pickupDate;
      this.pickupTime = c?.pickupTime;

      this.deliveryLocation = c?.deliveryLocation;
      this.deliveryDate = c?.deliveryDate;
      this.deliveryTime = c?.deliveryTime;

      this.serviceLevel = c?.serviceLevel || 'None';

      if ( c?.driver ) { this.driver = c?.driver; }

      if ( c?.dangerousGoods ) { this.dangerousGoods = c?.dangerousGoods; }

      if ( c?.origin ) { this.origin = c?.origin; }
      if ( c?.destination ) { this.destination = c?.destination; }

      this.blockedActions = c?.blockedActions || [];

      this.statuses = c?.statuses;
   }
}
