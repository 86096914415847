import { Component, OnInit, OnDestroy } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { ErrorMessage } from '@library/common/http';
import { ErrorMessageService } from '@library/core/services';

@Component({
   selector: 'lib-menu-error-bar',
   templateUrl: './error-bar.component.html',
   styleUrls: ['./error-bar.component.scss']
})
export class ErrorBarComponent implements OnInit, OnDestroy {

   errors$: Observable<ErrorMessage[]>;
   warnings$: Observable<ErrorMessage[]>;
   notifications$: Observable<ErrorMessage[]>;

   show = new Set();

   _onDestroy: Subject<void> = new Subject();

   constructor( public errors: ErrorMessageService ) { }

   ngOnInit() {
      const observables = {
         errors$: 'error',
         warnings$: 'alert',
         notifications$: 'notification'
      };

      Object.keys( observables ).map( k => this[k] = this.errors.messages.pipe(
         takeUntil( this._onDestroy ),
         map( messages => messages.filter( m => m.type.toLowerCase() === observables[k].toLowerCase() )),
      ));
   }

   ngOnDestroy() {
      this._onDestroy.next();
      this._onDestroy.complete();
   }

   clearType( type: ErrorMessage['type'] ) {
      this.errors.messages.getValue()
         .filter( m => m.type === type )
         .map( m => this.errors.clear( m.id ));
   }
}
