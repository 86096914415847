export function formatTime( time: string, field: string ): string {
   if ( !time ) { return; }
   if ( time.length === 1 ) { time = '0' + time; }
   if ( time.length === 2 ) { time = time + '00'; }
   if ( time.length === 3 ) { time = '0' + time; }

   const match = time.match( /^([\d]{2}):?([\d]{2})$/ );
   if ( match ) {
      time = match[1] + ':' + match[2];
      this.shipment.shipmentForm.get( field ).setValue( time );
   }

   return time;
}

import { AbstractControl } from '@angular/forms';
export function formatTime2( time: string, control: AbstractControl ) {
   if ( !time ) { return; }

   if ( time.length === 1 ) { time = '0' + time; }
   if ( time.length === 2 ) { time = time + '00'; }
   if ( time.length === 3 ) { time = '0' + time; }

   const [ match, hours, minutes ] = time.match( /^([\d]{2}):?([\d]{2})$/ );
   if ( match ) {
      control.setValue( hours + ':' + minutes );
      control.updateValueAndValidity();
   }

   return time;
}

export const TimeRegEx = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
export function validateTime( time: string ): boolean {
   if ( !time ) { return; }

   return !!time.match( TimeRegEx );
}
