import * as faker from 'faker';
import { optional } from '@library/common/functions';

import { MockBillingParty } from '@library/common/models/mock';

import { Leg } from '../cartage-leg';

export class MockLeg extends Leg {
   constructor() {
      super({
         carrier: faker.company.companyName(),
         proNumber: faker.random.alphaNumeric( 12 ),
         serviceLevel: faker.random.arrayElement([ 'Next Day', 'White Glove', '4 Day', '3-5 Deferred Day' ]),
      });

      if ( optional() ) { this.accountNumber = faker.datatype.number( 9999999999 ).toString(); }

      if ( optional() ) { this.shipper = new MockBillingParty(); }
      if ( optional() ) { this.consignee = new MockBillingParty(); }
   }
}
