import { Pipe, PipeTransform } from '@angular/core';

import { SpacersPipe } from './spacers.pipe';

@Pipe({ name: 'readable' })
export class ReadablePipe extends SpacersPipe implements PipeTransform {
   transform( value: string ): string {
      if ( !value ) { return; }

      value = super.transform( value );

      const regex = /\b(?![A-Z])(?:[a-z]){4,}/g;

      return value.replace( regex, ( txt => txt[0].toUpperCase() + txt.substr( 1 ).toLowerCase() ));
   }
}
