import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

// Formats a time string based on moment formatting
@Pipe({ name: 'time' })
export class TimePipe implements PipeTransform {
   transform( time: string, pattern: string = 'HH:mm' ): string {
      const [ , hours = '0', minutes = '0', seconds = '0'] = time?.match( /^([01]?\d|2[0-3])(?::([0-5]?\d))?(?::([0-5]?\d))?$/ ) || [];

      return moment({ hour: +hours, minute: +minutes, second: +seconds }).format( pattern );
   }
}
