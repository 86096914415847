import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

import { animate, style, transition, trigger } from '@angular/animations';
import { DriverService } from './driver.service';

@Component({
   selector: 'app-driver-update',
   templateUrl: './driver-update.component.html',
   styleUrls: ['./driver-update.component.scss', '../cartage-update.scss'],
   animations: [
      trigger( 'checkfade', [
         transition( ':enter', [
            style({ opacity: 0 }),
            animate( 200 ),
            animate( 1000 ),
            animate( 1000, style({ opacity: 0 })),
         ]),
      ]),
   ],
})
export class DriverUpdateComponent implements OnInit {

   @Input() readonly form: FormControl;
   @Input() readonly savingState: 'none' | 'saving' | 'saved' = 'none';
   @Input() readonly showLabel: boolean = false;

   constructor(
      public drivers: DriverService,
   ) { }

   ngOnInit() {
   }
}
