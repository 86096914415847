import { Injectable } from '@angular/core';

import {
   HttpInterceptor,
   HttpRequest,
   HttpHandler,
   HttpEvent,
   HttpResponse,
   HTTP_INTERCEPTORS,
} from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { isAssets } from './httpFunctions';

@Injectable()
export class MockHttpInterceptorBase implements HttpInterceptor {
   constructor() {}

   mockService: any;

   intercept(
      request: HttpRequest<any>,
      next: HttpHandler,
   ): Observable<HttpEvent<any>> {
      if ( !this.isMock() || isAssets( request.url ) ) {
         return next.handle( request );
      }

      const collection = this.mockService.mock( request.method + request.url, request.body );

      console.log( '* mock interceptor responding with collection', collection );

      return of( new HttpResponse<any>({ body: collection }) as HttpEvent<any> ).pipe( delay( 2000 ));
   }

   isMock(): boolean {
      return false;
   }
}

export let mockHttpInterceptor = {
   provide: HTTP_INTERCEPTORS,
   useClass: MockHttpInterceptorBase,
   multi: true
};
