import { NgModule } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

@NgModule({ })
export class SvgIconsModule {
   constructor(
      iconRegistry: MatIconRegistry,
      sanitizer: DomSanitizer,
   ) {
      const menuIcons = [
         'admin',
         'activityfeed',
         'addressbook',
         'cartage',
         'createshipment',
         'dashboard',
         'invoices',
         'reports',
         'shipments',
         'templates',
         'track',
      ];
      const typeIcons = [
         'domestic',
         'international',
      ];
      const modeIcons = [
         'road',
         'air',
         'ocean',
      ];
      const summaryIcons = [
         'total',
         'new',
         'active',
         'complete',
      ];
      const milestoneIcons = [
         'arranged_pickup',
         'arrived_terminal',
         'arrivedair',
         'arrivedocean',
         'booked',
         'cancelled',
         'confirmed',
         'delivered',
         'departedair',
         'departedocean',
         'gated',
         'intransit',
         'outfordelivery',
         'pickedup',
         'pod',
         'tendered',
      ];
      const oceanCalculatorIcons = [
         'FCL', 'LCL'
      ];
      const attachmentTypeIcons = [
         'JPG', 'PDF', 'PNG', 'TIFF', 'TXT'
      ];

      const icons = [
         ...menuIcons,
         ...typeIcons,
         ...modeIcons,
         ...summaryIcons,
         ...milestoneIcons,
         ...oceanCalculatorIcons,
         ...attachmentTypeIcons,
      ];

      icons.forEach( icon => {
         const path = 'assets/icons/' + icon + '.svg';

         iconRegistry.addSvgIcon(
            icon,
            sanitizer.bypassSecurityTrustResourceUrl( path ));
      });
   }
}
