import { Component, OnDestroy, Input } from '@angular/core';
import { FormArray } from '@angular/forms';

import { Observable, Subject } from 'rxjs';
import { tap, takeUntil } from 'rxjs/operators';

import { Attachment } from '@library/common';

import { AttachmentService } from '../attachment.service';

@Component({
   selector: 'lib-attachment-create',
   templateUrl: './attachment-create.component.html',
   styleUrls: ['./attachment-create.component.scss']
})
export class AttachmentCreateComponent implements OnDestroy {

   @Input() readonly form: FormArray;

   @Input() readonly clearable: boolean = true;

   private _attachmentTypesEnum: Observable<string[]>;
   @Input() set attachmentTypesEnum( value: Observable<string[]>) {
      this._attachmentTypesEnum = value;

      value.pipe(
         tap( types => {
            ( this.form.value as Attachment[] ).forEach( ( a, index ) => {
               if ( a.type && !types.includes( a.type )) {
                  this.form.controls[index].setValue({ ...a, type: null });
                  console.log('Mismatch');
               }
            });
         }),
         takeUntil( this._onDestroy ),
      ).subscribe();
   }

   get attachmentTypesEnum(): Observable<string[]> { return this._attachmentTypesEnum; }

   _onDestroy: Subject<void> = new Subject();

   constructor(
      public attachmentService: AttachmentService,
   ) { }

   ngOnDestroy() {
      this._onDestroy.next();
      this._onDestroy.complete();
   }

   attachFile( event ): void {
      const files: FileList = event.target.files;

      this.attachmentService.getFormArray( files ).subscribe( attachments => {
         attachments.controls.forEach( a => this.form.push( a ));
      }, err => {
         console.error( err );
      });
   }

   elementFocused( id: string ): boolean {
      return id === document.activeElement.id;
   }
}
