<ng-template #spinner>
   <mat-spinner diameter="24"></mat-spinner>
</ng-template>

<ng-container *ngFor="let charge of form.controls; index as i; first as first; last as last">
   <div class="item-wrapper" [formGroup]="charge">
      <div class="type">
         <mat-form-field *ngIf="cartageEnums.chargeTypes( type ) | async as chargeTypes else spinner">
            <mat-label>Type</mat-label>
            <mat-select formControlName="type" required>
               <mat-option *ngFor="let chargeType of chargeTypes" [value]="chargeType">{{chargeType}}</mat-option>
            </mat-select>
         </mat-form-field>
      </div>

      <div class="amount">
         <mat-form-field *ngIf="enums.currencies() | async as currencies else spinner" class="dual-input">
            <mat-label>Amount</mat-label>
            <input matInput type="number" min="0" formControlName="amount" style="width: 70%;" required />
            <mat-select formControlName="currency" style="width: 30%;" required>
               <mat-option *ngFor="let currency of currencies" [value]="currency">{{currency}}</mat-option>
            </mat-select>
         </mat-form-field>
      </div>

      <div *ngIf="clearable && !first" class="button">
         <button mat-icon-button class="warn-button" matTooltip="REMOVE CHARGE"matTooltipPosition="before" (click)="form.removeAt( i )">
            <mat-icon>clear</mat-icon>
         </button>
      </div>
   </div>

   <mat-divider *ngIf="!last"></mat-divider>
</ng-container>

<div class="add-button">
   <button mat-stroked-button color="accent" (click)="addGroup()">
      <mat-icon>add</mat-icon> ADD CHARGE
   </button>
</div>
