import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseWrapper } from '@library/common/http';
import { CacheService } from '@library/core/services';

import { CartageItemTypes } from './models';

import { Observable, of } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';

@Injectable({
   providedIn: 'root'
})
export class CartageEnumsService {
   url = 'enum';

   _observables: any = {};

   constructor(
      private http: HttpClient,
      private cache: CacheService
   ) { }

   attachmentTypes( type?: CartageItemTypes ): Observable<string[]> {
      return this._get( [ 'attachment_types', type ].filter( _ => _ ).join( '/' ));
   }

   statusTypes( type?: CartageItemTypes ): Observable<{ code: string, label: string}[]> {
      return this._get( [ 'status_types', type ].filter( _ => _ ).join( '/' ));
   }

   chargeTypes( type?: CartageItemTypes ): Observable<string[]> {
      return this._get( [ 'charge_types', type ].filter( _ => _ ).join( '/' ));
   }

   _get<T>( type: string ): Observable<T[]> {
      if ( this.cache.check( type )) { return of( this.cache.retrieve( type )); }
      if ( this._observables[ type ] ) { return this._observables[ type ]; }

      return this._observables[ type ] = this.http.get<ResponseWrapper<T>>( this.url + '/' + type ).pipe(
         map( rw => rw.response.collection ),
         tap( s => this.cache.store( type, s, 1400 )), // store for 24 hours
         tap( _ => this._observables[ type ] = null ),
         map( list => list.sort(( a, b ) => a < b ? -1 : a > b ? 1 : 0 )),
         shareReplay(),
      );
   }

   prefetch( type?: CartageItemTypes ) {
      this.attachmentTypes( type ).subscribe().unsubscribe();
      this.statusTypes( type ).subscribe().unsubscribe();
      this.chargeTypes( type ).subscribe().unsubscribe();
   }

   prefetchAll() {
      CartageItemTypes.map( type => this.prefetch( type ));
   }
}
