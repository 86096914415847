export const AttachmentExtensionTypes = [ 'jpeg', 'jpg', 'pdf', 'tiff', 'tif', 'png', 'txt' ];

export interface Attachment {
   id?: string;

   type: string;
   filename: string;
   extension: string;
   size: number; // size in bytes
   mimetype: string;
   data?: string | ArrayBuffer;

   name?: string;
}

export class Attachment implements Attachment {
   constructor( a?: Attachment ) {
      if ( a?.id ) { this.id = a.id; }

      this.type = a?.type;
      this.filename = a?.filename;
      this.extension = a?.extension;
      this.size = a?.size;
      this.mimetype = a?.mimetype;

      if ( a?.data ) { this.data = a.data; }

      if ( a?.name ) { this.name = a.name; }
   }
}

import { FormGroup, FormControl, FormArray } from '@angular/forms';
export function attachmentGroup( attachment: any = {} ): FormGroup {
   return new FormGroup({
      type: new FormControl( attachment['type'] ),
      filename: new FormControl( attachment['filename'] ),
      extension: new FormControl( attachment['extension'] ),
      size: new FormControl( attachment['size']),
      mimetype: new FormControl( attachment['mimetype'] ),
      data: new FormControl( attachment['data'] ),

      name: new FormControl( attachment['name'] || ''),
   });
}

export function attachmentArray( attachments: Attachment[] = []): FormArray {
   const attachmentsArray = new FormArray( [] );
   attachments.map( d => attachmentsArray.push( attachmentGroup( d )));

   return attachmentsArray;
}
