import { Pipe, PipeTransform } from '@angular/core';

import { DimUomUnits, VolUomUnits, WeightUomUnits } from '@library/common/models';

const convert = {
   inft:  1 / 12,
   incm: 2.54 ,
   inm: 1 / 39.37,
   ftin: 12,
   ftcm: 30.48,
   ftm: 1 / 3.281,
   cmin: 1 / 2.54,
   cmft: 1 / 30.48,
   cmm: 1 / 100,
   min: 39.37,
   mft: 3.281,
   mcm: 100,
};

export function DimConvert( amount: number, from: DimUomUnits, to: DimUomUnits ): number {
   if ( amount <= 0 ) { return amount; }

   if ( convert.hasOwnProperty( from + to )) {
      return +( amount * convert[ from + to ]);
   }

   return amount;
}

export function volProp( unit: VolUomUnits ): DimUomUnits { return unit === 'cft' ? 'ft' : 'm'; }

export function VolConvert( amount: number, from: VolUomUnits, to: VolUomUnits ): number {
   if ( amount <= 0 ) { return amount; }

   const prop = volProp( from ) + volProp( to );

   if ( convert.hasOwnProperty( prop )) {
      return +( amount * Math.pow( convert[ prop ], 3 ));
   }

   return amount;
}

export function WeightConvert( amount: number, from: WeightUomUnits, to: WeightUomUnits ): number {
   const weightConvert = {
      lbskgs: 0.453592,
      kgslbs: 2.20462,
   };

   if ( weightConvert.hasOwnProperty( from + to )) {
      return +( amount * weightConvert[ from + to ]);
   }

   return amount;
}

export const UomConvertTypes = [ 'dim', 'weight', 'volume' ] as const;
export type UomConvertTypes = typeof UomConvertTypes[number];

export type ConvertableValues = DimUomUnits | WeightUomUnits | VolUomUnits;

@Pipe({ name: 'convertUom' })
export class ConvertUomPipe implements PipeTransform {
   transform( value: number, type: UomConvertTypes, from: ConvertableValues, to: ConvertableValues ): number {
      switch ( type ) {
         case 'dim':
            return DimConvert( +value, from as DimUomUnits, to as DimUomUnits );
         case 'weight':
            return WeightConvert( +value, from as WeightUomUnits, to as WeightUomUnits );
         case 'volume':
            return VolConvert( +value, from as VolUomUnits, to as VolUomUnits);
         default:
            return value;
      }
   }
}
