import { CartageItemTypes } from '../cartage-item-types';
import { DeliveryMilestones } from './delivery-milestones';
import { LinehaulMilestones } from './linehaul-milestones';
import { PickupMilestones } from './pickup-milestones';

export interface CartageMilestone {
   code: string;
   label: string;
   icon: string;
   disableAutocheck?: boolean;
   event?: {
      pickup?: string;
      delivery?: string;
      linehaul?: string;
   };
}

export class CartageMilestone implements CartageMilestone {
   constructor( c?: CartageMilestone ) {
      this.code = c?.code;
      this.label = c?.label;
      if ( c?.disableAutocheck ) { this.disableAutocheck = c?.disableAutocheck; }
   }
}

export function CartageMilestones( type: CartageItemTypes, current?: any ) {
   switch ( type ) {
      case 'pickup':
         return new PickupMilestones( current );
      case 'delivery':
         return new DeliveryMilestones( current );
      case 'linehaul':
         return new LinehaulMilestones( current );
      default:
         return null;
   }
}

const [
   New, Confirmed,
   Arranged, PickedUp, Tendered,
   OutForDelivery, ArrivedDestination, POD,
   InTransit, ArrivedTerminal, Recovered
]: CartageMilestone[] = [
   { code: 'new', label: 'New', icon: 'new' },
   { code: 'confirmed', label: 'Alert Confirmed', icon: 'confirmed', event: {
         pickup: 'pConfirmed',
         delivery: 'dConfirmed',
      }
   },

   { code: 'arranged', label: 'Arranged Pickup', icon: 'arranged_pickup', event: { pickup: 'pArranged' }},
   { code: 'pickedup', label: 'Picked Up', icon: 'pickedup', event: { pickup: 'pPickedUp' }},
   { code: 'tendered', label: 'Tendered to Carrier', icon: 'delivered', event: { pickup: 'pTendered' }},

   { code: 'out', label: 'Out for Delivery', icon: 'outfordelivery', event: { delivery: 'dOut' }},
   { code: 'arrivedDestination', label: 'Arrived Destination', icon: 'delivered', event: { delivery: 'dArrivedDestination' }},
   { code: 'pod', label: 'POD', icon: 'pod', event: { delivery: 'dPOD' }},

   { code: 'intransit', label: 'In Transit', icon: 'intransit', event: { linehaul: 'lIntransit' }},
   { code: 'arrivedTerminal', label: 'Arrived Destination Terminal', icon: 'arrived_terminal', event: { linehaul: 'lArrivedTerminal' }},
   { code: 'recovered', label: 'Recovered', icon: 'delivered', event: { linehaul: 'lRecovered' }},
];

export const pickupMilestones: CartageMilestone[] = [
   New, Confirmed, Arranged, PickedUp, Tendered
];

export const deliveryMilestones: CartageMilestone[] = [
   New, Confirmed, OutForDelivery, ArrivedDestination, POD
];

export const linehaulMilestones: CartageMilestone[] = [
   New, InTransit, ArrivedTerminal, Recovered
];

export function CartageMilestonesMap( type: CartageItemTypes = null ): CartageMilestone[] {
   switch ( type ) {
      case 'pickup':
         return [ ...pickupMilestones ];
      case 'delivery':
         return [ ...deliveryMilestones ];
      case 'linehaul':
         return [ ...linehaulMilestones ];
      default:
         return [ ...pickupMilestones, ...deliveryMilestones, ...linehaulMilestones ];
   }
}
