<div class="item-wrapper" [formGroup]="form">
   <mat-form-field class="date">
      <mat-label>Date</mat-label>
      <input matInput required formControlName="date" placeholder="mm/dd/yyyy" [matDatepicker]="datepicker">
      <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
      <mat-datepicker #datepicker [touchUi]="true"></mat-datepicker>
   </mat-form-field>

   <mat-form-field class="time">
      <mat-label>Time</mat-label>
      <input #input matInput placeholder="hh:mm" required autocomplete="off" formControlName="time" (click)="breakpoints.large ? '' : openClock()">
      <mat-icon matSuffix class="clock" (click)="openClock()" zindex="-1">schedule</mat-icon>
   </mat-form-field>

   <mat-form-field class="quantity dual-input">
      <mat-label>Quantity</mat-label>
      <input matInput type="number" min="0" formControlName="pieces" style="width: 70%;" required />
      <mat-select formControlName="piecesUom" style="width: 30%;" required>
         <mat-option *ngFor="let unit of piecesUnits" [value]="unit">{{unit}}</mat-option>
      </mat-select>
   </mat-form-field>

   <mat-form-field class="name">
      <mat-label>Name</mat-label>
      <input matInput formControlName="name" required />
   </mat-form-field>
</div>

<div class="add-button">
   <button mat-stroked-button color="accent" (click)="addSignature()">
      <mat-icon>gesture</mat-icon> {{this.form.get( 'attachmentData' ).value[0] ? 'UPDATE SIGNATURE' : 'ADD SIGNATURE'}}
   </button>
</div>

<div *ngIf="this.form.get( 'attachmentData' ).value[0]" class="signature-display-wrapper">
   <div class="signature-display">
      <img [src]="getSignatureImage()"/>

      <button mat-icon-button color="warn" matTooltip="Remove Signature" (click)="this.form.get( 'attachmentData' ).setValue([])">
         <mat-icon>clear</mat-icon>
      </button>
   </div>
</div>
