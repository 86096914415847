import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LongPressDirective } from './longpress.directive';
import { RadinputDirective } from './radinput.directive';

@NgModule({
  declarations: [
     LongPressDirective,
     RadinputDirective,
  ],
  imports: [
    CommonModule
  ],
  exports: [
     LongPressDirective,
     RadinputDirective,
  ]
})
export class DirectivesModule { }
