import { Component } from '@angular/core';

import { BreakpointService } from '@library/core/services';

import { AccountService } from '@library/modules/user';

@Component({
   selector: 'lib-account-select',
   templateUrl: './account-select.component.html',
   styleUrls: ['./account-select.component.scss']
})
export class AccountSelectComponent {

   loading = false;

   constructor(
      public account: AccountService,
      public breakpoints: BreakpointService,
   ) { }

   setActive( id: number ): void {
      this.loading = true;
      this.account.switchAccount( id ).subscribe(
         _ => this.loading = false );
   }
}
