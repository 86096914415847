import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { MatMenuTrigger, MatMenu } from '@angular/material/menu';

import { fromEvent } from 'rxjs';

import { UserService } from '@library/modules/user';

import { BreakpointService, KeyboardShortcutsService } from '@library/core/services';

@Component({
   selector: 'lib-quick-reports',
   templateUrl: './quick-reports.component.html',
   styleUrls: ['./quick-reports.component.scss']
})
export class QuickReportsComponent implements OnInit, OnDestroy {

   @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
   @ViewChild(MatMenu) menu: MatMenu;

   badges = false;

   constructor(
      public user: UserService,
      public breakpoints: BreakpointService,
      private router: Router,
      private ks: KeyboardShortcutsService,
   ) {}

   ngOnInit() {
      this.ks.register$({
         description: 'Launch a Quick Report',
         key: 'Q',
         keyDisplay: 'Shift + Q',
         svgIcon: 'reports',
      }).subscribe( () => {
         this.trigger.openMenu();
         this.badges = true;
         const sub = fromEvent( window, 'keydown' ).subscribe(( e: KeyboardEvent ) => {
            if ( new RegExp( /[1-9]/ ).test( e.key )) {
               this.user.pinned_reports.subscribe( list => {
                  list.filter(( _, i ) => i === +e.key )
                     .map( r => this.router.navigate([ '/report/run/', r.id ]));
               });
            }
            this.trigger?.closeMenu();
            sub.unsubscribe();
         });
      });

      this.ks.escape$.subscribe( _ => this.trigger.closeMenu())
   }

   ngOnDestroy() {
      this.ks.remove( 'Q' );
   }
}
