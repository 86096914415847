<ng-container *ngIf="pagination.loading">
   <ng-container *ngIf="breakpoints.small">
      <div class="paginator">

         <div style="position: relative; width: {{diameter}}px; height: {{diameter}}px;">

            <mat-progress-spinner [diameter]="diameter" mode="determinate" [value]="pagination.loadingPercent" style="z-index: 1;"></mat-progress-spinner>

            <div class="center" style="opacity: 0.7;">
               <mat-progress-spinner [diameter]="diameter" color="primary" mode="indeterminate"></mat-progress-spinner>
            </div>

            <div class="percent" color="primary" class="center" style="font-size: {{diameter / 5}}px;">
               {{pagination.loadingPercent}}%
            </div>
         </div>
      </div>
      <div style="text-align: center;">
         <button mat-button color="primary" (click)="pagination.stop()">
            Stop Loading
         </button>
      </div>
   </ng-container>

   <ng-container *ngIf="!breakpoints.small">
      <div style="padding: 1em;">
         <div style="display: flex; justify-content: space-between; align-items: center;">
            <div class="percent" color="primary" style="margin-bottom: 0.5em;">
               Your data is loading {{pagination.loadingPercent}}%
            </div>

            <div>
               <button mat-button color="primary" (click)="pagination.stop()">Stop</button>
            </div>
      </div>

         <mat-progress-bar [mode]="pagination.loadingPercent ? 'determinate' : 'indeterminate'" [value]="pagination.loadingPercent"></mat-progress-bar>
      </div>
   </ng-container>
</ng-container>
