import * as faker from 'faker';

import { COD, CODTypes } from '../base';

export class MockCOD extends COD {
   constructor() {
      super();

      this.type = faker.random.arrayElement( CODTypes );
      this.amount = faker.finance.amount();
      this.currency = faker.finance.currencyCode();
   }
}
