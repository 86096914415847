import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHeaders,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from './environment';
import { AuthService } from '@library/modules/auth';

import { RadiantHttpInterceptor } from '@library/common/http';

@Injectable()
export class CpHttpInterceptor extends RadiantHttpInterceptor {
   constructor(
      private auth: AuthService,
   ) {
      super();
   }

   origin(): string {
      return environment.origin;
   }

   get version(): string {
      return environment.version || '1.0.0';
   }

   headers( request: HttpRequest<any> ): Observable<HttpHeaders> {
      return this.auth.setAuthHeaders( request.headers ).pipe(
         map( headers => headers.set( 'app-version', this.version )),
      );
   }
}

export let httpInterceptor2 = {
   provide: HTTP_INTERCEPTORS,
   useClass: CpHttpInterceptor,
   multi: true
};
