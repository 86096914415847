<div *ngFor="let type of [ errors$, warnings$, notifications$ ]">
   <div class="error-messages" *ngIf="type | async as errorMessages">
      <div class="messages {{error.type | lowercase}}" *ngIf="errorMessages[0] as error">
         <div class="message">
            {{error.type | uppercase}} - {{error.message}}
         </div>
         <div style="display: flex;">
            <button mat-icon-button *ngIf="errorMessages.length > 1" (click)="show.has(error.type) ? show.delete(error.type) : show.add(error.type)">
               <mat-icon [matBadge]="errorMessages.length" matBadgeColor="accent">
                  {{show.has(error.type) ? 'expand_less' : 'expand_more'}}
               </mat-icon>
            </button>

            <button mat-icon-button (click)="errors.clear( error.id )">
               <mat-icon>close</mat-icon>
            </button>
         </div>
      </div>

      <ng-container *ngIf="errorMessages.length > 1">
         <ng-container *ngFor="let error of errorMessages">
            <div class="messages {{error.type | lowercase}} overflow" *ngIf="show.has(errorMessages[0].type)">
               <div class="message">
                  {{error.type | uppercase}} - {{error.message}}
               </div>
               <div>
                  <button mat-icon-button (click)="errors.clear( error.id )">
                     <mat-icon>close</mat-icon>
                  </button>
               </div>
            </div>
         </ng-container>
      </ng-container>
   </div>
</div>
