export interface Account {
   id: number;
   name?: string;
   accountNumber?: number;
   stationCode?: string;

   primary?: boolean;
   active?: boolean;
   pinned?: boolean;
}

export class Account implements Account {
   constructor( c: any = {} ) {
      this.id = c?.id;
      this.name = c?.name;
      this.accountNumber = c?.accountNumber;
      this.stationCode = c?.stationCode;
      this.primary = c?.primary ?? false;
      this.active = c?.active ?? false;
      this.pinned = c?.pinned ?? false;
   }
}
