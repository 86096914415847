import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filterlist', pure: false })
export class FilterListPipe implements PipeTransform {
   transform( values: any[], property: string, value?: any ) {
      if ( !values.length ) { return values; }

      switch ( typeof value ) {
         case 'string':
            value = ( value ?? '' ).toLowerCase().trim();

            if ( !property ) {
               return values.filter( v => JSON.stringify( v ).toLowerCase().includes( value ));
            }

            return values.filter( v => v[property].toLowerCase().trim().includes( value ));
         case 'undefined':
            return values.filter( v => v.hasOwnProperty( property ));
         default:
            return values.filter( v => v[property] === value );
      }
   }
}
