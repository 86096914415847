import { Component, OnInit } from '@angular/core';

import { KeyboardShortcutsService } from '@library/core/services';

@Component({
   selector: 'lib-keyboard-shortcuts',
   templateUrl: './keyboard-shortcuts.component.html',
   styleUrls: ['./keyboard-shortcuts.component.scss']
})
export class KeyboardShortcutsComponent implements OnInit {

   constructor(
      public ks: KeyboardShortcutsService,
   ) { }

   ngOnInit() {
   }
}
