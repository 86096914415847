import { Component, AfterViewInit, Input, ViewChild, ElementRef } from '@angular/core';

import { AppContainerService } from '@library/core/services';

@Component({
   selector: 'lib-footer',
   templateUrl: './footer.component.html',
   styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements AfterViewInit {

   _showalpha = false;
   @Input() showDisclaimer = false;
   @Input() disclaimer = 'alpha';
   @Input() version = '';

   @ViewChild( 'footer', { static: true }) footer!: ElementRef;

   constructor( private container: AppContainerService ) { }

   ngAfterViewInit() {
      this.container.footerHeight = this.footer.nativeElement.offsetHeight;
   }
}
