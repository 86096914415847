import { trigger, animate, style, transition } from '@angular/animations';

export const squishOut = trigger( 'squishOut', [
      transition( ':leave', [
         style({ position: 'absolute', overflow: 'hidden' }),
         animate( 200, style({  padding: 0, width: '0', opacity: 0 }))
      ])
   ]);

export const slideDown = trigger( 'slideDown', [
      transition( ':enter', [
         style({ height: 0 }),
         animate( 200, style({ height: '*' })),
      ]),
      transition( ':leave', [
         style({ height: '*' }),
         animate( 200, style({ height: 0 })),
      ]),
   ]);
