import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from '@library/modules/auth';
import { UserAccessGuard } from '@library/modules/user';

import {
   TermsAgreeComponent,
   TermsViewComponent,
} from '@library/modules/menu';

import {
   MenuComponent,
} from '@vp/components';

const routes: Routes = [
   { path: '', component: MenuComponent,
      canActivate: [ AuthGuard ],
      canActivateChild: [ AuthGuard, UserAccessGuard ],
      children: [
         { path: 'terms', component: TermsViewComponent },
         {
            path: 'user',
            loadChildren: () => import( '@library/modules/user' ).then(m => m.UserModule),
            // canLoad: [ AuthGuard ],
         },

         { path: 'cartage', loadChildren: () => import('./components/cartage/cartage.module').then(m => m.CartageModule) },
      ]
   },

   { path: 'error', loadChildren: () => import('@library/modules/errors').then(m => m.ErrorsModule ), },
   { path: 'termsagree', component: TermsAgreeComponent },
   { path: 'redirect', loadChildren: () => import( '@library/modules/redirect' ).then( m => m.RedirectModule )},
   { path: '', loadChildren: () => import('@library/modules/login').then(m => m.LoginModule), },

   { path: '**', redirectTo: '/' },
];

@NgModule({
   imports: [ RouterModule.forRoot(
         routes,
         {
             anchorScrolling: 'enabled',
             scrollPositionRestoration: 'enabled',
             // enableTracing: true,
         }
    )],
   exports: [RouterModule]
})
export class AppRoutingModule { }
