<div class="item-wrapper" *ngIf="form">
   <ng-container *ngFor="let manifest of form.controls; index as i; last as last">
      <div class="item">
         <mat-form-field>
            <mat-label>Line Item</mat-label>
            <input matInput [formControl]="manifest" [required]="!last">
            <button mat-icon-button matSuffix *ngIf="!last" tabindex="-1" (click)="form.removeAt( i )">
               <mat-icon>clear</mat-icon>
            </button>
         </mat-form-field>
      </div>
   </ng-container>
</div>

<div class="add-button">
   <button mat-stroked-button color="accent" (click)="startScanner();">
      SCAN BARCODE
   </button>
</div>
