import { titleCase, stringSort } from '@library/common/functions';
import { CartageListItem, CartageItemTypes } from './models';

export const Groups = [ 'type', 'driver', 'status', 'serviceLevel' ] as const;
export type Groups = typeof Groups[number];
export enum GroupsMap {
   type = 'Type',
   driver = 'Driver',
   status = 'Status',
   serviceLevel = 'Service Level',
   // partyName = 'Shipper / Consignee',
   proNumber = 'Pro Number',
   // mlCarrier = 'Carrier',
}

export const Sort = [
   'statusDate',
   'source',
   'destination',
   'proNumber',
   'type',
   'carrierProNumber',
   'driver',
   'pickup',
   'delivery',
] as const;
export type Sort = typeof Sort[number];

export enum SortMap {
   statusDate = 'Status Date',
   source = 'Source Location Name',
   destination = 'Destination Location Name',
   proNumber = 'Freight Order Pro #',
   type = 'Freight Order Type',
   carrierProNumber = 'Carrier Pro Number',
   driver = 'Driver',
   pickup = 'Pickup Date / Time',
   delivery = 'Delivery Date / Time',
}

export const SortingMap = {
   statusDate: ( a, b ) => {
      const first = new Date( a.statuses.slice( -1 )[0].dateTime ).getTime();
      const last = new Date( b.statuses.slice( -1 )[0].dateTime ).getTime();

      return first - last;
   },
   source: ( a, b ) => stringSort( a.pickupLocation?.name, b.pickupLocation?.name ),
   destination: ( a, b ) => stringSort( a.destinationLocation?.name, b.destinationLocation?.name ),
   proNumber: ( a, b ) => stringSort( a?.proNumber, b?.proNumber ),
   type: ( a, b ) => CartageItemTypes.indexOf( a.type ) - CartageItemTypes.indexOf( b.type ),
   carrierProNumber: ( a, b ) => {
      const sortA = a?.predecessorLeg?.proNumber || a?.successorLeg?.proNumber;
      const sortB = b?.predecessorLeg?.proNumber || b?.successorLeg?.proNumber;

      return stringSort( sortA, sortB );
   },
   driver: ( a, b ) => stringSort( a.driver, b.driver ),
   pickup: ( a, b ) => {
      if ( !a.pickupDate || !a.pickupTime || !b.pickupDate || !b.pickupTime ) { return 0; }
      const sortA = new Date( `${a.pickupDate}T${a.pickupTime}` ).getTime();
      const sortB = new Date( `${b.pickupDate}T${b.pickupTime}` ).getTime();

      return sortA - sortB;
   },
   delivery: ( a, b ) => {
      if ( !a.deliveryDate || !a.deliveryTime || !b.deliveryDate || !b.deliveryTime ) { return 0; }
      const sortA = new Date( `${a.deliveryDate}T${a.deliveryTime}` ).getTime();
      const sortB = new Date( `${b.deliveryDate}T${b.deliveryTime}` ).getTime();

      return sortA - sortB;
   },
};

export interface SortedKeys {
   label: string;
   code: string;
   count?: number;
}

export function sortedKeys( list: CartageListItem[], groupBy: Groups ): SortedKeys[] {
   // this function is used for specific sorting lists
   let sortingFunction: ( a: string, b: string ) => number;
   switch ( groupBy ) {
      case 'driver':
         sortingFunction = ( a, _ ) => a === 'Unassigned' ? -1 : 0;
         break;
      case 'type':
         const types = CartageItemTypes as any as string[];
         sortingFunction = ( a, b ) => types.indexOf( a ) - types.indexOf( b );
         break;
      default:
         sortingFunction = ( _a, _b ) => 0;
   }

   return [ ...new Set( list.map( item => {
         switch ( groupBy ) {
            case 'status':
               return item.statuses.slice( -1 )[0].status;
            default:
               return item[groupBy] as string;
         }
      })
   )]
   .sort()
   .sort( sortingFunction )
   .map( key => getLabel( groupBy, key ));
}

export function getLabel( groupBy: string, key: string ): SortedKeys {
   if ( !groupBy || !key ) { return; }

   switch ( groupBy ) {
      default:
         return { label: titleCase( key ) as string, code: key };
   }
}

export interface GroupedBy {
   [ key: string ]: CartageListItem[];
}

export function groupByKey(  list: CartageListItem[], groupBy: Groups ): GroupedBy {
   const obj: { [ key: string ]: CartageListItem[] } = {};

   sortedKeys( list, groupBy ).map( key => key.code ?? 'Misc' )
      .map( key => {
         obj[key] = list.filter( item => {
            switch ( groupBy ) {
               case 'status':
                  return item.statuses.slice( -1 )[0].status === key;
               default:
                  return item[groupBy] === key;
            }
         });
      });

   return obj;
}
