import * as faker from 'faker';

import { Customs } from '../base';

export class MockCustoms implements Customs {
   value: number;
   currency: string;

   constructor() {
      this.value = faker.datatype.number();
      this.currency = faker.finance.currencyCode();
   }
}
