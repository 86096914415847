import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MaterialsModule } from '@library/common/materials';
import { PipesModule } from '@library/core/pipes';

import { SidebarComponent } from './sidebar.component';

@NgModule({
  declarations: [ SidebarComponent ],
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,

    MaterialsModule,
    PipesModule,
  ],
  exports: [ SidebarComponent ]
})
export class SidebarModule { }
