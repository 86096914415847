import { FormGroup, Validators, FormControl, FormArray } from '@angular/forms';

import { TimeRegEx } from '@library/common/functions';
import moment from 'moment';

export interface CartageStatus {
   code: string;
   status?: string; // label
   dateTime: string;
}

export class CartageStatus implements CartageStatus {
   constructor( c?: any ) {
      this.code = c?.code;
      if ( c?.status ) { this.status = c?.status; }
      this.dateTime = c?.dateTime;
      if ( c?.date && c?.time ) {
         this.dateTime = c.date.format('YYYY-MM-DD[T]') + c.time + ':00';
      }
   }
}

export function CartageStatusGroup( status: CartageStatus = new CartageStatus() ): FormGroup {
   return new FormGroup({
      code: new FormControl( status.code || '', [ Validators.required ]),

      date: new FormControl( moment( status.dateTime ) || moment(), [ Validators.required ]),
      time: new FormControl( moment( status.dateTime ).format('HH:mm') || moment().format('HH:mm'), [
         Validators.required,
         Validators.pattern( TimeRegEx )
      ]),
   });
}

export function GetStatusUpdate( updateForm: FormArray ): CartageStatus[] {
   return updateForm.controls.map( c => {
      return new CartageStatus({
         code: c.get('code').value,
         dateTime: c.get('date').value.format('YYYY-MM-DD[T]') + c.get('time').value + ':00',
      });
   });
}
