import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { shareReplay, map, tap } from 'rxjs/operators';

import { User } from './user';
import { UserModification } from './user-modification';
import { PinnedReport } from './pinned-report';
import { Account } from '../account/account';

import { ResponseWrapper } from '@library/common/http';

import { PrefetchService, Preferences } from '@library/core/services';

@Injectable({
   providedIn: 'root',
})
export class UserService {
   url = 'user';

   _user: User;

   observable: Observable<any>;

   constructor(
      private http: HttpClient,
      private prefetch: PrefetchService,
      private prefs: Preferences,
   ) { }

   get user(): Observable<User> {
      if ( this._user ) { return of( this._user ); }

      if ( this.observable ) { return this.observable; }

      // newly logged in user, pull info from the db
      return this.observable = this.http.get<ResponseWrapper<User>>( this.url )
         .pipe(
            map( rw => rw.response.collection[0] ),
            map( user => user ?? throwError ),
            map( user => new User( user )),
            tap( user => {
               this._user = user;
               localStorage.removeItem( 'user' );
            }),
            tap( user => this.prefs.prefs = user.preferences ),
            tap( _ => this.prefs.listen = true ),
            tap( _ => this.prefetch.prefetch() ),
            tap( _ => this.observable = null ),
            shareReplay()
         );
   }

   get accounts(): Observable<Account[]> {
      return this.user.pipe( map( user => user.accounts ));
   }

   get pinned_reports(): Observable<PinnedReport[]> {
      return this.user.pipe( map( user => user.pinned_reports ));
   }

   update( modify: UserModification ): Observable<any> {
      if ( !modify ) { return of( null ); }

      return this.http.patch( this.url, modify ).pipe( tap( _ => this.logout() ));
   }

   logout() {
      this._user = null;
   }

   destroy(): void {
      this.logout();
      localStorage.removeItem( 'user' );
   }
}
