<ng-container *ngIf="!environment.production">
   <button mat-icon-button matTooltip="Switch Theme" [matMenuTriggerFor]="themeMenu" style="margin: 0 0.5em;">
      <mat-icon>palette</mat-icon>
   </button>

   <mat-menu #themeMenu="matMenu">
      <button mat-menu-item *ngFor="let theme of branding.brands" (click)="loadTheme( theme )">
         {{branding.themes[theme].title}}
      </button>
   </mat-menu>
</ng-container>
