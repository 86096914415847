<ng-container *ngIf="!template else template">
   <div class="sidebar" *ngIf="sidebar.show">
      <div #backdrop class="backdrop">
         <div class="sidebar-header">
               <button mat-icon-button (click)="sidebar.close$.next()" *ngIf="!breakpoints.large">
                  <mat-icon>clear</mat-icon>
               </button>
            <span style="flex-grow: 1;">
               <span #contentRef><ng-content select="[title]"></ng-content></span>
               <span *ngIf="!contentRef.innerHTML.trim()">{{title}}</span>
            </span>
            <button mat-icon-button matTooltip="Customize" (click)="expanded = !expanded" *ngIf="header">
               <mat-icon>{{expanded ? 'expand_less' : expandButton}}</mat-icon>
            </button>
         </div>

         <div @slideDown class="active-filters" *ngIf="sidebar.active">
            {{sidebar.active}} Active {{'Filter' | plural : sidebar.active}}

            <button mat-icon-button (click)="sidebar.clear()" matTooltip="Clear Filters">
               <mat-icon>clear</mat-icon>
            </button>
         </div>

         <div @slideDown *ngIf="expanded">
            <ng-content select="[header]"></ng-content>
         </div>
      </div>

      <div class="filters" [style.top.px]="stickyTop">
         <div *ngFor="let filter of sidebar.filters; let i = index">
            <div class="sidebar-title" [class.tall]="editing === filter.id">
               <!-- Display Template -->
               <ng-container *ngIf="editing !== filter.id">
                  <span>
                     <button mat-icon-button *ngIf="!filter.hard" (click)="editing = filter.id;">
                        <mat-icon>edit</mat-icon>
                     </button>

                     <span style="display: inline-flex; flex-direction: column;">
                        <span>{{filter.title}}</span>
                        <span class="sub-label" *ngIf="filter.value">Match: {{filter.value}}</span>
                     </span>
                  </span>

                  <button mat-icon-button (click)="filter.hide = !filter.hide; sidebar.save()" [matTooltip]="filter.hide ? 'Show' : 'Collapse'">
                     <mat-icon>{{filter.hide ? 'expand_more' : 'expand_less' }}</mat-icon>
                  </button>
               </ng-container>

               <!-- Edit Template -->
               <div class="editing" *ngIf="editing === filter.id">
                  <div style="width: 100%; text-align: right;">
                     <button mat-icon-button (click)="move( i, i - 1 )" [disabled]="sidebar.filters[i - 1].hard || i - 1 < 0">
                        <mat-icon>arrow_upward</mat-icon>
                     </button>

                     <button mat-icon-button (click)="move( i, i + 1 )" [disabled]="i + 1 > sidebar.filters.length - 1">
                        <mat-icon style="transform: scaleY( -1 )">arrow_upward</mat-icon>
                     </button>
                  </div>

                  <mat-form-field>
                     <mat-label>Title</mat-label>
                     <input matInput [(ngModel)]="filter.title" (keyup.enter)="sidebar.save(); editing = null" />
                  </mat-form-field>

                  <ng-container *ngIf="filter.matchValue">
                     <mat-form-field>
                        <mat-label>Value Contains (Optional)</mat-label>
                        <input matInput [(ngModel)]="filter.value" (keyup.enter)="sidebar.save(); editing = null" />
                     </mat-form-field>
                  </ng-container>

                  <div class="editing">
                     <button mat-stroked-button color="primary" (click)="sidebar.save(); editing = null">
                        <mat-icon>save</mat-icon> Save
                     </button>

                     <button mat-button (click)="editing = null">
                        Cancel
                     </button>

                     <button mat-button color="warn" (click)="sidebar.remove( filter.id ); editing = null">
                        <mat-icon>clear</mat-icon> Remove
                     </button>
                  </div>
               </div>
            </div>

            <!-- List of Filters -->
            <ng-container *ngIf="!filter.hide && !editing">
               <ng-container *ngFor="let tile of filter.items">
                  <ng-container *ngIf="filter.selectable">
                     <button mat-button class="tile" color="primary" (click)="toggle( tile, filter )">
                        <span class="button-label-wrapper">
                           <div class="label-wrapper">
                              <div class="label">
                                 {{tile.label}}
                              </div>

                              <div class="sub-label" *ngIf="tile.sublabel">
                                 {{tile.sublabel}}
                              </div>
                           </div>
                           <mat-icon>{{tile.selected ? 'check_box' : 'check_box_outline_blank'}}</mat-icon>
                        </span>
                     </button>
                  </ng-container>

                  <ng-container *ngIf="tile.count !== undefined">
                     <button mat-button class="tile" color="primary" [class.is-active]="filter.active?.code?.length && equal(filter.active.code, tile.code)" (click)="toggle( tile, filter )">
                        <span class="button-label-wrapper">
                           <mat-chip>{{tile.count}}</mat-chip>

                           <div class="label-wrapper">
                              <div class="label">
                                 {{tile.count !== 1 && tile.label.length > 1 ? tile.label[1] : tile.label[0]}}
                              </div>

                              <div class="sub-label" *ngIf="tile.sublabel">
                                 {{tile.sublabel}}
                              </div>

                              <div class="sub-label" *ngIf="tile.amount && tile.amount.total">
                                 {{tile.amount.total | currencycode: tile.amount.currency}}
                              </div>
                           </div>

                           <mat-icon *ngIf="tile.icon" [svgIcon]="tile.icon"></mat-icon>
                        </span>
                     </button>
                  </ng-container>
               </ng-container>
            </ng-container>

            <mat-divider></mat-divider>
         </div>

         <div class="sidebar-title" *ngIf="sidebar.toAdd?.length">
            <mat-form-field style="width: 100%;">
               <mat-label>Add</mat-label>
               <mat-select (selectionChange)="sidebar.add( $event.value ); $event.source.value = null;">
                  <mat-option *ngFor="let item of sidebar.toAdd" [value]="item">{{item.title}}</mat-option>
               </mat-select>
            </mat-form-field>
         </div>
      </div>
   </div>
</ng-container>

<mat-chip-list></mat-chip-list>
