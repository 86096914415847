import { Injectable } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Observable, of, ReplaySubject } from 'rxjs';
import { filter, tap, concatMap, catchError, map } from 'rxjs/operators';

import { AppConfigService } from '@library/app.config';
import { ResponseWrapper } from '@library/common/http';
import { Attachment, attachmentGroup } from '@library/common/models';
import { CacheService } from '@library/core/services';

import * as filesaver from 'file-saver';

export interface AttachmentPayload {
   hawb: string;
   attachments: Attachment[];
}

@Injectable({
   providedIn: 'root'
})
export class AttachmentService {
   url = 'attachment';

   allowedMimetypes = [];

   lengths: any = {};

   loading = false;

   savingState = '';

   download$: ReplaySubject<Attachment> = new ReplaySubject();

   constructor(
      private http: HttpClient,
      private cache: CacheService,
      private snackbar: MatSnackBar,
      private appConfig: AppConfigService,
   ) {
      this.download$.pipe(
         filter( a => !!a && !!a.id ),
         tap( _ => this.loading = true ),
         concatMap( a => this.downloadAttachment( a.id )),
         tap( a => {
            if ( !a ) {
               this.snackbar.open( 'Could not download attachment at this time, try again later', 'Ok' );
               this.loading = false;

               return;
            }

            try {
               // ToDo: Improve performance of this block
               const byteCharacters = atob( a.data as string );
               const byteNumbers = new Array( byteCharacters.length );
               for ( let i = 0; i < byteCharacters.length; i++ ) {
                  byteNumbers[i] = byteCharacters.charCodeAt( i );
               }
               const byteArray = new Uint8Array( byteNumbers );
               const blob = new Blob( [byteArray], { type: a.mimetype });

               filesaver.saveAs( blob, a.filename );

            } catch ( error ) {
               console.warn( 'Attachment blob conversion error: ', error );
               this.snackbar.open( 'Could not save attachment', 'Ok' );
            }
         }),
      ).subscribe( _ => this.loading = false );

      this.allowedMimetypes = this.appConfig.getExtendedConfig( 'attachments' ).filter( f => f.id === 'mimetype' )[0]?.enum || [];
   }

   getFormArray( list: FileList, allowedMimetypeList: string[] = this.allowedMimetypes ): Observable<FormArray> {
      return new Observable<FormArray>( observer => {
         const attachments: FormArray = new FormArray( [] );

         Array.from( list ).forEach( file => {
            const reader = new FileReader();
            const attachment: Attachment = new Attachment({
               filename: file.name,
               extension: file.name.split( '.' ).pop(),
               size: file.size,
               mimetype: file.type,
               type: '',
               data: '',
               name: '',
            });

            if ( !allowedMimetypeList.includes( attachment.mimetype ) ) {
               observer.error( 'Invalid mimetype, make sure to restrict file upload or pass an allowed list into this function');
               observer.complete();
            }

            reader.readAsDataURL( file );

            reader.addEventListener( 'load', _ => {
               attachment.data = ( reader.result as string ).split( ',' )[1];

               const group: FormGroup = attachmentGroup( attachment );

               attachments.push( group );

               if ( attachments.length === list.length ) {
                  observer.next( attachments );
                  observer.complete();
               }
            });
         });
      });
   }

   uploadAttachment( payload: AttachmentPayload ): Observable<boolean> {
      this.savingState = 'saving';

      return this.http.post<ResponseWrapper<any>>( this.url, payload ).pipe(
         map( _ => true ),
         tap( _ => this.savingState = 'saved' ),
         catchError( _ => {
            this.savingState = 'show';

            return of( false );
         }),
      );
   }

   downloadAttachment( id: string ): Observable<Attachment> { // this should be returning a blob
      const url =  `${this.url}/${id}`;

      return this.cache.observable( url ) ||
         this.http.get<ResponseWrapper<Attachment>>( url ).pipe(
            map( rw => rw.response.collection[0] ),
            tap( a => this.cache.store( url, a, 60 )),
            catchError( _ => of( null )),
         );
   }
}
