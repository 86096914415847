export interface ReferenceInterface {
   party: string;
   type: string;
   data: string;
}

export class Reference implements ReferenceInterface {
   party: string;
   type: string;
   data: string;

   constructor( r?: Reference ) {
      this.party = r?.party;
      this.type = r?.type;
      this.data = r?.data;
   }

   public toString = (): string => {
      return this.type + '◬' + this.data;
   }
}

import { FormGroup, FormControl, FormArray } from '@angular/forms';
export function refGroup( ref: Reference = new Reference() ): FormGroup {
   return new FormGroup({
      party: new FormControl( ref['party'] ),
      type: new FormControl( ref['type'] ),
      data: new FormControl( ref['data'] )
   }, {
      validators: group => {
         if ( !group.get( 'party' ).value && !group.get( 'data' ).value ) {
            return null;
         }

         const allOf = [
            group.get('party').value ? null : 'Party',
            group.get('data').value ? null : 'Reference Number',
         ].filter( _ => !!_ );

         return allOf.length ? { allOf } : null;
      }
   });
}

export function refArray( references: Reference[] = [] ): FormArray {
   const refs = new FormArray( [] );
   references.filter( r => r['party'] || r['type'] || r['data'] )
      .map( r => refs.push( refGroup( r )));

   return refs;
}
