import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { UserService, UserModification } from '@library/modules/user';
import { BrandingService } from '@library/common/branding';
import { ErrorMessageService } from '@library/core/services';

@Component({
   selector: 'lib-terms-agree',
   template: `
      <div class="t-agree">
         <header>
            <h2 color="primary">{{branding.portal | uppercase}}</h2>
            Our terms have changed, please review and agree below
         </header>

         <section *ngIf="terms">
            <h2 color="primary">
               <mat-checkbox [(ngModel)]="tagree">
                  TERMS OF USE
               </mat-checkbox>
            </h2>

            <div class="content">
               <lib-terms></lib-terms>
            </div>
         </section>

         <section *ngIf="conditions">
            <h2 color="primary">
               <mat-checkbox [(ngModel)]="tcagree">
                  TERMS AND CONDITIONS
               </mat-checkbox>
            </h2>

            <div class="content">
               <lib-conditions></lib-conditions>
            </div>
         </section>

         <div class="actions">
            <button mat-raised-button color="primary" (click)="agree()" [disabled]="!ready()">I Agree</button>
            <button mat-button (click)="disagree()">I Disagree</button>
         </div>
      </div>`,
   styles: [`
      .t-agree {
         height: 100vh;
         padding: 1em;
      }
      section {
         margin-top: 2em;
      }
      section .content {
         max-height: 50vh;
         overflow-y: scroll;
         margin-top: 1em;
         padding: 20px;
         border: 1px solid;
      }
      .actions {
         padding: 2em;
         padding-bottom: 3em;
         text-align: center;
      }
      mat-checkbox * {
         whitespace: unset;
      }
   `],
})
export class TermsAgreeComponent {

   terms = false;
   conditions = false;

   tagree = false;
   tcagree = false;

   constructor(
      public router: Router,
      public error: ErrorMessageService,
      public branding: BrandingService,
      // public nav: NavMenuService,
      private user: UserService,
   ) {
      error.messages.getValue().forEach( message => {
         switch ( message.code ) {
            case 'TOU_NOT_READ':
               this.terms = true;
               break;
            case 'TC_NOT_READ':
               this.conditions = true;
               break;
         }
      });

      if ( !this.terms && !this.conditions ) { this.disagree(); } // why were you sent here?
   }

   ready(): boolean {
      if ( !this.terms && !this.conditions ) { return false; }

      return (( this.terms ) ? this.tagree : true )
          && (( this.conditions ) ? this.tcagree : true );
   }

   agree() {
      // hit http to update user profile
      const modification: UserModification = {};
      if ( this.tagree ) { modification.termsUse = true; }
      if ( this.tcagree ) { modification.termsConditions = true; }
      this.error.reset();

      this.user.update( modification ).subscribe( () => {
         // redirect to home page -- gatekeeper will check again
         this.router.navigate([ '/' ]);
      });
   }

   disagree() {
      this.router.navigate([ 'logout' ]);
   }
}
