import { Injectable } from '@angular/core';

import { DatehandlerService } from './datehandler.service';

import {
   DateTokens,
   TimePeriods,
   RelativeTokens,
   Operand,
   ParsedDateString,
} from './datehandler.model';

@Injectable({
   providedIn: 'root'
})
export class RelativeDateService {

   constructor( private dh: DatehandlerService ) { }

   parseString( dateString: string, relativeDates? ): ParsedDateString {
      if ( !dateString ) { return; }

      let start;
      const math = [];

      if ( relativeDates ) {
         start = Object.keys( relativeDates ).map( key => relativeDates[key] )
            .filter( token => dateString.indexOf( token ) === 0 )
            .sort(( a, b ) => b.length - a.length )[0];
      }

      if ( !start ) {
         // find start string
         start = Object.keys( DateTokens ).map( t => DateTokens[ t ])
            .filter( token => dateString.indexOf( token ) === 0 )
            .sort(( a, b ) => b.length - a.length )[0];
      }

      console.log( 'start', start );

      // Object.keys( DateTokens ).map( t => DateTokens[ t ]).reverse().map( key => {
      //    if ( !start && dateString.indexOf( key ) === 0 ) {
      //       start = dateString.slice( 0, key.length );
      //       dateString = dateString.slice( key.length );
      //    }
      // });

      if ( !start ) { return; }
      dateString = dateString.slice( start.length );

      // find relative operations
      let regexString = '';

      const periods = Object.keys( TimePeriods ).map( key => TimePeriods[key] );
      regexString += '((?:[+-][1-9][0-9]{0,2}[' + periods.join( '' ) + '])|';

      const relative = Object.keys( RelativeTokens ).map( key => RelativeTokens[key] );
      regexString += '(?:[~](?:' + relative.join( '|' ).replace( /([+-])/gi, '\\$1' ) + ')))';

      // grab matches and loop over
      dateString.split( new RegExp( regexString, 'gi' )).map( match => {
         if ( !match.trim().length ) { return; }

         const check = match.slice( 0, 1 );

         switch ( check ) {
            case '~':
               math.push( new Operand({ token: match.slice( 1 ) }) );
               break;
            case '+':
            case '-':
               math.push( new Operand({
                  operation: match.slice( 0, 1 ),
                  amount: match.slice( 1, match.length - 1 ),
                  period: match.slice( match.length - 1 )
               }));
               break;
         }
      });

      return { start, operands: math };
   }

   makeString( date: ParsedDateString ): string {
      if ( !date || !date.start ) { return; }

      return date.start + date.operands.map(( o: any ) => {
         if ( o['token'] ) {
            return '~' + o.token;
         } else {
            return '' + o.operation + o.amount.value + o.period;
         }
      }).join('');
   }

   getDate( date: string | ParsedDateString ): string {
      if ( typeof date !== 'string' ) {
         date = this.makeString( date );
      }

      const ret = this.dh.normalizeDate( date );

      return ret ? ret.toString() : new Date().toString();
   }

   getIsoDate( date: string | ParsedDateString ): string {
      if ( typeof date !== 'string' ) {
         date = this.makeString( date );
      }

      if ( !this.dh.normalizeDate( date )) { return; }

      return this.dh.dateTimeObj.startOf( 'day' ).format();
   }
}
