import { Injectable } from '@angular/core';

import { Subject, pipe } from 'rxjs';
import { map } from 'rxjs/operators';

import {
   Filter,
   FilterBaseToFilter,
   FilterToFilterBase,
} from './sidebar';

import { equal } from '@library/common/functions';
import { Preferences } from '@library/core/services';

export interface SidebarOptions {
   prefName: string;
   filterList: Filter<any>[];
}

@Injectable({
   providedIn: 'root'
})
export class SidebarService {
   show = false;

   prefName: string;
   filterList: Filter<any>[];

   refresh$: Subject<void> = new Subject();
   close$: Subject<void> = new Subject();

   filters: Filter<any>[] = [];

   get active() {
      return this.filters
         .filter( f => f.order !== -1 )
         .filter( f => f.active )
         .filter( f => f.active.code.length )
         .length;
   }

   constructor( private prefs: Preferences ) { }

   sidebarFilter = pipe(
      map(( list: any[] ) => {
         this.filters.filter( f => !f.selectable ).map( f => f.items = null );

         if ( !list.length ) { return list; }

         this.filters.map( filter => {
            if ( !filter.selectable || !filter.items ) {
               filter.items = filter.filterBy( list, filter.value, filter.data );
            }

            if ( filter.order === -1 && !filter.active ) { // set default value
               const pref = ( this.prefs.getPref( this.prefName ) || {} )[filter.id];
               filter.active = filter.items.filter( i => equal( i.code, pref ))[0] || filter.items[0];
            }

            if ( !filter.active || !filter.items || !filter.items.length ) { return; }

            if ( typeof filter.active.code === 'string' ) {
               list = filter.filterList( list, filter.active.code, filter.data );
            } else {
               list = [].concat.apply( [], filter.active.code.map( c => filter.filterList( list, c, filter.data )));
            }
         });

         return list;
      }),
   );

   load( options: SidebarOptions ) {
      if ( !options?.prefName ) { throw new Error( 'Missing prefName declaration for load' ); }
      if ( !options?.filterList ) { throw new Error( 'Missing filterList declaration for load' ); }

      this.prefName = options.prefName;
      this.filterList = options.filterList;

      const pref = this.prefs.getPref( this.prefName ) || {};

      this.filters = this.filterList.filter( f => f.hard ) || [];

      if ( pref.filters ) {
         pref.filters.map( f => FilterBaseToFilter( f, this.filterList ))
            .filter( _ => _ )
            .map( f => this.filters.push( f ));
      }

      this.refresh$.next();
      this.show = true;
   }

   save() {
      if ( !this.prefName ) { throw new Error( 'Missing prefName declaration for save' ); }
      if ( !this.filterList ) { throw new Error( 'Missing filterList declaration for save' ); }

      // Store default filters for things that need it
      this.filters.map( f => this.prefs.clearPref( this.prefName, f.id ));
      this.filters.filter( f => f.order < 0 ).filter( f => f.active ).filter( f => f.active !== f.items[0] )
         .map( f => this.prefs.upsertPref( this.prefName, { [f.id]: f.active.code }));

      // Store all other filters
      const filters = this.filters.filter( f => !f.hard ).map( f => FilterToFilterBase( f, this.filterList ));

      this.prefs.clearPref( this.prefName, 'filters' );

      if ( filters.length ) { this.prefs.upsertPref( this.prefName, { filters }); }

      this.refresh$.next();
   }

   clear( id?: string ) {
      this.filters.filter( f => f.order !== -1 )
         .filter( f => id ? f.id === id : true )
         .map( f => delete( f.active ));

      this.save();
   }

   get toAdd() {
      return this.filterList.filter( f => !this.filters.map( m => m.id ).includes( f.id )) || [];
   }

   add( filter: Filter<any> ) {
      if ( !this.filterList ) { throw new Error( 'Missing filterList declaration for add' ); }

      this.filters.push( filter );
      this.save();
   }

   remove( id: string ) {
      this.filters = this.filters.filter( f => f.id !== id );

      this.save();
   }

   reset() {
      this.prefName = null;
      this.filterList = null;
      this.filters = [];
      this.show = false;
      this.refresh$.next();
   }
}
