<!-- <div class="selection-bar" *ngIf="selectionMode">
   <div>
      {{getCount('selected')}} Attachments Selected
      <button mat-icon-button (click)="clearAllSelected()" style="margin-left: 1em;">
         <mat-icon>cancel</mat-icon>
      </button>
   </div>

   <div *ngIf="breakpoints.xsmall; else notXSmall">
      <button mat-icon-button [matMenuTriggerFor]="actionMenu">
         <mat-icon>more_vert</mat-icon>
      </button>

      <mat-menu #actionMenu="matMenu">
         <button mat-menu-item>
            <mat-icon>get_app</mat-icon>
            <span>Download</span>
         </button>

         <button mat-menu-item>
            <mat-icon>share</mat-icon>
            <span>Email</span>
         </button>
      </mat-menu>
   </div>

   <ng-template #notXSmall>
      <div>
         <button mat-flat-button color="primary" style="margin-right: 1em;" (click)="downloadAttachments()">
            <mat-icon inline="true" style="margin-right: 0.5em;">get_app</mat-icon>
            DOWNLOAD
         </button>
         <button mat-flat-button color="primary" (click)="emailAttachments()">
            <mat-icon inline="true" style="margin-right: 0.5em;">share</mat-icon>
            EMAIL
         </button>
      </div>
   </ng-template>
</div> -->

<div class="filters" *ngIf="( attachments$ | async ).length > pageSize">
   <ng-container *ngIf="!showFilters">
      <button mat-button (click)="showFilters = true">
         <mat-icon>filter_list</mat-icon> Filter Attachments
      </button>
   </ng-container>

   <ng-container *ngIf="showFilters">
      <button mat-icon-button (click)="filterType.reset(); filterName.reset(); showFilters = false">
         <mat-icon>clear</mat-icon>
      </button>

      <mat-form-field class="filter-type">
         <mat-label>Filetype</mat-label>
         <mat-select [formControl]="filterType">
            <mat-option value="">All</mat-option>
            <mat-option *ngFor="let option of attachmentTypes$ | async" [value]="option">
               {{option}}
            </mat-option>
         </mat-select>
      </mat-form-field>

      <mat-form-field class="filter-name">
         <mat-label>Search Filename</mat-label>
         <input matInput type="text" autocomplete="off" [formControl]="filterName">
         <span matSuffix *ngIf="!filterName.value">
            <mat-icon>search</mat-icon>
         </span>
         <span matSuffix *ngIf="filterName.value">
            <button mat-icon-button (click)="filterName.reset()">
               <mat-icon>close</mat-icon>
            </button>
         </span>
      </mat-form-field>
   </ng-container>
</div>

<mat-progress-bar mode="indeterminate" *ngIf="attachmentService.loading" style="margin-bottom: 0.5em;"></mat-progress-bar>

<div class="attachment-list" #attachmentList>
   <ng-container *ngFor="let attachment of displayed$ | async">
      <lib-attachment-card #item [attachment]="attachment" (download)="attachmentService.download$.next(attachment)"></lib-attachment-card>
   </ng-container>
</div>

<mat-paginator #paginator
   *ngIf="( filtered$ | async ).length > pageSize"
   [length]="( filtered$ | async ).length"
   [pageSize]="pageSize"
   (page)="startIndex = $event.pageIndex * $event.pageSize; refresh$.next()"
   hidePageSize = true>
</mat-paginator>
