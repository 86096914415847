import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SizeService {
  // This is a placeholder for a future feature
  // where the user can choose the font-size of the app
  constructor() { }
}
