<div class="menu-activity-bar">
   <div class="breadcrumbs button-ish" *ngIf="!searchActive else searching">
      <ng-container *ngIf="search.showSearch">
         <span (mouseenter)="searchHover = true" (mouseleave)="searchHover = false" (click)="toggleSearchOn()" style="margin-right: 1ex; cursor: pointer;">
            <mat-icon style="vertical-align: middle;">search</mat-icon>
            <span *ngIf="searchHover" @slideIn class="slideable"> Search</span>
         </span>
      </ng-container>

      <ng-container *ngFor="let crumb of breadcrumbs.breadcrumbs; first as first">
         <span *ngIf="!first"> > </span>
         <a class="breadcrumb" (click)="breadcrumbs.goToCrumb( crumb )">{{crumb.label | uppercase}}</a>
      </ng-container>
   </div>

   <lib-account-select *ngIf="!breakpoints.xsmall"></lib-account-select>
</div>

<ng-template #searching>
   <mat-form-field floatLabel="never" style="width: 100%; max-width: 75ex;">
      <mat-label>Search {{breadcrumbs.breadcrumbs[0]}}</mat-label>
      <mat-icon matPrefix style="margin-right: 2ex;">search</mat-icon>
      <input #searchInput matInput [formControl]="searchValue" (keydown.esc)="toggleSearchOff()" />
      <button mat-icon-button matSuffix (click)="toggleSearchOff()" [matTooltip]="breakpoints.large ? 'Esc' : ''">
         <mat-icon>clear</mat-icon>
      </button>
   </mat-form-field>
</ng-template>
