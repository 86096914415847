import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad, Route, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
   constructor(
      private auth: AuthService,
   ) { }

   canActivate( _, state: RouterStateSnapshot ): Observable<boolean> {
      return this.checkLogin( state.url );
   }

   canActivateChild( _, state: RouterStateSnapshot ): Observable<boolean> {
      return this.checkLogin( state.url );
   }

   canLoad( route: Route ): Observable<boolean> {
      return this.checkLogin( `/${route.path}` );
   }

   checkLogin( _url: string ): Observable<boolean> {
      return this.auth.isLoggedIn$.pipe(
         tap( loggedIn => !loggedIn ? this.auth.logout() : null ),
      );
   }
}
