/* tslint:disable no-unused-expression */
import { Component, OnInit, AfterViewInit, Input, ViewChild, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';

import { Observable, Subject, merge } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { ResizeSensor } from 'css-element-queries';

import { BreakpointService } from '@library/core/services';

import { Attachment } from '@library/common/models';
import { AttachmentCardComponent } from '../attachment-card/attachment-card.component';
import { AttachmentService } from '../attachment.service';

@Component({
   selector: 'lib-attachment-select',
   templateUrl: './attachment-select.component.html',
   styleUrls: ['./attachment-select.component.scss']
})
export class AttachmentSelectComponent implements OnInit, AfterViewInit {
   @Input() attachments: Attachment[];

   attachments$: Observable<Attachment[]>;
   filtered$: Observable<Attachment[]>;
   displayed$: Observable<Attachment[]>;
   attachmentTypes$: Observable<string[]>;
   refresh$: Subject<void> = new Subject();

   @ViewChild( 'attachmentList' ) attachmentList!: ElementRef;
   @ViewChildren( 'item' ) items: QueryList<AttachmentCardComponent>;

   // selectionMode = false;

   @ViewChild( MatPaginator ) paginator: MatPaginator;
   pageSize = 1;
   startIndex = 0;
   get endIndex() { return this.startIndex + this.pageSize; }

   showFilters = false;
   filterName: FormControl = new FormControl('');
   filterType: FormControl = new FormControl('');

   constructor(
      public breakpoints: BreakpointService,
      public attachmentService: AttachmentService,
   ) { }

   ngOnInit() {
      merge(
         this.filterName.valueChanges,
         this.filterType.valueChanges,
      ).subscribe( _ => this.paginator?.firstPage() );

      this.attachments$ = merge(
         this.refresh$,
         this.filterName.valueChanges,
         this.filterType.valueChanges,
      ).pipe(
         startWith( true ),
         map( _ => this.attachments ),
         map( attachments => attachments.filter( a => a.extension )),
      );

      this.filtered$ = this.attachments$.pipe(
         map( attachments => {
            if ( this.filterName.value ) {
               attachments = attachments.filter( a =>
                  ( a.name + a.filename ).toLowerCase().includes( this.filterName.value.toLowerCase() ));
            }
            if ( this.filterType.value ) {
               attachments = attachments.filter( a =>
                  a.type.toLowerCase().includes( this.filterType.value.toLowerCase() ));
            }

            return attachments;
         }),
      );

      this.displayed$ = this.filtered$.pipe(
         map( attachments => attachments.slice( this.startIndex, this.endIndex )),
      );

      this.attachmentTypes$ = this.attachments$.pipe(
         map( attachments => attachments.map( a => a.type )),
         map( a => [ ...new Set( a )]),
      );
   }

   ngAfterViewInit() {
      new ResizeSensor( this.attachmentList.nativeElement, () => {
         if ( this.attachments.length < 1 ) { return; }
         if ( !this.items.length ) { return; }

         const width = this.attachmentList.nativeElement.offsetWidth;
         const itemWidth = this.items.first.el.nativeElement.offsetWidth;

         const perRow = Math.floor( width / ( itemWidth + 16 ));

         this.pageSize = perRow * 2;
         this.refresh$.next();
      });
   }
}
