<mat-expansion-panel #panel expanded class="bordered" *ngIf="attachments.length || updateForm.length else panelButton">
   <mat-expansion-panel-header>
      <mat-panel-title>Attachments</mat-panel-title>

      <div (click)="$event.stopPropagation()" *ngIf="panel.expanded" style="margin-right: 1em;">
         <ng-container *ngTemplateOutlet="addButton"></ng-container>

         <lib-save-button [state]="attachmentService.savingState" [disabled]="updateForm.invalid" (save)="save.emit(updateForm.value)" [showCancel]="true" (cancel)="updateForm.clear()"></lib-save-button>
      </div>
   </mat-expansion-panel-header>

   <lib-attachment-create *ngIf="updateForm.length" [form]="updateForm" [attachmentTypesEnum]="attachmentTypesEnum"></lib-attachment-create>

   <lib-attachment-select [attachments]="attachments"></lib-attachment-select>
</mat-expansion-panel>

<button mat-button color="accent" class="expansion-add-button" *ngIf="!attachments" (click)="uploader.click()">
   <mat-icon>add</mat-icon> ADD ATTACHMENT
</button>

<input hidden type="file" #uploader (change)="attachFile($event)" [accept]="attachmentService.allowedMimetypes.join(', ')" multiple="true">

<ng-template #addButton>
   <div  *ngIf="attachmentService.savingState !== 'saving' && !updateForm.length && allowNewAttachments" (click)="uploader.click()">
      <button mat-button color="accent" *ngIf="!breakpoints.xsmall else iconButton">
         <mat-icon>add</mat-icon> ADD ATTACHMENT
      </button>
   </div>

   <ng-template #iconButton>
      <button mat-icon-button color="accent">
         <mat-icon>add</mat-icon>
      </button>
   </ng-template>
</ng-template>

<ng-template #panelButton>
   <button mat-button color="accent" class="expansion-add-button" (click)="uploader.click()">
      <mat-icon>add</mat-icon> ADD ATTACHMENT
   </button>
</ng-template>
