<div class="right">
   <div class="menu-hamburger">
      <button mat-icon-button (click)="opened = !opened">
         <div class="hamburger" [class.is-active]="opened">
            <span class="line"></span>
            <span class="line"></span>
            <span class="line"></span>
         </div>
      </button>
   </div>
</div>

<div [@slideInOut]="opened ? 'in' : 'out'" class="menu-bar">
   <div class="nav-container below">
      <lib-action-menu></lib-action-menu>
   </div>

   <div class="menu-activity-bar">
      <lib-account-select></lib-account-select>
   </div>

   <div class="menu-items">
      <mat-form-field style="width: 100%; padding: 0 1em;" *ngIf="nav.showTracking && access.hasAccess( 'tracking' ) | async">
         <mat-label>TRACK A SHIPMENT</mat-label>
         <mat-icon matPrefix svgIcon="track" style="margin-right: 2ex;"></mat-icon>
         <input matInput placeholder="Enter a HAWB" [(ngModel)]="track_value" (keyup.enter)="track()" />
         <button mat-button matSuffix (click)="track()">Go</button>
      </mat-form-field>

      <button mat-button class="menu-item" *ngFor="let item of nav.items$ | async" [routerLink]="item.path" routerLinkActive="active" (click)="opened = false">
         <mat-icon [svgIcon]="item.icon"></mat-icon>
         {{item.label}}
      </button>
   </div>
</div>
