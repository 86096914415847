export interface RoadMilestones {
   booked: string;
   confirmed: string;
   cancelled: string;
   pickedup: string;
   intransit: string;
   delivery: string;
   delivered: string;
   pod: string;
}

export class RoadMilestones {
   constructor( current?: RoadMilestones ) {
      this.booked    = current && current['booked'] || '';
      this.confirmed = current && current['confirmed'] || '';
      this.cancelled = current && current['cancelled'] || '';
      this.pickedup  = current && current['pickedup'] || '';
      this.intransit = current && current['intransit'] || '';
      this.delivery  = current && current['delivery'] || '';
      this.delivered = current && current['delivered'] || '';
      this.pod       = current && current['pod'] || '';
   }
}
