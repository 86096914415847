import * as faker from 'faker';

import { Reference } from '../base';

export class MockReference extends Reference {
   constructor() {
      super();

      this.party = faker.name.jobTitle();
      this.type = faker.lorem.word().toUpperCase();
      this.data = Array.from(
         {
            length: faker.datatype.number({ min: 15, max: 35}) // between 15 and 35 char
         }
      ).map( _ => faker.datatype.number( 9 ) ).join( '' );
   }
}
