export interface PODInterface {
   date: string;
   time: string;
   timezone: string;
   name: string;
   pieces: number;
}

export class POD implements PODInterface {
   date: string;
   time: string;
   timezone: string;
   name: string;
   pieces: number;

   constructor( pod?: POD ) {
      this.date = pod?.date;
      this.time = pod?.time;
      this.timezone = pod?.timezone;
      this.name = pod?.name;
      this.pieces = pod?.pieces;
   }
}
