/* tslint:disable no-unused-expression */
import { Component, AfterViewInit, ViewChild, ElementRef } from '@angular/core';

import { ResizeSensor } from 'css-element-queries';

@Component({
   selector: 'app-overflow-line',
   inputs: [ 'lines' ],
   template: `
   <div style="position: relative;">
      <div style="overflow: auto;" [style.height.px]="show ? null : height">
         <div #list>
            <ng-content></ng-content>
         </div>
      </div>

      <button mat-icon-button *ngIf="height" (click)="show = !show" style="position: absolute; bottom: 0; right: 0;">
         <mat-icon>{{show ? 'arrow_upward' : 'arrow_downward'}}</mat-icon>
      </button>
   </div>
   <div #line style="height: 1em; visibility: hidden;"></div>
   `,
})
export class OverflowLineComponent implements AfterViewInit {
   lines = 15;
   show = false;

   height: number;

   @ViewChild( 'list' ) list!: ElementRef;
   @ViewChild( 'line' ) line!: ElementRef;

   ngAfterViewInit() {
      new ResizeSensor( this.list.nativeElement, () => this.setHeight() );
   }

   setHeight() {
      if ( this.show ) { return; }

      const maxHeight = this.line.nativeElement.offsetHeight * this.lines;

      if ( this.list.nativeElement.offsetHeight >= maxHeight ) {
         this.height = maxHeight;
      } else {
         this.height = null;
      }
   }
}
