import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';

import { Observable, from, BehaviorSubject, throwError } from 'rxjs';
import { tap, map, filter, startWith, switchMap, catchError } from 'rxjs/operators';

import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';

import {
   EnvironmentService,
   ErrorMessageService,
   ResetService,
} from '@library/core/services';
import { BrandingService } from '@library/common/branding';
import { UserService } from '@library/modules/user';

import { Auth, CognitoUser } from '@aws-amplify/auth';

export enum AuthStates {
   SignedOut = 'signedout',
   SignedIn = 'signedin',
   VerifyEmail = 'verifyemail',
   ForgotPassword = 'forgotpassword',
   ResetPassword = 'resetpassword',
}

@Injectable({
   providedIn: 'root',
})
export class AuthService {
   lastUrl = '/';
   locked = false;
   initialLogin = false;
   working = false;

   authState$: BehaviorSubject<string> = new BehaviorSubject( AuthStates.SignedOut );

   CognitoUser: CognitoUser;
   activeEmail: string;

   authSnackbar: MatSnackBarRef<any>;

   _loggedIn = false;
   get isLoggedIn$(): Observable<boolean> {
      return this.session.pipe(
         map( session => session?.isValid() || false ),
         map( _ => this.e.environment?.mock ? true : _ ),
         tap( loggedIn => this._loggedIn = loggedIn ),
      );
   }

   get session() {
      return from( Auth.currentSession().catch( _ => null ));
   }

   get currentUser(): Observable<CognitoUser> {
      return from( Auth.currentUserPoolUser({ bypassCache: true }).catch( _ => null ));
   }

   constructor(
      private router: Router,
      private branding: BrandingService,
      private e: EnvironmentService,
      private error: ErrorMessageService,
      private user: UserService,
      private reset: ResetService,
      private snackbar: MatSnackBar,
   ) {
      // listen for branding changes
      this.branding.themeChange$.pipe(
         startWith( true ),
      ).subscribe( _ =>
         Auth.configure({
            authenticationFlowType: 'USER_PASSWORD_AUTH',
            clientMetadata: {
               portal: this.branding.portal,
               brand: this.branding.class,
               origin: document.location.origin,
            }
         })
      );

      // on load match the session state
      this.isLoggedIn$.pipe(
         filter( signedin => !!signedin ),
         switchMap( _ => this.currentUser ),
      ).subscribe( cognitoUser => this.handleSignedInState( cognitoUser ));

      // set last url
      this.router.events.pipe(
         filter( event => event instanceof NavigationEnd ),
         filter(( event: NavigationEnd ) => ![ '/login', '/logout', '/lock' ].includes( event.url )),
      ).subscribe( _ => this.lastUrl = this.router.url );
   }

   handleSignedInState( user: CognitoUser = null ) {
      if ( !user ) { return; }

      this.CognitoUser = user;
      if ( !(user as any).attributes.email_verified ) {
         this.authState$.next( AuthStates.VerifyEmail );
         this.sendVerificationEmail();
         this.working = false;
      } else {
         this.authState$.next( AuthStates.SignedIn );
         this.user.user.pipe(
            catchError( error => {
               if ( error.error.messages?.length ) {
                  console.log( 'sign in error', error.error.messages );
                  this.initialLogin = true;

                  return throwError( error.error.messages[0].message );
               }

               this.logout();

               setTimeout(() => this.error.push( 'This portal is not currently working, please contact your administrator' ), 100 );

               return throwError( 'Cannot retrieve user' );
            }),
         ).subscribe( _ => {
            if ( [ '/login', '/logout', '/lock' ].includes( this.router.url )) {
               this.router.navigate([ '/' ]);
            }
         });
      }
   }

   setAuthHeaders( headers: HttpHeaders ): Observable<HttpHeaders> {
      return this.session.pipe(
         map( session => {
            if ( !session || !session.isValid() ) { return headers; }

            let authHeaders = `Bearer ${session.getAccessToken().jwtToken}`;

            if ( this.initialLogin ) {
               authHeaders += '.' + session.getIdToken().jwtToken;
               this.initialLogin = false;
            }

            headers = headers.set( 'Authorization', authHeaders );
            // console.log( 'set headers', headers );

            return headers;
         }),
      );
   }

   login( username: string, password: string ): Observable<CognitoUser> {
      this.working = true;

      return from( Auth.signIn( username, password )).pipe(
         tap( _ => this.initialLogin = true ),
         // tap( response => console.log( 'response', response )),
         tap( cognitoUser => this.CognitoUser = cognitoUser || null ),
         tap( cognitoUser => this.handleSignedInState( cognitoUser )),
         catchError( error => {
            if ( error.message ) {
               this.authSnackbar = this.snackbar.open( error.message, 'Close' );
               this.authSnackbar.onAction().subscribe( _ => this.authSnackbar.dismiss() );
               this.working = false;
            }

            throw error;
         }),
      );
   }

   logout(): void {
      this.working = true;

      Auth.signOut().then( _ => {
         this.authState$.next( AuthStates.SignedOut );
         localStorage.clear();
         this.user.destroy();
         this.reset.reset();
         this.router.navigate([ 'login' ]);
         this.working = false;
      });
   }

   lock( url: string = this.router.url ): void {
      this.working = true;
      this.lastUrl = url === '/lock' ? '/' : url;
      Auth.signOut().then( _ => {
         this.authState$.next( AuthStates.SignedOut );
         this.router.navigate([ 'login' ]);
         this.working = false;
      });
   }

   sendVerificationEmail() {
      this.working = true;
      this.activeEmail = ( this.CognitoUser as any ).attributes.email;

      Auth.verifyUserAttribute( this.CognitoUser, 'email' ).then( _ => this.working = false );
   }

   verifyEmail( code: string ) {
      this.working = true;

      return from( Auth.verifyUserAttributeSubmit( this.CognitoUser, 'email', code )).pipe(
         switchMap( _ => Auth.currentUserPoolUser({ bypassCache: true })),
         tap( user => this.handleSignedInState( user )),
         tap( _ => this.activeEmail = null ),
         tap( _ => this.working = false ),
      );
   }

   sendForgotPasswordEmail( email: string ) {
      this.working = true;
      this.activeEmail = email;

      Auth.forgotPassword( email ).then( _ => {
         this.working = false;
         this.authState$.next( AuthStates.ResetPassword );
      });
   }

   resetPassword( code: string, password: string ) {
      this.working = true;

      return Auth.forgotPasswordSubmit( this.activeEmail, code, password ).then( _ => {
         this.login( this.activeEmail, password ).subscribe();
         this.activeEmail = null;
      });
   }
}
