import * as faker from 'faker';
import { optional } from '@library/common/functions';

import { Account } from '../account';

export class MockAccount extends Account {
   constructor() {
      super();

      this.id = faker.datatype.number();
      this.name = faker.company.companyName();
      if ( optional() ) { this.accountNumber = faker.finance.account(); }
      if ( optional() ) { this.stationCode = faker.company.companySuffix(); }
      this.primary = false;
      this.active = false;
      this.pinned = optional();
   }
}
