import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { of } from 'rxjs';
import { Observable } from 'rxjs';

export class PasswordRequirement {
   title: string;
   regex: string;
   text: string;
}

@Injectable({
   providedIn: 'root',
})
export class PasswordService {

   url = 'user/password';

   constructor( private http: HttpClient ) {}

   getRequirements(): Observable<PasswordRequirement[]> {
      return of([
         { title: 'minimum', regex: '.{8,}', text: 'Minimum eight characters' },
         { title: 'numeric', regex: '[0-9]', text: 'Contains at least one numeric character' },
         { title: 'capital', regex: '[A-Z]', text: 'Contains at least one capital letter' },
         { title: 'lowercase', regex: '[a-z]', text: 'Contains at least one lower case letter' },
         { title: 'special', regex: '[^a-zA-Z0-9_]', text: 'Contains at least one special character' },
      ]);
   }

   triggerEmail( username: string ): Observable<any> {
      return this.http.get( this.url + '/reset/' + username );
   }

   validateHash( hash: string ): Observable<any> {
      return this.http.get( this.url + '/validate/' + hash );
   }

   updatePassword( hash: string, password: string ): Observable<any> {
      return this.http.patch( this.url + '/update', { resetHash: hash, password });
   }
}
