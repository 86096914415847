import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'total' })
export class TotalPipe implements PipeTransform {
   transform( values: any[], column: string ): number {
      let total = 0;

      if ( !values.length || !column ) { return total; }

      values.map( val => {
         if ( val[column] ) {
            total += val[column];
         }
      });

      return total;
   }
}
