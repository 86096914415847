import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Injectable({
   providedIn: 'root'
})
export class BreakpointService {

   public xsmall = false;
   public small = false;
   public medium = false;
   public large = false;
   public xlarge = false;

   private renderer: Renderer2;

   constructor(
      private breakpointObserver: BreakpointObserver,
      private factory: RendererFactory2,
   ) {
      this.renderer = this.factory.createRenderer( null, null );

      this.breakpointObserver.observe([
         Breakpoints.XSmall,
         Breakpoints.Small,
         Breakpoints.Medium,
         Breakpoints.Large,
         Breakpoints.XLarge,
      ]).subscribe( _ => {
         this.default();

         switch ( true ) {
            case this.breakpointObserver.isMatched( Breakpoints.XLarge ):
               this.xlarge = true;
               this.renderer.addClass( document.documentElement, 'xlarge' );
            case this.breakpointObserver.isMatched( Breakpoints.Large ):
               this.large = true;
               this.renderer.addClass( document.documentElement, 'large' );
               break;
            case this.breakpointObserver.isMatched( Breakpoints.Medium ):
               this.medium = true;
               this.renderer.addClass( document.documentElement, 'medium' );
               break;
            case this.breakpointObserver.isMatched( Breakpoints.XSmall ):
               this.xsmall = true;
               this.renderer.addClass( document.documentElement, 'xsmall' );
            case this.breakpointObserver.isMatched( Breakpoints.Small ):
            default:
               this.small = true;
               this.renderer.addClass( document.documentElement, 'small' );
         }
      });
   }

   default() {
      this.xsmall = false;
      this.small = false;
      this.medium = false;
      this.large = false;
      this.xlarge = false;

      this.renderer.removeClass( document.documentElement, 'xsmall' );
      this.renderer.removeClass( document.documentElement, 'small' );
      this.renderer.removeClass( document.documentElement, 'medium' );
      this.renderer.removeClass( document.documentElement, 'large' );
      this.renderer.removeClass( document.documentElement, 'xlarge' );
   }

   tap() {} // don't worry about this little guy here
}
