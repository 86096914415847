<ng-template #loading>
   <mat-spinner diameter="40"></mat-spinner>

   <button mat-icon-button matTooltip="Log Out" routerLink="/logout" style="margin-left: 1em;">
      <mat-icon>exit_to_app</mat-icon>
   </button>
</ng-template>

<div style="margin: 0 0.5em; height: 4em; display: flex; align-items: center;">
   <ng-container *ngIf="user.user | async as user else loading">
      <lib-user-avatar [matMenuTriggerFor]="userMenu"></lib-user-avatar>

      <mat-menu #userMenu="matMenu">
         <mat-card class="user-card">
            <lib-user-avatar [size]="60"></lib-user-avatar>
            <br/>

            <div class="user-name">{{user.fname}} {{user.lname}}</div>
            <div class="user-email" *ngIf="user.email">{{user.email}}</div>

            <br/>
            <div class="user-title" *ngIf="user.title">{{user.title}}</div>
            <div class="user-phone" *ngIf="user.phone">{{user.phone}}</div>

            <br/>
            <div class="primary-account" *ngIf="account.primary | async as primary">
               {{primary.accountNumber}} - {{primary.name}}
            </div>

            <br/>
            <div class="user-actions">
               <span>
                  <button mat-icon-button matTooltip="Edit" routerLink="/user">
                     <mat-icon>edit</mat-icon>
                  </button>
               </span>
               <span>
                  <button mat-icon-button matTooltip="Reset Password" routerLink="/user/password">
                     <mat-icon>vpn_key</mat-icon>
                  </button>
               </span>
               <span>
                  <button mat-icon-button matTooltip="Lock" routerLink="/lock">
                     <mat-icon>lock</mat-icon>
                  </button>
               </span>
               <span>
                  <button mat-icon-button matTooltip="Log Out" routerLink="/logout">
                     <mat-icon>exit_to_app</mat-icon>
                  </button>
               </span>
            </div>
         </mat-card>
      </mat-menu>
   </ng-container>

</div>
