import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { Subject } from 'rxjs';

import { Theme, Themes } from './themes';

export const ServerMap: {[ key: string ]: string } = {
   radiantdelivers: 'radiant',
   localhost: 'dev',
   airgroup: 'airgroup',
   adcomworldwide: 'adcom',
   dbaco: 'dba',
   sbaglobal: 'sba',
   clippergroup: 'clipper',
   vintage: 'vintage',
   'ha.rglportal': 'hawaiianair',
   'hatest.rglportal': 'hawaiianair',
};

@Injectable({
   providedIn: 'root'
})
export class BrandingService {

   class = 'radiant';

   theme: Theme = Themes.radiant;

   themeChange$: Subject<void> = new Subject();

   get themes() { return Themes; }
   get brands(): string[] {
     return Object.keys( Themes );
   }

   _portal = 'Customer';
   set portal( value ) {
      this._portal = value;
      this.title.setTitle( this.portal );
   }
   get portal() {
      return `${this.theme.title} ${this._portal} Portal`;
   }

   private renderer: Renderer2;

   constructor(
      private factory: RendererFactory2,
      private title: Title,
   ) {
      this.renderer = this.factory.createRenderer( null, null );

      this.setTheme( 'radiant' );
   }

   load(): void {
      const regex = `(${Object.keys( ServerMap ).join( '|' )})`;
      const matches = new RegExp( regex ).exec( window.location.hostname );
      const host = matches ? matches[0] : 'radiant';

      if ( ServerMap[host] ) {
         this.setTheme( ServerMap[host] );
      }
   }

   setTheme( theme: string ) {
      this.class = theme;
      this.theme = Themes[theme];

      this.brands.map( c =>
         this.renderer.removeClass( document.documentElement, c ));

      this.renderer.addClass( document.documentElement, theme );

      const favicon = document.getElementById( 'appFavIcon' );
      if ( favicon ) { this.renderer.removeChild( document.head, favicon ); }

      const link = this.renderer.createElement( 'link' );
      link.id = 'appFavIcon';
      link.rel = 'icon';
      link.type = 'image/x-icon';
      link.href = '/assets/favicons/' + this.theme.favIcon;

      this.renderer.appendChild( document.head, link );

      this.title.setTitle( this.portal );

      this.themeChange$.next();
   }

   // hex can be '#69c', '69C', '#6699CC', or '6699CC'
   // -1 <= lum => 1 --- positive lighter, negative darker
   colorLuminance( hex: string, lum: number ) {
      // validate hex string
      hex = String( hex ).replace( /[^0-9a-f]/gi, '');
      if ( hex.length < 6 ) {
         hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
      }
      lum = lum || 0;

      // convert to decimal and change luminosity
      let rgb = '#';
      let c;
      let i;
      for ( i = 0; i < 3; i++ ) {
         c = parseInt( hex.substr( i * 2, 2 ), 16 );
         c = Math.round( Math.min( Math.max( 0, c + ( c * lum )), 255 )).toString( 16 );
         rgb += ( '00' + c ).substr( c.length );
      }

      return rgb;
   }
}
