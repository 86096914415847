export const mockCountries = [
   { code: 'WK', country: 'Wakanda', },
   {
      code: 'CA', country: 'Canada',
      regions: [
         { code: 'AB', region: 'Alberta' },
         { code: 'BC', region: 'British Columbia' },
         { code: 'NB', region: 'New Brunswick' },
         { code: 'NL', region: 'Newfoundland/Labrad.' },
         { code: 'YT', region: 'Yukon Territory' }
      ],
      postalDetails: {
         regex: '(^[a-zA-Z][0-9][a-zA-Z] [0-9][a-zA-Z][0-9]$)',
         required: false,
         maxLength: 7,
         test: 'A1Z 0Y9'
      },
      ports: [ 'Vancouver', 'Montreal', 'Prince Rupert', ],
   },
   {
      code: 'MX', country: 'Mexico',
      regions: [
         { code: 'BC', region: 'Baja California' },
         { code: 'CHI', region: 'Chihuahua' },
         { code: 'GRO', region: 'Guerrero' },
         { code: 'MEX', region: 'Estado de Mexico' },
         { code: 'OAX', region: 'Oaxaca' },
         { code: 'TAB', region: 'Tabasco' },
      ],
      postalDetails: {
         regex: '^[0-9]{5,5}$',
         required: false,
         maxLength: 5,
         test: '12345'
      },
      ports: [ 'Manzanillo', 'Veracruz', 'Ensenada', ],
   },
   {
      code: 'US', country: 'USA',
      regions: [
         { code: 'CA', region: 'California' },
         { code: 'LA', region: 'Louisiana' },
         { code: 'MS', region: 'Mississippi' },
         { code: 'NM', region: 'New Mexico' },
         { code: 'NY', region: 'New York' },
         { code: 'OR', region: 'Oregon' },
         { code: 'WA', region: 'Washington' },
      ],
      postalDetails: {
         regex: '(^[0-9][0-9][0-9][0-9][0-9]-[0-9][0-9][0-9][0-9]$)|(^[0-9][0-9][0-9][0-9][0-9]$)',
         required: false,
         maxLength: 10,
         test: '12345-1234'
      },
      ports: [ 'Los Angeles', 'New York', 'Seattle-Tacoma', ],
   },
   {
      code: 'TG',
      country: 'Togo',
      postalDetails: {
         regex: '^[a-zA-Z0-9]{1,10}$',
         required: true,
         maxLength: 10
      }
   },
   {
      code: 'GB',
      country: 'United Kingdom',
      regions: [
         {
            code: 'CO',
            region: 'Cornwall'
         },
         {
            code: 'LE',
            region: 'Leicestershire'
         },
         {
            code: 'SE',
            region: 'East Sussex'
         },
         {
            code: 'WC',
            region: 'Worcestershire'
         },
         {
            code: 'WT',
            region: 'Wigtownshire'
         }
      ],
      postalDetails: {
         regex: '^[0-9a-zA-Z ]{0,9}$',
         required: false,
         maxLength: 9
      }
   },
];
