import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
   selector: 'lib-input-error',
   inputs: [ 'control', 'prefix', 'suffix' ],
   template: `<mat-error *ngIf="control">
      <ng-container *ngIf="prefix">{{prefix}}</ng-container>
      <ng-container *ngIf="control.hasError('required') || control.hasError('requiredTrue')">Required </ng-container>
      <ng-container *ngIf="control.hasError('min')">Minimum value {{control.errors.min.min}} </ng-container>
      <ng-container *ngIf="control.hasError('max')">Maxiumum value {{control.errors.max.max}} </ng-container>
      <ng-container *ngIf="control.hasError('maxlength')">Max length {{control.errors.maxlength.requiredLength}} exceeded </ng-container>
      <ng-container *ngIf="control.hasError('minlength')">Min length {{control.errors.minlength.requiredLength}} not met </ng-container>
      <ng-container *ngIf="control.hasError('pattern')">Invalid value </ng-container>
      <ng-container *ngIf="control.hasError('email')">Invalid email </ng-container>
      <ng-container *ngIf="control.hasError('matDatepickerFilter')">Invalid Date </ng-container>
      <ng-container *ngIf="control.hasError('matDatepickerMin')">Needs to be after {{control.errors.matDatepickerMin.min | date: 'shortDate'}} </ng-container>
      <ng-container *ngIf="control.hasError('matDatepickerMax')">Needs to be before {{control.errors.matDatepickerMax.max | date: 'shortDate'}} </ng-container>
      <ng-container *ngIf="control.hasError('bothRequired')">Missing required field {{control.errors?.bothRequired?.missing}}</ng-container>
      <ng-container *ngIf="control.hasError('allOf')">Missing required field<ng-container *ngIf="control.errors?.allOf.length != 1">s</ng-container> {{control.errors?.allOf.join(', ')}}</ng-container>
      <ng-container *ngIf="control.hasError('filesize')">Max file size exceeded (Max: {{control.errors.filesize.max | filesize}} | Current: {{control.errors.filesize.actual | filesize}})</ng-container>
      <ng-container *ngIf="suffix">{{suffix}}</ng-container>
   </mat-error>`
})
export class InputErrorComponent {
   control: FormControl;
   prefix: string;
   suffix: string;
}
