import { NgModule } from '@angular/core';

import { httpInterceptor2 } from './http.interceptor-2';
import { cacheHttpInterceptor } from '@library/common/http';
import { messagesHttpInterceptor } from './http-interceptor-messages';
import { mockHttpInterceptor } from './http-interceptor-mock';

@NgModule({
   providers: [
      cacheHttpInterceptor,
      messagesHttpInterceptor,
      mockHttpInterceptor,
      httpInterceptor2,
   ],
})
export class HttpInteceptorModule {}
