import { FormGroup } from '@angular/forms';

import { Shipment } from './shipment';
import { ShipmentTypes } from './shipment-types';
import { ShipmentModes } from './shipment-modes';

import { DomesticShipment, domesticForBooking, domesticShipmentGroup, domesticShipmentCopy } from './domestic-shipment';
import { InternationalRoadShipment, internationalRoadForBooking, internationalRoadShipmentGroup, internationalRoadShipmentCopy } from './international-road-shipment';
import { InternationalAirShipment, internationalAirForBooking, internationalAirShipmentGroup, internationalAirShipmentCopy } from './international-air-shipment';
import { InternationalOceanShipment, internationalOceanForBooking, internationalOceanShipmentGroup, internationalOceanShipmentCopy } from './international-ocean-shipment';

/**
 * default behavior is domestic road
 *
 * load => returns a data model with passed in shipment information
 * book => returns a sanitized model for booking
 * group => returns the FormGroup for the requested model
 */
export class ShipmentFactory {
   static type({ shipType = ShipmentTypes[0] } = {}): ShipmentTypes {
      return shipType;
   }
   static mode({ mode = ShipmentModes[0] } = {}): ShipmentModes {
      return mode;
   }

   static load( s: any = {} ): Shipment<any, any> {
      const t = ShipmentFactory.type( s );
      const m = ShipmentFactory.mode( s );
      switch ( t ) {
         case 'international':
            switch ( m ) {
               case 'ocean':
                  return new InternationalOceanShipment( s );
               case 'air':
                  return new InternationalAirShipment( s );
               case 'road':
               default:
                  return new InternationalRoadShipment( s );
            }
         case 'domestic':
         default:
            return new DomesticShipment( s );
      }
   }

   static formGroup( s?: any ): FormGroup {
      const t = ShipmentFactory.type( s );
      const m = ShipmentFactory.mode( s );
      switch ( t ) {
         case 'international':
            switch ( m ) {
               case 'ocean':
                  return internationalOceanShipmentGroup({ ...s, mode: 'ocean' });
               case 'air':
                  return internationalAirShipmentGroup({ ...s, mode: 'air' });
               case 'road':
               default:
                  return internationalRoadShipmentGroup({ ...s, mode: 'road' });
            }
         case 'domestic':
         default:
            return domesticShipmentGroup({ ...s, mode: 'road' });
      }
   }

   static book( s?: any ): Shipment<any, any> {
      const t = ShipmentFactory.type( s );
      const m = ShipmentFactory.mode( s );
      switch ( t ) {
         case 'international':
            switch ( m ) {
               case 'ocean':
                  return internationalOceanForBooking( s );
               case 'air':
                  return internationalAirForBooking( s );
               case 'road':
               default:
                  return internationalRoadForBooking( s );
            }
         case 'domestic':
         default:
            return domesticForBooking( s );
      }
   }

   static copy( s?: any, mode: null | 'continue' | 'reverse' = null ): Shipment<any, any> {
      const t = ShipmentFactory.type( s );
      const m = ShipmentFactory.mode ( s );

      switch ( t ) {
         case 'international':
            switch ( m ) {
               case 'ocean':
                  return internationalOceanShipmentCopy( s, mode );
               case 'air':
                  return internationalAirShipmentCopy( s, mode );
               case 'road':
               default:
                  return internationalRoadShipmentCopy( s, mode );
            }
         case 'domestic':
         default:
            return domesticShipmentCopy( s, mode );
      }
   }
}
