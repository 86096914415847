import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule, } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MaterialsModule } from '@library/common/materials';
import { UseravatarModule } from '@library/modules/user';

import { MenuBarComponent } from './menu-bar/menu-bar.component';
import { MenuXsmallComponent } from './menu-xsmall/menu-xsmall.component';
import { UserMenuComponent } from './user-menu/user-menu.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';

import { AccountSelectComponent } from './account-select';
import { ActivityBarComponent } from './activity-bar/activity-bar.component';
import { ErrorBarComponent } from './error-bar/error-bar.component';
import { FooterComponent } from './footer/footer.component';
import { KeyboardShortcutsComponent } from './settings/keyboard-shortcuts/keyboard-shortcuts.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { QuickReportsComponent } from './quick-reports/quick-reports.component';
import { SettingsComponent } from './settings/settings.component';
import { ThemeSwitcherComponent } from './theme-switcher/theme-switcher.component';

import { AvatarModule } from 'ngx-avatar';

import { ActionMenuComponent } from './action-menu/action-menu.component';
import { HelpComponent, HelpTooltipComponent } from './help/help.component';
import { TrackingComponent } from './tracking/tracking.component';

import {
   ConditionsComponent,
   TermsAgreeComponent,
   TermsComponent,
   TermsViewComponent,
} from './terms';

@NgModule({
   declarations: [
      AccountSelectComponent,
      ActionMenuComponent,
      ActivityBarComponent,
      ConditionsComponent,
      ErrorBarComponent,
      FooterComponent,
      HelpComponent,
      HelpTooltipComponent,
      KeyboardShortcutsComponent,
      MenuBarComponent,
      MenuXsmallComponent,
      NavMenuComponent,
      NotificationsComponent,
      QuickReportsComponent,
      SettingsComponent,
      TermsAgreeComponent,
      TermsComponent,
      TermsViewComponent,
      ThemeSwitcherComponent,
      TrackingComponent,
      UserMenuComponent,
   ],
   imports: [
      CommonModule,
      FormsModule, ReactiveFormsModule,
      HttpClientModule,
      RouterModule,
      BrowserAnimationsModule,

      AvatarModule,
      MaterialsModule,
      UseravatarModule,
   ],
   exports: [
      FooterComponent,
      MenuBarComponent,
      TermsAgreeComponent,
      TermsViewComponent,
   ],
})
export class MenuModule { }
