import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialsModule } from '@library/common/materials';

import { HttpPaginationComponent } from './http-pagination.component';

@NgModule({
  declarations: [ HttpPaginationComponent ],
  imports: [
    CommonModule,
    MaterialsModule,
  ],
  exports: [ HttpPaginationComponent ]
})
export class HttpPaginationModule { }
