import { Attachment } from '@library/common/models';
import {
   CartageCharge,
   CartagePod,
   CartageStatus,
} from '../models';

import { BulkUpdateTypes } from '../update';

type Action = string | Attachment | CartageCharge | CartagePod | CartageStatus;

export interface BulkQueue {
   id: string;
   label: string;

   user: string;
   account: string;
   uploaded: Date;

   actionType: BulkUpdateTypes;
   actions: Action[];
   jobs: BulkQueueJob[];
}

export class BulkQueue implements BulkQueue {
   constructor( b?: BulkQueue ) {
      this.id = b?.id;
      this.label = b?.label;

      this.user = b?.user;
      this.account = b?.account;
      this.uploaded = b?.uploaded;

      this.actionType = b?.actionType;
      this.actions = b?.actions;
      this.jobs = b?.jobs;
   }
}

export interface BulkQueueJob {
    job: number;
    completed: boolean;
    error: boolean;
}

export class BulkQueueJob implements BulkQueueJob {
   constructor( i?: BulkQueueJob ) {
      this.job = i?.job;
      this.completed = i?.completed || false;
      this.error = i?.error || false;
   }
}
