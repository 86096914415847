import { Component } from '@angular/core';
import { BreadcrumbService } from '@library/core/services';

import { BrandingService } from '@library/common/branding';

@Component({
   selector: 'lib-terms-view',
   template: `
   <div style="padding: 1em;">
      <h2 color="primary">{{branding.portal | uppercase}}</h2>

      <h2 color="primary">TERMS OF USE</h2>
      <lib-terms></lib-terms>

      <div style="height: 2em;"></div>

      <h2 color="primary">TERMS AND CONDITIONS</h2>
      <lib-conditions></lib-conditions>
   </div>`,
})
export class TermsViewComponent {
   constructor(
      private breadcrumbs: BreadcrumbService,
      public branding: BrandingService,
   ) {
      this.breadcrumbs.newCrumb({ label: 'Terms', path: '/terms' });
   }
}
