import { Component, ElementRef } from '@angular/core';
import { Overlay, OverlayRef, OverlayPositionBuilder } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

import { HelpService } from '../help.service';
import { BreakpointService } from '@library/core/services';

@Component({
  selector: 'lib-menu-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent {
   tooltipRef: OverlayRef;

   constructor(
      public help: HelpService,
      public breakpoints: BreakpointService,
      private overlay: Overlay,
      private overlayPositionBuilder: OverlayPositionBuilder,
   ) {}

   showTooltip( el: ElementRef ) {
      const positionStrategy = this.overlayPositionBuilder
         .flexibleConnectedTo( el )
         .withPositions([{
           originX: 'end',
           originY: 'bottom',
           overlayX: 'end',
           overlayY: 'top',
         }])
         .withDefaultOffsetY( 24 );
      this.tooltipRef = this.overlay.create({ positionStrategy });

      // create portal
      const portal = new ComponentPortal( HelpTooltipComponent );

      this.tooltipRef.attach( portal );
   }

   toggle( el: ElementRef ) {
      if ( this.tooltipRef && this.tooltipRef.hasAttached() ) {
         this.tooltipRef.detach();
      } else {
         this.showTooltip( el );
      }
   }
}

@Component({
   selector: 'lib-menu-help-tooltip',
   template: `
   <div class="tooltip-container mat-elevation-z4">
      <ng-container *ngFor="let tip of help.tooltips">
         {{tip}}<br/>
      </ng-container>
   </div>`,
   styles: [`
      .tooltip-container {
         font-size: 0.8em;
         padding: 0.8em;
         border-radius: 4px;
      }
   `]
})
export class HelpTooltipComponent {
   constructor( public help: HelpService ) { }
}
