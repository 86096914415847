import { Pipe, PipeTransform } from '@angular/core';
import { formatCurrency, getCurrencySymbol } from '@angular/common';

@Pipe({
   name: 'currencycode',
})
export class CurrencyCodePipe implements PipeTransform {
   transform(
      value: number = 0,
      currencyCode: string = 'USD',
      digitsInfo: string = '1.2-2',
      locale: string = 'en',
   ): string | null {
      if ( !value ) { value = 0; }

      const currency: string = currencyCode || 'USD';

      let symbol = getCurrencySymbol( currency, 'narrow', locale );
      if ( !symbol || symbol.length > 1 ) { symbol = ''; }

      return formatCurrency(
        value,
        locale,
        symbol,
        currency,
        digitsInfo,
      ) + ' ' + currency;
   }
}
