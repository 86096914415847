import { AppConfig, FormItem } from '../app.config';

// AppConfigs
import defaultConfig from './default.config.json';
import fourplConfig from './fourpl.config.json';
import hawaiianairConfig from './hawaiianair.config.json';

// FormItems
import billingPartyConfig from './billingparty.config.json';
import contactConfig from './contact.config.json';
import attachmentsConfig from './attachments.config.json';

// Constants
export const ConfigKeys = [
   'default',
   'fourpl',
   'hawaiianair',
] as const;
export type ConfigKey = typeof ConfigKeys[number];

export const ExtendedKeys = [
   'billingparty',
   'contact',
   'attachments',
] as const;
export type ExtendedKey = typeof ExtendedKeys[number];

// Maps
export const ConfigMap: {[ key in ConfigKey ]: AppConfig } = {
   default: defaultConfig,
   fourpl: fourplConfig,
   hawaiianair: hawaiianairConfig,
};

export const FormItemMap: {[ key: string ]: FormItem[] } = {
   billingparty: billingPartyConfig,
   contact: contactConfig,
   attachments: attachmentsConfig,
};
