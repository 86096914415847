import * as faker from 'faker';

import { CartageCharge } from '../cartage-charge';

export class MockCartageCharge extends CartageCharge {
   constructor() {
      super({
         type: faker.company.catchPhrase(),
         amount: faker.datatype.number(),
         currency: 'USD',
      });
   }
}
