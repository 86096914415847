import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component( {
    selector: 'lib-confirm-dialog',
    template: `
      <h2 mat-dialog-title>{{ title }}</h2>
      <div mat-dialog-content>
         {{ msg }}
         <div *ngIf="note"><br/>{{ note }}</div>
         <div *ngIf="alert" class="mat-error"><br/>{{ alert }}</div>
      </div>
      <mat-dialog-actions>
         <button mat-button mat-raised-button [mat-dialog-close]="true">{{ yes }}</button>
         <button mat-button mat-dialog-close>{{ no }}</button>
      </mat-dialog-actions>`
} )
export class ConfirmDialogComponent {

    public title: string;
    public msg: string;
    public note: string;
    public alert: string;
    public yes: string;
    public no: string;

    constructor(
        public dialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject( MAT_DIALOG_DATA ) public data: any
    ) {
        this.title = ( data.title ) ? data.title : 'Confirm';
        this.msg   = ( data.msg )   ? data.msg   : 'Are you sure you wish to perform this action?';
        this.note  = ( data.note )  ? data.note  : null;
        this.alert = ( data.alert ) ? data.alert : null;
        this.yes   = ( data.yes )   ? data.yes   : 'Ok';
        this.no    = ( data.no )    ? data.no    : 'Cancel';
    }
}
