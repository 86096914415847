import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { ErrorMessageService, BreadcrumbService } from '@library/core/services';

@Component({
   selector: 'lib-default-error',
   styles: [
      `.error-message {
         font-weight: bold;
         font-size: 1.2em;
      }`
   ],
   template: `
      <mat-card *ngFor="let error of error.messages | async" color="warn" class="error-message">
         {{error.message}}
      </mat-card>
   `,
})
export class DefaultErrorComponent implements OnDestroy {

   constructor(
      public error: ErrorMessageService,
      private router: Router,
      private breadcrumbs: BreadcrumbService,
   ) {
      this.breadcrumbs.addCrumble( 'error' );

      if ( this.error.none ) {
         console.log( 'directed to error without an error messageArr or message', this.error );
         this.router.navigate([ './' ]);
      }
   }

   ngOnDestroy() {
      this.error.reset();
   }
}
