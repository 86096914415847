import { Account } from '../account/account';
import { PinnedReport } from './pinned-report';

export interface User {
   username: string;
   password?: string;

   fname: string;
   lname: string;

   initials?: string;
   avatar: any;

   title: string;
   email: string;

   phone?: string;

   services: string[];
   accounts: Account[];
   pinned_reports: PinnedReport[];
   preferences: {};
}

export class User {
   constructor( user: any = {} ) {
      this.username = user?.username || '';
      if ( user?.password ) { this.password = user.password; }
      this.fname = user?.fname || '';
      this.lname = user?.lname || '';
      this.initials = ( this.fname[0] || '' ) + ( this.lname[0] || '' );
      if ( user?.avatar ) { this.avatar = user.avatar; }
      this.title = user?.title || '';
      this.email = user?.email || '';
      if ( user?.phone ) { this.phone = user.phone; }

      this.services = user?.services || [];
      this.accounts = ( user?.accounts || user?.customers || user?.vendors || [] ).map( a => new Account( a ));
      this.pinned_reports = user?.pinned_reports || [];
      this.preferences = user?.preferences || {};
   }
}
