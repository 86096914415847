import { Injectable } from '@angular/core';

import { fromEvent, Observable, pipe, Subject, of } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

export const KeyboardPipe = pipe(
   // skip any events that somebody is typing into an input
   filter(( e: KeyboardEvent ) => ( e.target as Element ).tagName.toLowerCase() !== 'input' ),
   filter(( e: KeyboardEvent ) => ( e.target as Element ).tagName.toLowerCase() !== 'textarea' ),
);

export interface KeyboardShortcut {
   description: string;
   key: string;
   keyDisplay: string;
   icon?: string;
   svgIcon?: string;
   _onDestroy?: Subject<void>;
}

@Injectable({
   providedIn: 'root'
})
export class KeyboardShortcutsService {
   public shortcuts: Map<string, KeyboardShortcut> = new Map();

   public trigger$: Subject<void> = new Subject();

   public escape$: Observable<KeyboardEvent> = fromEvent( window, 'keydown' ).pipe(
      KeyboardPipe,
      filter( e => e.key === 'Escape' )
   );

   register$( shortcut: KeyboardShortcut ): Observable<KeyboardEvent> {
      if ( this.shortcuts.has( shortcut.key )) {
         console.warn( 'Attempted to register a shortcut that already exists: ' + JSON.stringify(  shortcut ));

         return of();
      }

      shortcut._onDestroy = shortcut._onDestroy ?? new Subject();

      this.shortcuts.set( shortcut.key, shortcut );

      return fromEvent( window, 'keydown' ).pipe(
         takeUntil( shortcut._onDestroy ),
         KeyboardPipe,
         filter(( e: KeyboardEvent ) => ( e.shiftKey &&  e.key === shortcut.key )),
      );
   }

   remove( key: string ) {
      if ( !this.shortcuts.has( key )) { // tried to remove a shortcut that doesn't exist
         return;
      }

      this.shortcuts.get( key )._onDestroy.next();
      this.shortcuts.get( key )._onDestroy.complete();
      this.shortcuts.delete( key );
   }
}
