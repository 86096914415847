export interface OceanMilestones {
   booked: string;
   confirmed: string;
   cancelled: string;
   pickedup: string;
   gated: string;
   departedocean: string;
   arrivedocean: string;
   // inland: string;
   podd: string;
   pod: string;
}

export class OceanMilestones implements OceanMilestones {
   constructor( current?: OceanMilestones ) {
      this.booked         = current && current['booked'] || '';
      this.confirmed      = current && current['confirmed'] || '';
      this.cancelled      = current && current['cancelled'] || '';
      this.pickedup       = current && current['pickedup'] || '';
      this.gated          = current && current['gated'] || '';
      this.departedocean  = current && current['departedocean'] || '';
      this.arrivedocean   = current && current['arrivedocean'] || '';
      // this.inland    = current && current['inland'] || '';
      this.podd           = current && current['podd'] || '';
      this.pod            = current && current['pod'] || '';
   }
}
