<div class="card">
   <h2 color="primary" style="font-weight: normal;" *ngIf="userService.user | async as u">
      {{u.fname}} {{u.lname}}
   </h2>

   <form [formGroup]="passwordForm">
      <h3>Password Reset</h3>

      <p>New passwords must conform to the following requirements:</p>
      <mat-spinner diameter="69" *ngIf="!validation"></mat-spinner>

      <div class="requirement" *ngFor="let req of validation">
         <mat-icon class="error" *ngIf="passwordTest.invalid && passwordTest.errors[req.title]">clear</mat-icon>
         <mat-icon *ngIf="passwordTest.valid || (passwordTest.invalid && !passwordTest.errors[req.title])">done</mat-icon>
         {{req.text}}
      </div>

      <div class="requirement">
         <mat-icon class="error" *ngIf="passwordForm.invalid">clear</mat-icon>
         <mat-icon *ngIf="passwordForm.valid">done</mat-icon>
         Passwords must match
      </div>
      <br/>

      <mat-form-field>
         <input matInput autofocus type="password" formControlName="passwordTest" placeholder="New Password" />
      </mat-form-field>

      <mat-form-field>
         <input matInput type="password" formControlName="confirmTest" placeholder="Confirm New Password" />
      </mat-form-field>

      <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>

      <div class="buttons">
         <button mat-raised-button color="primary" type="submit"
            [disabled]="passwordForm.invalid"
            (click)="resetPassword()">
            Reset Password
         </button>

         <!-- <button mat-button (click)="dialog.close()">
            Cancel
         </button> -->
      </div>
   </form>
</div>
