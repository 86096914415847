export interface DeliveryMilestones {
   new: string;
   confirmed: string;
   out: string;
   arrivedDestination: string;
   pod: string;
}

export class DeliveryMilestones implements DeliveryMilestones {
   constructor( current?: DeliveryMilestones ) {
      this.new = current?.new || '';
      this.confirmed = current?.confirmed || '';
      this.out = current?.out || '';
      this.arrivedDestination = current?.arrivedDestination || '';
      this.pod = current?.pod || '';
   }
}
