import { Component, OnInit } from '@angular/core';

import { UserService, UseraccessService } from '@library/modules/user';

@Component({
   selector: 'lib-action-menu',
   templateUrl: './action-menu.component.html',
   styleUrls: ['./action-menu.component.scss']
})
export class ActionMenuComponent implements OnInit {
   constructor(
      public userService: UserService,
      public access: UseraccessService,
   ) { }

   ngOnInit() {
   }

}
