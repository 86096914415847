export interface Customs {
   value: number;
   currency: string;
}

export class Customs implements Customs {
   constructor( c?: any ) {
      if ( c?.value ) { this.value = c?.value; }
      if ( c?.currency ) { this.currency = c?.currency; }
   }
}

import { FormGroup, FormControl, Validators, ValidatorFn } from '@angular/forms';
export function customsGroup( customs: any = {} ): FormGroup {
   return new FormGroup({
      value: new FormControl( customs['value'] ),
      currency: new FormControl( customs['currency'] ),
   }, customsValidator);
}

function customsValidator(g: FormGroup): ValidatorFn {
   if ( g.get('value').value || g.get('currency').value ) {
      g.get('value').setValidators( [Validators.required, Validators.min( 0 )] );
      g.get('currency').setValidators( Validators.required );
   } else {
      g.get('value').setValidators( [Validators.min( 0 )]);
      g.get('currency').clearValidators();
   }

   return;
}
