import { Component } from '@angular/core';

import { BrandingService } from '@library/common/branding';

@Component({
   selector: 'lib-terms',
   templateUrl: './terms.component.html',
})
export class TermsComponent {
   constructor( public branding: BrandingService ) { }
}
