import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';

import { HttpClient } from '@angular/common/http';
import { ResponseWrapper } from '@library/common/http';

import { Observable, Subject, merge } from 'rxjs';
import { map, tap, shareReplay, filter } from 'rxjs/operators';

import { CacheService, SearchService, Preferences } from '@library/core/services';

import { SidebarService } from '@library/modules/sidebar';
import { HttpPaginationService, PaginationOptions } from '@library/modules/http-pagination';

import { CartageListItem, CartageDetail } from './models';

import {
   Groups,
   GroupsMap,
   Sort
} from './cartage-functions';

import * as moment from 'moment';

@Injectable({
   providedIn: 'root'
})
export class CartageService {

   url = 'routing';

   sidebarStartDate: FormControl = new FormControl( moment().subtract( 7, 'd' ));
   sidebarEndDate: FormControl = new FormControl( moment() );
   sidebarGroupBy: FormControl = new FormControl( this.pref?.groupBy || Groups[0] );
   sidebarSortBy: FormControl = new FormControl( this.pref?.sortBy || Sort[0] );
   sidebarSortAsc: FormControl = new FormControl( this.pref?.sortDesc || true );

   dateChange$: Subject<void> = new Subject();

   refresh$: Subject<void> = new Subject();

   get pref() { return this.prefs.getPref( 'cartage' ) || {}; }

   constructor(
      private cache: CacheService,
      private search: SearchService,
      private http: HttpClient,
      private sidebar: SidebarService,
      private paginationService: HttpPaginationService,
      public prefs: Preferences,
   ) {
      this.sidebarGroupBy.valueChanges.subscribe( groupBy => {
         const groupFilter = this.sidebar.filters.filter( f => f.id === 'groupByFilter' )[0];

         groupFilter.title = 'Group By ' + GroupsMap[groupBy];
         groupFilter.data = groupBy;
         groupFilter.active = null;

         this.prefs.clearPref( 'cartage', 'groupBy' );
         if ( groupBy !== Groups[0] ) {
            this.prefs.upsertPref( 'cartage', { groupBy });
         }

         if ( this.sidebarSortBy.value === groupBy ) {
            this.sidebarSortBy.setValue( Sort[0] );
         }

         this.sidebar.save();
      });

      this.sidebarSortBy.valueChanges.subscribe( sortBy => {
         this.prefs.clearPref( 'cartage', 'sortBy' );
         if ( sortBy !== Sort[0] ) {
            this.prefs.upsertPref( 'cartage', { sortBy });
         }

         this.sidebar.save();
      });

      this.sidebarSortAsc.valueChanges.subscribe( sortAsc => {
         this.prefs.clearPref( 'cartage', 'sortDesc' );
         if ( !sortAsc ) {
            this.prefs.upsertPref( 'cartage', { sortDesc: !sortAsc });
         }

         this.sidebar.save();
      });

      this.dateChange$.pipe(
         filter( _ => this.sidebarStartDate.valid ),
         filter( _ => this.sidebarEndDate.valid ),
      ).subscribe( _ => {
         this.reset();
         this.sidebar.save();
      });

      // check for changes
      merge(
         this.sidebar.refresh$,
         this.search.text.valueChanges,
      ).subscribe( _ => this.refresh$.next() );
   }

   list(): Observable<CartageListItem[]> {
      const params = {
         start: this.sidebarStartDate.value.format( 'YYYY-MM-DD' ),
         end: this.sidebarEndDate.value.format( 'YYYY-MM-DD' ),
      };

      const options = new PaginationOptions({
         url: `${this.url}/list`,
         params,
         refresher$: this.refresh$,
         responseCallbacks: {
            collection: c => c.map( i => new CartageListItem( i )),
         }
      });

      return this.paginationService.paginateHttp( options );
   }

   details( job: number ): Observable<CartageDetail> {
      return this.cache.observable( this.url + job ) ||
         this.http.get<ResponseWrapper<CartageDetail>>( `${this.url}?job=${job}` ).pipe(
            map( rw => rw.response.collection[0] ),
            map( details => new CartageDetail( details )),
            tap( details => this.cache.store( this.url + job, details )),
            shareReplay(),
         );
   }

   stop() {
      this.paginationService.stop();
   }

   reset() {
      this.stop();
      this.cache.clearSet( this.url );
      this.refresh$.next();
   }
}
