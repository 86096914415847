import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpErrorResponse,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';

import { ErrorMessage, MessagesHttpInterceptorBase } from '@library/common/http';
import { AuthService } from '@library/modules/auth';
import { ErrorMessageService } from '@library/core/services';

@Injectable()
export class MessagesHttpInterceptor extends MessagesHttpInterceptorBase {
   constructor(
      private auth: AuthService,
      private error: ErrorMessageService,
      private router: Router,
   ) {
      super();
   }

   handle( messages: ErrorMessage[] ): void {
      if ( !messages || !messages.length ) { return; }

      messages.map( message => this.error.add( message ));

      // console.log( 'message handler messages', messages );

      messages.forEach( message => {
         // console.log( 'checking code', message );

         switch ( message.code ) {
            // Version stuff
            case 'VERSION_EXPIRED':
               const version = sessionStorage.getItem( 'ev' );
               if ( !version || version !== message.message ) {
                  sessionStorage.setItem( 'ev', message.message );
                  this.error.reset();
                  location.reload( true );
               }

               return;

            // User session stuff
            case 'USER_SESSION_EXPIRED':
            case 'USER_LOCKED':
               this.error.reset();
               this.error.add( message );
               if ( [ '/login', '/logout', '/lock' ].indexOf( this.router.url ) === -1 ) {
                  this.auth.logout();
               }

               return;
            case 'USER_NOT_FOUND':
            case 'USER_IS_INACTIVE':
            case 'USER_SESSION_DOES_NOT_EXIST':
               this.auth.logout();

               return;

            // Gatekeeper stuff
            case 'TOU_NOT_READ':
            case 'TC_NOT_READ':
               this.router.navigate([ 'termsagree' ]);

               return;
            case 'USER_REQUIRED_TO_RESET_PASSWORD':
               this.router.navigate([ 'passwordreset' ]);

               return;

            // Misc problems
            case 'MALFORMED_PAYLOAD':
            case 'TEMPORARY_DATABASE_FAILURE':
            case 'UNEXPECTED_EXCEPTION':
               this.router.navigate([ 'error' ]);

               return;
         }
      });
   }

   handleError( response: HttpErrorResponse ): void {
      this.error.add({
         type: 'error',
         code: response && response['status'] ? response['status'].toString() : 500,
         message: response && response['status'] ? response['status'] + ' ' + response['statusText'] : 'Unknown Error'
      } as ErrorMessage );

      console.log( 'ERROR!!!', response );
      this.router.navigate([ 'error' ]);
   }
}

export let messagesHttpInterceptor = {
   provide: HTTP_INTERCEPTORS,
   useClass: MessagesHttpInterceptor,
   multi: true
};
