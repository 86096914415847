import { Injectable } from '@angular/core';

import * as moment_ from 'moment';
const moment = moment_;

import {
   DateTokens,
   RelativeDateTokens,
   DaysOfTheWeek,
   MonthsOfTheYear,
   TimePeriods,
   Delimiters,
} from './datehandler.model';

@Injectable({
   providedIn: 'root'
})
export class DatehandlerService {

   public dateTimeObj = moment().utc();

   public verbose = false;

   get FormatDefault(): string { return 'MM/DD/YYYY'; }
   get FormatSql(): string { return 'Y-m-d'; }

   set outputFormat( formatStr: string ) {
      this._outputFormat = formatStr;
      if ( !formatStr ) { this._outputFormat = this.FormatDefault; }
   }
   get outputFormat() { return this._outputFormat; }
   private _outputFormat = this.FormatDefault;

   weekStart: DaysOfTheWeek = DaysOfTheWeek[0];

   private _error;
   get error() { return this._error; }
   set error( error: string ) {
      if ( error ) { console.error( error ); }
      this._error = error;
   }

   get regularRegex(): RegExp {
      // Build our crazy regex...
      let regex = '^([0-9\\';
      // Because regex
      regex = regex.concat( Delimiters.join('\\'), ']{4,10}|');
      // Allow date tokens too
      regex = regex.concat( Object.values( DateTokens ).join('|'), ')(?=$|[+~-])((?:([+-][1-9][0-9]{0,2}[');
      // Allow our date math tokens
      regex = regex.concat( TimePeriods.join(''), '])|([~](?:');
      regex = regex.concat( Object.values( RelativeDateTokens ).join('|'), ')))*)$');

      if ( this.verbose ) { console.log( 'regex:', regex ); }

      return new RegExp( regex, 'ig' );
   }

   get monthRegex() {
      // Build crazy regex for month strings...
      const delims: string = '[\\,\\' + Delimiters.join('\\') + ']*';
      let mregex: string = '^([0-9]{0,4}' + delims + '(?:';
      // look for short or long month names
      let or = '';
      for ( const month of MonthsOfTheYear ) {
         mregex = mregex.concat(or, month.substr(0, 3), '(?:' + month.substr(3), ')?');
         or = '|';
      }
      mregex = mregex.concat(')', delims, '[0-9]{0,2}', delims, '[0-9]{0,4})(?:[t\\,\\');
      mregex = mregex.concat( Delimiters.join('\\'), ']*)');
      // look for time and ignore
      mregex = mregex.concat('(?:[0-9]*\:[0-9]*(?:[am|pm]{2,2})?)*((?=$|[+~-])((?:([+-][1-9][0-9]{0,2}[');
      // allow date math tokens
      mregex = mregex.concat(
         TimePeriods.join(''), '])|([~](?:',
         Object.values( RelativeDateTokens ).join('|'), ')))*))$'
      );

      if ( this.verbose ) { console.log( 'mregex:', mregex ); }

      return new RegExp( mregex, 'ig' );
   }

   constructor() { }

   normalizeDate( dateStr: string ): string | boolean {
      if ( this.verbose ) { console.log( 'Original date string:', dateStr ); }

      this.error = null;
      this.dateTimeObj = moment().utc();

      if ( moment( dateStr ).isValid() ) {
         this.dateTimeObj = moment( dateStr );

         return this.repeat();
      }

      // strip out commas
      dateStr = dateStr.replace( new RegExp( /,/ig ), '' );

      // strip out days of the week if passed
      const weekregex: string =
         '(' + DaysOfTheWeek.map( d => d.substr( 0, 3 )).join( '\\w*|' ) + '\\w*)';

      if ( this.verbose ) { console.log( 'weekregex', weekregex ); }

      dateStr = dateStr.replace( new RegExp( weekregex, 'ig' ), '' ).trim();

      if ( this.verbose ) { console.log( 'Trimmed date string:', dateStr ); }

      let matches: any;
      let date: string;
      let tokens: any;

      if ( this.regularRegex.test( dateStr )) {
         matches = this.regularRegex.exec( dateStr );

         if ( this.verbose ) { console.log( 'Matched regular regex', matches ); }

         [ , date, tokens ] = matches;

         const dateTokens: string[] = Object.keys( DateTokens ).map( k => DateTokens[k] );

         // Handle the date
         dateTokens.includes( date ) ? this.handleToken( date ) : this.handleDateStr( date );

      } else if ( this.monthRegex.test( dateStr )) {
         matches = this.monthRegex.exec( dateStr );

         if ( this.verbose ) { console.log( 'Matched string month regex', matches ); }

         [ , date, tokens ] = matches;

         // find delimiter
         const delim: string = this.strFindDelim( date );

         let month;
         let day;
         let year;

         date.split( delim ).map( part => {
            if ( !year && part.length === 4 ) {
               // found the year
               year = part;

               return;
            }

            if ( !month ) {
               MonthsOfTheYear.map(( m, index ) => {
                  if ( m.slice( 0, part.length ) === part.toLowerCase() ) {
                     month = index + 1;

                     return;
                  }
               });
            }

            day = part; // default to day
         });

         this.handleDateStr([ month, day, year ].join( '/' ));
      }

      if ( !matches || this.error ) {
         if ( this.verbose ) { console.log( 'no matches or error' ); }

         this.error = this.error || 'Invalid date string';

         return false;
      }

      // Handle the date math
      if ( tokens.length > 0 ) {
         if ( this.verbose ) { console.log( 'tokens', tokens ); }
         tokens.replace( new RegExp('([~]?[~+-])', 'ig' ), ' $1')
            .split(' ').map( token => this.applyDateToken( token ));
      }

      return this.repeat();
   }

   repeat(): string {
      if ( this.verbose ) { console.log( 'repeat', this.dateTimeObj.format( this.outputFormat ) ); }

      return this.dateTimeObj.format( this.outputFormat );
   }

   private stringTrim(str: string, char: string) {
      if ( char === ']' ) {
         char = '\\]';
      }
      if ( char === '\\' ) {
         char = '\\\\';
      }
      const reg = new RegExp( `^[${char}]|[${char}][${char}]|[${char}]$`, 'g');
      if ( this.verbose ) { console.log( 'stringTrim regex', reg ); }

      return str.replace(reg, '');
   }

   private handleDateStr( dateStr: string ): number {
      if ( this.verbose ) { console.log( 'handleDateStr', dateStr ); }

      const delim: string = this.strFindDelim( dateStr );

      let parts: any[] = [];
      let year: string;

      if ( delim ) {
         // We have multiple parts
         year = this.extractYear( dateStr );
         if ( !year ) { return; }

         dateStr = dateStr.replace( year, '' );

         if ( this.verbose ) { console.log( 'dateStr with year extracted', dateStr ); }

         dateStr = this.stringTrim( dateStr, delim );

         // remove empty parts, in case the year was located in the middle
         parts =  dateStr.split( delim ).filter( _ => _ );

         if ( this.verbose ) { console.log( 'parts', parts ); }

         if ( this.validMonth( +parts[0] ) && this.validDay( +parts[1] )) {
            // MM DD
            return this.getUnixTimestamp( year, +parts[0] - 1, parts[1] );

         } else if ( this.validDay( +parts[0] ) && this.validMonth( +parts[1] )) {
            // DD MM
            return this.getUnixTimestamp( year, +parts[1] - 1, parts[0] );
         }

         this.error = 'Found delimiter but failed to parse date';

         return;
      }

      // One string, let's try to parse it
      if ( dateStr.length !== 8 ) {
         this.error = 'Failed to parse date. Length should be 8 characters.';

         return;
      }

      // try to find a 4 digit year to extract
      year = this.extractYear( dateStr );
      if ( !year ) { return; }

      dateStr = dateStr.replace( year, '' );
      parts = [ dateStr.substr(0, 2), dateStr.substr(2, 2) ];

      if ( this.validMonth( +parts[0] ) && this.validDay( +parts[1] )) {
         // MM DD
         return this.getUnixTimestamp( year, +parts[0] - 1, parts[1] );

      } else if ( this.validDay( +parts[0] ) && this.validMonth( +parts[1] )) {
         // DD MM
         return this.getUnixTimestamp( year, +parts[1] - 1, parts[0] );
      }

      this.error = 'Failed to parse date. Invalid date format.';
   }

   private getUnixTimestamp( year, month, day ): number {
      const mktime = moment([ year, month, day, 12 ]);
      this.dateTimeObj = moment.unix( mktime.unix() );

      if ( this.verbose ) { console.log( 'getUnixTimestamp', year, month, day, this.dateTimeObj ); }

      return this.dateTimeObj.unix();
   }

   private extractYear( date: string ): string {
      const range = new Date().getFullYear() - 1970 + 1;
      const years = [ ...Array( range ).keys() ].map( y => y + 1970 + '' );

      const found = years.filter( year => date.indexOf( year ) !== -1 );
      if ( found && found.length >= 1 ) {
         if ( this.verbose ) { console.log( 'extracted year', found.slice().pop().toString(), 'from', found ); }

         return found.pop().toString();
      }

      this.error = 'Failed to parse date. Unable to extract the year.';
   }

   private validMonth( month: number ): boolean {
      // If the number is out of the range OR isn't a whole number
      if ( this.verbose ) { console.log( 'validMonth', month, !( Math.floor( month ) - month !== 0 || month < 1 || month > 12 )); }

      return !( Math.floor( month ) - month !== 0 || month < 1 || month > 12 );
   }

   private validDay( day: number ): boolean {
      // If the number is out of the range OR isn't a whole number
      if ( this.verbose ) { console.log( 'validDay', day, !( Math.floor( day ) - day !== 0 || day < 1 || day > 31 )); }

      return !( Math.floor( day ) - day !== 0 || day < 1 || day > 31 );
   }

   strFindDelim( str: string ): string {
      // find the count of each type of used delimeter and find the highest count
      const delims: string[] = Delimiters
         .filter( d => str.includes( d ))
         .sort(( a, b ) => str.split( b ).length - str.split( a ).length );

      if ( this.verbose ) { console.log( 'strFindDelim', delims ); }

      if ( delims.length >= 1 ) { return delims[0]; }
   }

   handleToken( token: string, relative: boolean = false ): void {
      if ( this.verbose ) { console.log( 'handleToken', token, relative ); }

      if ( !relative ) {
         this.dateTimeObj = moment();
      }

      switch ( token.slice( 0, 2 )) {
         case DateTokens.Today:
            this.dateTimeObj = moment();
            break;

         case DateTokens.WeekStart:
            this.dateTimeObj.day( this.weekStart );
            break;

         case DateTokens.WeekEnd:
            this.dateTimeObj.day( this.weekStart ).add( 6, 'days' );
            break;

         case DateTokens.MonthStart:
            this.dateTimeObj.startOf( 'month' );
            break;

         case DateTokens.MonthEnd:
            this.dateTimeObj.endOf( 'month' );
            break;

         case DateTokens.YearStart:
            this.dateTimeObj.startOf( 'year' );
            break;

         case DateTokens.YearEnd:
            this.dateTimeObj.endOf( 'year' );
            break;

         case '+D':
            if ( moment( this.dateTimeObj ).day( token[2] ).isBefore( this.dateTimeObj )) {
               this.dateTimeObj.add( 1, 'week' );
            }
            this.dateTimeObj.day( +token[2] );
            break;
         case '-D':
            if ( moment( this.dateTimeObj ).day( token[2] ).isAfter( this.dateTimeObj )) {
               this.dateTimeObj.subtract( 1, 'week' );
            }
            this.dateTimeObj.day( +token[2] );
            break;

         case 'Q1':
         case 'Q2':
         case 'Q3':
         case 'Q4':
            this.dateTimeObj.quarter( +token.slice( 1, 2 ));
            this.dateTimeObj.startOf( 'quarter' );

            if ( token.slice( 2 ) === 'E' ) {
               this.dateTimeObj.endOf( 'quarter' );
            }
            break;

         case RelativeDateTokens.QuarterStart:
            this.dateTimeObj.startOf( 'quarter' );
            break;
         case RelativeDateTokens.QuarterEnd:
            this.dateTimeObj.endOf( 'quarter' );
            break;

         default:
            this.error = 'Unhandled date token: ' + token;
      }
   }

   private applyDateToken( token: string ): void {
      if ( !token ) { return; }
      const split = token.split('');

      if ( this.verbose ) { console.log( 'applyDateToken', token, split ); }

      const operator = split.splice( 0, 1 )[0];
      let type = split.splice( -1, 1 )[0].toLowerCase();
      const qty = split.join('');

      if ( !operator || !type || !qty ) { return; }

      // Months is added using 'M', 'm' is for minutes
      if ( type === 'm' ) {
         type = type.toUpperCase();
      }

      switch ( operator ) {
         case '-':
            if ( this.verbose ) { console.log( operator, qty, type ); }
            this.dateTimeObj.subtract( qty as any, type );
            break;

         case '+':
            if ( this.verbose ) { console.log( operator, qty, type ); }
            this.dateTimeObj.add( qty as any, type );
            break;

         case '~':
            if ( this.verbose ) { console.log( operator, 'handling token', token.slice( 1 )); }
            this.handleToken( token.slice( 1 ), true );
      }
   }

   isHoliday(): boolean {
      // New Years Day, Presidents Day, Memorial Day, Independence Day, Labor Day
      // Thanksgiving Day, Christmas Day

      const checkDate = {
         month: this.dateTimeObj.month(),
         date: this.dateTimeObj.date(),
         dayOfWeek: this.dateTimeObj.day(),
         week: Math.ceil(this.dateTimeObj.date() / 7)
      };

      switch ( true ) {
         // New Years Day
         case ( checkDate.month === 0 && checkDate.date === 1 ):

         // Presidents Day (3rd Monday of Feb)
         case ( checkDate.month === 1 && checkDate.dayOfWeek === 1 && checkDate.week === 3 ):

         // Memorial Day (Last Monday of May)
         case ( checkDate.month === 4 && checkDate.dayOfWeek === 1 && checkDate.week === 4 ):

         // Independence Day (4th of July)
         case ( checkDate.month === 6 && checkDate.date === 4 ):

         // Labor Day (1st Mon of Sept)
         case ( checkDate.month === 8 && checkDate.dayOfWeek === 1 && checkDate.week === 1 ):

         // Thanksgiving (4th Thursday of Nov)
         case ( checkDate.month === 10 && checkDate.dayOfWeek === 4 && checkDate.week === 4 ):

         // Christmas (25th of Dec)
         case ( checkDate.month === 11 && checkDate.date === 25 ):

            return true;

         default:
            return false;
      }
   }
}
