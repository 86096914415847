/* tslint:disable directive-selector */
import { Directive, ElementRef, Optional, Self, Inject, NgZone, HostListener } from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatInput, MAT_INPUT_VALUE_ACCESSOR } from '@angular/material/input';
import { Platform } from '@angular/cdk/platform';
import { NgControl, NgForm, FormGroupDirective } from '@angular/forms';
import { AutofillMonitor } from '@angular/cdk/text-field';
import { getLocaleNumberSymbol, NumberSymbol } from '@angular/common';

@Directive({
   selector: `input[radInput], textarea[radInput], select[matNativeControl],
      input[matNativeControl], textarea[matNativeControl]`,
   exportAs: 'radInput',
   host: {
      class: 'mat-input-element mat-form-field-autofill-control',
      // Native input properties that are overwritten by Angular inputs need to be synced with
      // the native input element. Otherwise property bindings for those don't work.
      '[attr.id]': 'id',
      '[placeholder]': 'placeholder',
      '[disabled]': 'disabled',
      '[required]': 'required',
      '[readonly]': 'readonly',
      '[attr.aria-describedby]': '_ariaDescribedby || null',
      '[attr.aria-invalid]': 'errorState',
      '(blur)': '_focusChanged(false)',
      '(focus)': '_focusChanged(true)',
      '(input)': '_onInput()',
    },
   providers: [{provide: MatFormFieldControl, useExisting: RadinputDirective}],
})
export class RadinputDirective extends MatInput {
   @HostListener('keypress', ['$event']) checkChar(event) {
      if (this.type !== 'number') { return; }

      const decimalSymbol: string = getLocaleNumberSymbol(navigator.language, NumberSymbol.Decimal);
      let acceptedChars = '';

      if ( (event.target.value as string).indexOf(decimalSymbol) !== -1 ) { // Only allow one decimal symbol
         acceptedChars = '[\\deE\\-\\+]';
      } else {
         acceptedChars = '[\\d\\' + decimalSymbol + 'eE\\-\\+]';
      }

      return new RegExp(acceptedChars).test(event.key);
   }

   constructor(
      protected _elementRef: ElementRef<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
      protected _platform: Platform,
      @Optional() @Self() public ngControl: NgControl,
      @Optional() _parentForm: NgForm,
      @Optional() _parentFormGroup: FormGroupDirective,
      _defaultErrorStateMatcher: ErrorStateMatcher,
      @Optional() @Self() @Inject(MAT_INPUT_VALUE_ACCESSOR) inputValueAccessor: any,
      _autofillMonitor: AutofillMonitor,
      ngZone: NgZone
   ) {
      super(
         _elementRef, _platform, ngControl, _parentForm, _parentFormGroup,
         _defaultErrorStateMatcher, inputValueAccessor, _autofillMonitor, ngZone
      );
   }
}
