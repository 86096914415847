import { Injectable } from '@angular/core';

import { MatSidenavContent } from '@angular/material/sidenav';

@Injectable({
   providedIn: 'root'
})
export class ScrollService {

   scrollContainer: MatSidenavContent;

   events: [ number, number ][] = [[ 0, 0 ]];

   push(): void { this.events.push([ 0, 0 ]); }

   update( position: [ number, number ]): void { this.events.splice( -1, 1, position ); }

   current(): [ number, number ] { return this.events.slice( -1 )[0]; }

   pop(): [ number, number ] {
      if ( this.events.length === 1 ) { this.events.unshift([ 0, 0 ]); }

      return this.events.splice( -1, 1 )[0];
   }
}
