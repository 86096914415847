import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ResponseWrapper } from '@library/common/http';
import { CacheService } from '@library/core/services';

import { BulkUpdateTypes, CartageUpdate, UpdateJob } from './cartage-update';

@Injectable({
   providedIn: 'root'
})
export class CartageUpdateService {

   private _jobs: UpdateJob[] = [];
   get jobs(): UpdateJob[] {
      if ( localStorage.getItem( 'bulkupdatejobs' )) {
         return JSON.parse( localStorage.getItem( 'bulkupdatejobs' ));
      }

      return this._jobs;
   }
   set jobs( items: UpdateJob[] ) {
      localStorage.removeItem( 'bulkupdatejobs' );
      localStorage.removeItem( 'bulkupdate' );
      if ( items ) {
         this._jobs = [...new Set( items )];
         localStorage.setItem( 'bulkupdatejobs', JSON.stringify( this._jobs ));
      }
   }

   _type: BulkUpdateTypes;
   get type() {
      return localStorage.getItem( 'bulkupdatetype' ) as BulkUpdateTypes || this._type;
   }
   set type( type: BulkUpdateTypes ) {
      localStorage.removeItem( 'bulkupdatetype' );
      localStorage.removeItem( 'bulkupdate' );
      if ( type ) {
         this._type = type;
         localStorage.setItem( 'bulkupdatetype', type );
      }
   }

   constructor(
      private http: HttpClient,
      private cache: CacheService,
   ) { }

   update( updates: CartageUpdate ): Observable<any> {
      console.log('Update Interface: ', updates);

      const url = 'routing/update';

      // Single / Bulk update checking
      if ( updates.async ) {
         if ( updates.jobs.length < 2 ) {
            console.error( 'Mismatching cartage update - Async update with 1 or less jobs' );
         }

         if ( !updates.batchLabel ) {
            console.error( 'Mismatching cartage update - Async update with no batch label' );
         }
      }

      if ( !updates.async ) {
         if ( updates.jobs.length !== 1 ) {
            console.error( 'Mismatching cartage update - Sync update with 0 or multiple jobs' );
         }
      }

      return this.http.post<ResponseWrapper<any>>( url, updates ).pipe(
         tap( _ => updates.async ? null : this.cache.clearSet( 'routing' )),
         tap( _ => this.clear() ),
      );
   }

   clear() {
      this.jobs = [];
      localStorage.removeItem( 'bulkupdate' );
      localStorage.removeItem( 'bulkupdateJobs' );
      localStorage.removeItem( 'bulkUpdateType' );
   }
}
