import { Component, Input } from '@angular/core';

import { UserService } from '../user';

@Component({
   selector: 'lib-user-avatar',
   templateUrl: './user-avatar.component.html',
   styleUrls: ['./user-avatar.component.scss']
})
export class UserAvatarComponent {

   @Input() size = 40;

   constructor( public userService: UserService ) { }
}
