import { Component } from '@angular/core';

import { BrandingService } from '@library/common/branding';

@Component({
   selector: 'lib-conditions',
   templateUrl: './conditions.component.html',
})
export class ConditionsComponent {
   constructor( public branding: BrandingService ) { }
}
