import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { validateTime } from '@library/common/functions';
import { PiecesUnits } from '@library/common/models';
import { BreakpointService } from '@library/core/services';
import { SignatureCaptureComponent } from '@library/modules/signature-capture';
import { AmazingTimePickerService } from 'amazing-time-picker';

@Component({
   selector: 'app-pod-update',
   templateUrl: './pod-update.component.html',
   styleUrls: ['./pod-update.component.scss', '../cartage-update.scss']
})
export class PodUpdateComponent implements OnInit {

   @Input() readonly form: FormGroup;

   piecesUnits = PiecesUnits;

   constructor(
      public dialog: MatDialog,
      public breakpoints: BreakpointService,

      private atp: AmazingTimePickerService,
   ) { }

   ngOnInit(): void {
      this.formatTime( this.form.get( 'time' ).value );

      this.form.get( 'time' ).valueChanges.subscribe( time => {
         this.formatTime( time );
      });
   }

   // remove seconds from timestring if present
   formatTime( time: string ): void {
      const timeSplit = time.split( ':' );
      if ( timeSplit.length === 3 ) {
         this.form.get('time').setValue( timeSplit[0] + ':' + timeSplit[1] );
      }
   }

   addSignature(): void {
      const dialogRef = this.dialog.open( SignatureCaptureComponent, {
         autoFocus: false
      });

      dialogRef.afterClosed().subscribe( result => {
         if ( result ) {
            this.form.get( 'attachmentData' ).setValue([ result ]);
         }
      });
   }

   openClock(): void {
      const f = this.form.get( 'time' );
      let time = f.value || '00:00';

      if ( !validateTime( f.value )) { time = '00:00'; }

      const timepicker = this.atp.open({ time });
      if ( timepicker ) {
         timepicker.afterClose().subscribe( t => {
            f.setValue( t );
         });
      }
   }

   getSignatureImage(): any {
      return atob( this.form.get( 'attachmentData' ).value[0] );
   }
}
