import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, Subject } from 'rxjs';
import { switchMap, shareReplay, takeWhile, take, tap } from 'rxjs/operators';

import { ResponseWrapper } from '@library/common/http';

import { CacheService, DefaultExpiryLength } from '@library/core/services';

/**
 * {
 *    url: for http call
 *    params: for http call
 *    refresher$: Subject to be used to trigger refresh in calling class
 *    cacheName: for stashing with cache service
 *    cacheLength: minutes to store cached data (default 5)
 *    responseCallbacks: the are called after the response is recieved and before it is cached
 * }
 */
export interface PaginationOptions {
   url: string;
   params?: {};
   refresher$: Subject<void>;
   cacheName?: string;
   cacheLength?: number;
   responseCallbacks?: {[ key: string ]: ( value ) => any };
}

export class PaginationOptions implements PaginationOptions {
   constructor( opts: PaginationOptions ) {
      this.url = opts.url;
      this.params = opts?.params || {};
      this.refresher$ = opts.refresher$;
      this.cacheName = opts?.cacheName || `${this.url}${JSON.stringify(this.params)}`;
      this.cacheLength = opts?.cacheLength || DefaultExpiryLength;
      this.responseCallbacks = opts?.responseCallbacks || {};
   }
}

@Injectable({
   providedIn: 'root'
})
export class HttpPaginationService {

   loading = true;
   loadingPercent = 0;

   inFlight: Observable<any>;

   constructor(
      private http: HttpClient,
      private cache: CacheService,
   ) { }

   paginateHttp<T>( options: PaginationOptions ): Observable<T[]> {
      const cacheName = options.cacheName;

      if ( this.cache.check( cacheName )) {
         return this.cache.observable( cacheName );
      }

      if ( this.inFlight ) { return this.inFlight; }

      this.loading = true;

      return this.inFlight = this.next<T>( options );
   }

   next<T>( options: PaginationOptions ): Observable<T[]> {
      const {
         url,
         params = {},
         refresher$ = new Subject(),
         cacheName = `${url}${JSON.stringify(params)}`,
         cacheLength = DefaultExpiryLength,
         responseCallbacks,
      } = options;

      const nextOpts = { ...options };

      if ( !this.loading || !url ) {
         return this.cache.observable( cacheName );
      }

      return this.http.post<ResponseWrapper<T>>( url, params ).pipe(
         takeWhile( _ => this.loading ),
         switchMap(( rw: any ) => {
            Object.keys( responseCallbacks ).map( key => {
               if ( rw.response[key] ) {
                  rw.response[key] = responseCallbacks[key]( rw.response[key] );
               }
            });
            const collection = rw.response.collection;

            this.updateCache( cacheName, collection, cacheLength );
            refresher$.next();

            const p = rw.response.pagination;

            if ( !p ) {
               this.stop();

               return this.cache.observable<T[]>( cacheName );
            }

            if ( p?.page === 1 ) {
               this.loading = true;
               this.cache.expire( cacheName );
               this.cache.store( cacheName, collection, cacheLength );
            }

            this.loadingPercent = +( p.page / p.total * 100 ).toFixed();

            if ( p.page < p.total ) {
               p.page++;
               nextOpts.params = ({ ...params, pagination: p });

               this.next( nextOpts ).pipe( take( 1 )).subscribe();
            } else {
               this.stop();
            }

            return this.cache.observable<T[]>( cacheName );
         }),
         tap( _ => this.inFlight = null ),
         shareReplay(),
      );
   }

   updateCache<T>( cacheName: string, data: T[], cacheLength?: number ): T[] {
      const cached = this.cache.retrieve( cacheName ) || [];
      const updated = [ ...cached, ...data ];

      this.cache.store( cacheName, updated, cacheLength );

      return updated;
   }

   stop(): void {
      this.loading = false;
      this.loadingPercent = 0;
      this.inFlight = null;
   }

   reset(): void {
      this.stop();
   }
}
