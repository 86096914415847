import { Injectable } from '@angular/core';

import { of, merge } from 'rxjs';
import { reduce, concatMap } from 'rxjs/operators';

@Injectable({
   providedIn: 'root'
})
export class AppContainerService {
   get windowHeight$() { return of( window.innerHeight ); }

   get viewportHeight$() {
      return this.windowHeight$.pipe(
         concatMap(
            _ => merge( this.menuHeight$, this.footerHeight$ )
               .pipe( reduce(( a, c ) => a + c, 0 )),
            ( wHeight, vHeight ) => wHeight - vHeight
         ),
      );
   }

   menuHeight = 0;
   get menuHeight$() { return of( this.menuHeight ); }

   footerHeight = 0;
   get footerHeight$() { return of( this.footerHeight ); }
}
