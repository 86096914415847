<div class="menu-top" #menuContainer>
   <div class="menu-bar">
      <span class="app-title" #left routerLink="/" (click)="left.blur()">
         <span class="logo"></span>

         <span class="app-name button-ish">{{navMenu.title | uppercase}}</span>
      </span>

      <div #navContainer>
         <lib-nav-menu *ngIf="!hamburger && ( navMenu.below$ | async ) === false"></lib-nav-menu>
      </div>

      <lib-action-menu *ngIf="!hamburger"></lib-action-menu>

      <lib-menu-xsmall *ngIf="hamburger"></lib-menu-xsmall>
   </div>

   <lib-nav-menu *ngIf="!hamburger && ( navMenu.below$ | async )"></lib-nav-menu>

   <lib-activity-bar></lib-activity-bar>

   <lib-menu-error-bar></lib-menu-error-bar>
</div>
