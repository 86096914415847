export { PipesModule } from './pipes.module';

export * from './convert.pipe';
export * from './currencycode.pipe';
export * from './ellipsis.pipe';
export * from './filesize.pipe';
export * from './filterlist.pipe';
export * from './highlight.pipe';
export * from './phone.pipe';
export * from './plural.pipe';
export * from './readable.pipe';
export * from './spacers.pipe';
export * from './time.pipe';
export * from './tofixed.pipe';
export * from './total.pipe';
export * from './volume.pipe';
export * from './weights.pipe';
