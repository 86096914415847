import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialsModule } from '@library/common/materials';
import { PipesModule } from '@library/core/pipes';

import { DefaultErrorComponent } from './default.error/default.error.component';
import { InputErrorComponent } from './input-error/input-error.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialsModule,
    PipesModule,
  ],
  declarations: [
     DefaultErrorComponent,
     InputErrorComponent,
  ],
  exports: [
     DefaultErrorComponent,
     InputErrorComponent,
  ],
})
export class ErrorsModule { }
