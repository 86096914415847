import { Injectable } from '@angular/core';
import {
   Router,
   CanActivate,
   CanActivateChild,
   CanLoad,
   ActivatedRouteSnapshot,
   Route,
   UrlTree,
} from '@angular/router';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { ErrorMessageService } from '@library/core/services';

import { UseraccessService } from './useraccess.service';

@Injectable()
export class UserAccessGuard implements CanActivate, CanActivateChild, CanLoad {

   constructor(
      private access: UseraccessService,
      private router: Router,
      private error: ErrorMessageService,
   ) { }

   canActivate( route: ActivatedRouteSnapshot, ): Observable<boolean | UrlTree> | boolean | UrlTree {
      return this.hasAccess( route.data.access ).pipe(
            map( access => {
               if ( access ) { return true; }

               this.error.add({
                  type: 'alert',
                  code: 'ACCESS',
                  message: `You do not have access to view ${route.url}`,
               });

               return this.router.createUrlTree([ '/' ]);
            }),
         );
   }

   canActivateChild( route: ActivatedRouteSnapshot, ): Observable<boolean | UrlTree> | boolean | UrlTree {
      return this.canActivate( route );
   }

   canLoad( route: Route ): Observable<boolean> | boolean {
      return this.hasAccess( route.data.access );
   }

   hasAccess( access: string ): Observable<boolean> {
      if ( !access ) { return of( true ); }

      return this.access.hasAccess( access );
   }
}
