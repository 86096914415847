<ng-container *ngIf="state == 'show'">
   <ng-container *ngIf="showCancel">
      <button mat-button *ngIf="!breakpoints.small" (click)="cancel.emit( true )">
         {{cancelButtonText | uppercase}}
      </button>

      <button mat-icon-button color="warn" matTooltip="{{cancelButtonText | titlecase}}" *ngIf="breakpoints.small" (click)="cancel.emit( true )">
         <mat-icon>{{cancelButtonIcon}}</mat-icon>
      </button>
   </ng-container>

   <button mat-button color="accent" [disabled]="disabled" *ngIf="!breakpoints.small" (click)="save.emit( true )">
      <mat-icon>{{saveButtonIcon}}</mat-icon> {{saveButtonText | uppercase}}
   </button>

   <button mat-icon-button color="accent" matTooltip="{{saveButtonText | titlecase}}" [disabled]="disabled" *ngIf="breakpoints.small" (click)="save.emit( true )">
      <mat-icon>{{saveButtonIcon}}</mat-icon>
   </button>
</ng-container>

<mat-spinner diameter="24" *ngIf="state == 'saving'"></mat-spinner>

<span [@checkfade] *ngIf="state == 'saved'">
   <mat-icon color="primary">check</mat-icon>
</span>
