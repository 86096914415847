import { Injectable } from '@angular/core';

import {
   HttpInterceptor,
   HttpRequest,
   HttpHandler,
   HttpEvent,
   HttpResponse,
   HTTP_INTERCEPTORS,
} from '@angular/common/http';

import { Observable, of, Subject } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

import { isAssets } from './httpFunctions';

@Injectable()
export class CacheHttpInterceptorBase implements HttpInterceptor {
   constructor() {}

   inFlight = new Map<string, any>();
   svgIcons = new Map<string, any>();

   intercept(
      request: HttpRequest<any>,
      next: HttpHandler,
   ): Observable<HttpEvent<any>> {
      if ( isAssets( request.url )) {
         const svgCached = this.svgIcons.get( request.url );
         if ( svgCached ) { return of( svgCached ); }

         return next.handle( request ).pipe( tap( result => {
            if ( result instanceof HttpResponse ) {
               this.svgIcons.set( request.url, result );
            }
         }));
      }

      const key = request.method + request.url;

      const cached = this.inFlight.get( key );
      if ( cached ) { return cached; }

      this.inFlight.set( key, new Subject<any>() );
      setTimeout( _ => this.inFlight.has( key ) && this.inFlight.delete( key ), 6000 );

      return next.handle( request ).pipe(
         tap( result => {
            if ( result instanceof HttpResponse ) {
               const c = this.inFlight.get( key );
               if ( c ) {
                  c.next( result );
                  this.inFlight.delete( key );
               }
            }
         }),
         catchError( error => {
            const c = this.inFlight.get( key );
            if ( c ) {
               this.inFlight.delete( key );
            }

            throw error;
         }),
      );
   }
}

export let cacheHttpInterceptor = {
   provide: HTTP_INTERCEPTORS,
   useClass: CacheHttpInterceptorBase,
   multi: true
};
