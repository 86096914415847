import { optional, titleCase } from '@library/common/functions';
import * as faker from 'faker';

import { Milestones, Shipment } from '../shipment';
import { BillingParties } from '../base';

import { MockAttachment } from './attachment.mock';
import { MockBillingParty } from './billing-party.mock';
import { MockCalculator } from './calculator.mock';
import { MockCOD } from './cod.mock';
import { MockHawb } from './hawb.mock';
import { MockPOD } from './pod.mock';
import { MockReference } from './reference.mock';
import { MockRisks } from './risks.mock';
import { MockStatus } from './status.mock';
import { MockTradeshow } from './tradeshow.mock';

export class MockShipment extends Shipment<any, any> {
   constructor() {
      super();

      this.hawb = MockHawb();

      this.station = new MockBillingParty();

      this.billingparty = faker.random.arrayElement( BillingParties );
      this.shipper = new MockBillingParty();
      this.consignee = new MockBillingParty();
      this.bill_to = new MockBillingParty();

      this.servicelevel = titleCase( faker.lorem.words(2) ) as string;

      if ( optional() ) { this.airportfrom = faker.address.state().slice( 0, 3 ).toUpperCase(); }
      if ( optional() ) { this.airportto = faker.address.state().slice( 0, 3 ).toUpperCase(); }

      this.pdatefrom = faker.date.past().toISOString().slice( 0, 10 );
      this.pdateto = faker.date.past().toISOString().slice( 0, 10 );
      this.ptimefrom = faker.fake(`{{datatype.number(1)}}{{datatype.number(9)}}:{{datatype.number(5)}}{{datatype.number(9)}}`);
      this.ptimeto = faker.fake(`{{datatype.number(1)}}{{datatype.number(9)}}:{{datatype.number(5)}}{{datatype.number(9)}}`);

      this.ddatefrom = faker.date.past().toISOString().slice( 0, 10 );
      this.ddateto = faker.date.past().toISOString().slice( 0, 10 );
      this.dtimefrom = faker.fake(`{{datatype.number(1)}}{{datatype.number(9)}}:{{datatype.number(5)}}{{datatype.number(9)}}`);
      this.dtimeto = faker.fake(`{{datatype.number(1)}}{{datatype.number(9)}}:{{datatype.number(5)}}{{datatype.number(9)}}`);

      this.calculator = Array.from({ length: faker.datatype.number( 10 ) }, _ => new MockCalculator() );

      if ( optional() ) { this.references = Array.from({ length: faker.datatype.number( 10 ) }, _ => new MockReference() ); }

      if ( optional() ) { this.tradeshow = [ new MockTradeshow( 'from' ), new MockTradeshow( 'to' ) ]; }

      if ( optional() ) { this.risks = new MockRisks(); }

      if ( optional() ) { this.statuses = Array.from({ length: faker.datatype.number( 5 ) }, _ => new MockStatus() ); }

      if ( optional() ) { this.cod = new MockCOD(); }

      if ( optional() ) { this.pod = new MockPOD(); }

      this.milestones = Milestones( this.mode );
      const length = faker.datatype.number( Object.keys( this.milestones ));
      Object.keys( this.milestones ).map(( key, index ) => {
         if ( index < length && optional() ) {
            this.milestones[key] = faker.date.past();
         }
      });

      if ( optional() ) { this.description = faker.lorem.paragraph(); }
      if ( optional() ) { this.specialinstructions = faker.lorem.paragraph(); }

      if ( optional() ) {
         this.attachments = Array.from({ length: faker.datatype.number( 5 ) }, _ => new MockAttachment() );
      }
   }
}
