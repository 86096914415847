export function CurrencyCheck( toCheck: { currency: any }[] ): boolean {
   if ( toCheck.length < 2 ) { return true; }

   const mapped = toCheck.map( c => c.currency );
   if ( mapped.shift() === mapped.pop() ) {
      return true;
   }

   return false;
}
