import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';

import { Subject } from 'rxjs';

import { SearchService, BreakpointService, BreadcrumbService } from '@library/core/services';

import { AccountService } from './account.service';
import { Account } from './account';

@Component({
  selector: 'lib-account',
  styleUrls: [ './account.component.scss' ],
  templateUrl: './account.component.html',
})
export class AccountComponent implements OnInit, OnDestroy {
   _loading: number;
   _onDestroy: Subject<void> = new Subject();

   @ViewChild(MatPaginator) paginator: MatPaginator;
   pageSize = 10;
   page = 0;
   get startIndex() { return this.page * this.pageSize; }
   get endIndex() { return this.startIndex + this.pageSize; }

   get filterString(): string {
      return ( this.search.text.value || '' ).trim().toLowerCase();
   }

   constructor(
      public accountService: AccountService,
      public search: SearchService,
      public breakpoints: BreakpointService,

      private breadcrumbs: BreadcrumbService,
   ) { }

   ngOnInit() {
      this.breadcrumbs.newCrumb({ label: 'User', path: '/user' });
      this.breadcrumbs.addCrumble( 'Accounts' );
      this.search.showSearch = true;
   }

   ngOnDestroy() {
      this._onDestroy.next();
      this._onDestroy.complete();
   }

   switch( id: number ) {
      this._loading = id;
      this.accountService.switchAccount( id ).subscribe( _ => this._loading = null );
   }

   bookmark( a: Account ): void {
      this.accountService.bookmark( a );
   }
}
