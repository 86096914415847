import { Shipment, shipmentForBooking, shipmentGroup, shipmentCopy } from './shipment';

export interface DomesticShipment extends Shipment<'domestic', 'road'> {}

export class DomesticShipment extends Shipment<'domestic', 'road'> {
   shipType: 'domestic' = 'domestic';
   mode: 'road' = 'road';

   constructor( s?: DomesticShipment ) {
      super( s );
   }
}

import { FormGroup } from '@angular/forms';
export function domesticShipmentGroup( s: any = {} ): FormGroup {
   return shipmentGroup( s );
}

export function domesticForBooking<S extends DomesticShipment>( s: S ): S {
   return shipmentForBooking<S>( s );
}

export function domesticShipmentCopy<S extends DomesticShipment>( s: S, mode: null | 'continue' | 'reverse' = null ): S {
   return ({
      ...shipmentCopy<S>( s, mode ),
   });
}
