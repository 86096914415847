import * as faker from 'faker';
import { optional } from '@library/common/functions';

import {
   Milestones,
   InternationalShipment, TrafficDirections,
   InternationalRoadShipment,
   InternationalAirShipment, AwbTypes,
   InternationalOceanShipment, ShippingTypes,
} from '../shipment';

import { MockShipment } from './shipment.mock';
import { MockCustoms } from './customs.mock';
import { MockContainer } from './containers.mock';

export class MockInternationalShipment extends InternationalShipment {
   constructor() {
      super({
         ...new MockShipment(),
         movementType: {
            from: Math.random() > 0.5 ? 'door' : 'port',
            to: Math.random() > 0.5 ? 'door' : 'port'
         },
      });

      if ( optional() ) { this.incoterms = faker.lorem.word(); }
      if ( optional() ) { this.customs = new MockCustoms(); }
      if ( optional() ) { this.trafficDirection = faker.random.arrayElement( TrafficDirections ); }
   }
}

export class MockInternationalRoadShipment extends InternationalRoadShipment {
   constructor() {
      super( new MockInternationalShipment() );
   }
}

export class MockInternationalAirShipment extends InternationalAirShipment {
   constructor() {
      super( new MockInternationalShipment() );

      this.milestones = Milestones( 'air' );
      if ( optional() ) { this.awbType = faker.random.arrayElement( AwbTypes ); }
      Object.keys( this.milestones ).map( key => {
         if ( optional() ) {
            this.milestones[key] = faker.date.past();
         }
      });
   }
}

export class MockInternationalOceanShipment extends InternationalOceanShipment {
   constructor() {
      super( new MockInternationalShipment() );

      this.itemsType = optional() ? 'LCL' : 'FCL';
      if ( this.itemsType === 'FCL' ) {
         this.itemized = optional();
         this.containers = Array.from({ length: faker.datatype.number( 9 ) + 1 }, _ => new MockContainer( this.itemized ));
      }

      if ( optional() ) { this.shippingType = faker.random.arrayElement( ShippingTypes ); }
      if ( optional() ) { this.teu = faker.datatype.number( 10 ); }
      if ( optional() ) {
         this.origin = faker.address.state().slice( 0, 3 ).toUpperCase();
         this.destination = faker.address.state().slice( 0, 3 ).toUpperCase();
      }

      this.milestones = Milestones( 'ocean' );
      Object.keys( this.milestones ).map( key => {
         if ( optional() ) {
            this.milestones[key] = faker.date.past();
         }
      });
   }
}
