import { Injectable } from '@angular/core';

import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { ErrorMessage } from './response.wrapper';

import { isAssets } from './httpFunctions';

@Injectable()
export class MessagesHttpInterceptorBase implements HttpInterceptor {
   intercept(
      request: HttpRequest<any>,
      next: HttpHandler,
   ): Observable<HttpEvent<any>> {
      if ( isAssets( request.url )) {
         return next.handle( request );
      }

      return next.handle( request )
         .pipe(
            catchError(( response: HttpErrorResponse ): Observable<HttpResponse<any>> => {
               if ( response.error && !response.error?.messages ) {
                  this.handleError( response.error?.messages );

               } else {
                  this.handle( response.error?.messages || [] );
               }

               return throwError( response );
            }),
            tap(( ev: HttpEvent<any> ) => {
               if ( ev instanceof HttpResponse && ev.body?.messages ) {
                  this.handle( ev.body?.messages );
               }
            }),
         );
   }

   handle( _messages: ErrorMessage[] ): void { }

   handleError( _response: HttpErrorResponse ): void { }
}

export let messagesHttpInterceptor = {
   provide: HTTP_INTERCEPTORS,
   useClass: MessagesHttpInterceptorBase,
   multi: true
};
