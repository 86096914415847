import { Injectable } from '@angular/core';

import {
   HttpInterceptor,
   HttpRequest,
   HttpHeaders,
   HttpHandler,
   HttpEvent,
   HTTP_INTERCEPTORS,
} from '@angular/common/http';

import { Observable, of, Subject, timer, throwError } from 'rxjs';
import {
   map,
   switchMap,
   retryWhen,
   takeUntil,
   mergeMap,
} from 'rxjs/operators';

import { isAssets } from './httpFunctions';

@Injectable()
export class RadiantHttpInterceptor implements HttpInterceptor {
   constructor() { }

   retries = new Map<string, any>();

   cancelAll: Subject<void> = new Subject();

   intercept(
      request: HttpRequest<any>,
      next: HttpHandler,
   ): Observable<HttpEvent<any>> {
      if ( isAssets( request.url )) {
         return next.handle( request );
      }
      // console.log( 'original request', request );

      // middleware request
      return this.headers( request ).pipe(
         takeUntil( this.cancelAll ),
         map( headers => request.clone({
            url: this.origin() + request.url,
            headers
         })),
         switchMap( customReq => {
            // console.log( 'intercepted request', customReq );

            return next.handle( customReq ).pipe(
               retryWhen( errors =>
                  errors.pipe(
                     mergeMap(( error, i ) => {
                        const attempt = i + 1;

                        if (
                           customReq.method !== 'GET' || // only retry on GET
                           !error.status || // unexpected status
                           error.status.toString().startsWith( '4' ) || // no retry on 4**
                           attempt >= 3 // if max retries has been met
                        ) {
                           return throwError( error );
                        }

                        return timer( 1000 );
                     }),
                  )),
            );
         })
      );
   }

   /** Override to set the middleware server URL */
   origin(): string {
      return '';
   }

   /** Override to set request headers ( ie build version ) */
   headers( request: HttpRequest<any> ): Observable<HttpHeaders> {
      return of( request.headers );
   }

   reset() {
      this.cancelAll.next();
   }
}

export let httpInterceptor2 = {
   provide: HTTP_INTERCEPTORS,
   useClass: RadiantHttpInterceptor,
   multi: true
};
