import {
   Component,
   Inject,
   Input,
   ViewChild,
   ViewChildren,
   Renderer2,
   AfterViewInit,
   OnDestroy,
   QueryList,
   ElementRef,
} from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, } from '@angular/material/dialog';

import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

import { Subscription } from 'rxjs';

import { SignaturePad } from 'angular2-signaturepad';

@Component({
   selector: 'app-signature-pad-dialog',
   template: `
   <button mat-stroked-button color="primary" (click)="open()">
      <mat-icon style="margin-right: 0.5em;">gesture</mat-icon>
      Add Signature
   </button>`,
})
export class SignaturePadDialogComponent {
   @Input() control: FormArray;

   constructor( public dialog: MatDialog ) { }

   open() {
      const dialogRef = this.dialog.open( SignaturePadComponent, {
         maxWidth: 'unset',
         maxHeight: 'unset',
      });

      dialogRef.afterClosed().subscribe( result => {
         if ( !result ) { return; }

         this.control.push( new FormControl( result ));
      });
   }
}

@Component({
   selector: 'app-signature-pad',
   template: `
      <h1 mat-dialog-title>Sign Here</h1>
      <div #signature_content class="mat-elevation-z8">
         <signature-pad [options]="options" (onBeginEvent)="drawBegin()" (onEndEvent)="drawComplete()"></signature-pad>
      </div>
      <div mat-dialog-actions style="display: flex; justify-content: space-between;">
         <button mat-button (click)="clear()">Clear</button>
         <span>
            <button mat-button (click)="dialogRef.close()">Cancel</button>
            <button mat-raised-button color="primary" [mat-dialog-close]="_signature">Submit</button>
         </span>
      </div>`,
})
export class SignaturePadComponent implements AfterViewInit, OnDestroy {

   @ViewChild(SignaturePad) signaturePad: SignaturePad;

   @ViewChildren( 'signature_content' ) content: QueryList<ElementRef>;

   public options = { // passed through to szimek/signature_pad constructor
      minWidth: 1,
      canvasWidth: 800,
      canvasHeight: 250,
   };

   _observerSubscription: Subscription;

   _signature: any = null;
   rotated = false;

   public propagateChange: ( value: any ) => void;

   get signature(): any {
      return this._signature;
   }

   set signature( value: any ) {
      this._signature = value;
      if ( this.propagateChange instanceof Function ) {
         this.propagateChange(this.signature);
      }
   }

   get canvas(): HTMLCanvasElement {
      const content = this.content.first.nativeElement;

      return content.querySelector( 'canvas' );
   }

   constructor(
      public dialogRef: MatDialogRef<SignaturePadComponent>,
      @Inject( MAT_DIALOG_DATA ) public data: any,
      public breakpointObserver: BreakpointObserver,
      public renderer: Renderer2,
   ) {
   }

   public setOptions() {
      if ( this.breakpointObserver.isMatched( Breakpoints.HandsetPortrait )) {
         this.options.canvasHeight = Math.min( window.innerHeight - 148 );
         this.options.canvasWidth = Math.min( window.innerWidth - 48 );
      } else {
         this.options.canvasHeight = Math.min( 250, window.innerHeight - 148 );
         this.options.canvasWidth = Math.min( 800, window.innerWidth - 48 );
      }
   }

   public writeValue(value: any): void {
      if (!value) { return; }
      this._signature = value;
      this.signaturePad.fromDataURL(this.signature);
   }

   public registerOnChange(fn: any): void {
      this.propagateChange = fn;
   }

   public registerOnTouched(): void {
      // no-op
   }

   public ngAfterViewInit(): void {
      this.signaturePad.clear();

      this.setOptions();

      this._observerSubscription = this.breakpointObserver.observe([
         Breakpoints.HandsetPortrait,
      ]).subscribe( result => {
         if ( result.matches && !this.rotated ) {
            this.dialogRef.addPanelClass( 'rotate90deg' );
            this.dialogRef.updateSize( Math.min( 800, window.innerHeight ) + 'px', Math.min( 400, window.innerWidth ) + 'px' );

            const ctx = this.canvas.getContext( '2d' );
            ctx.rotate( 1.5 * Math.PI );
            ctx.translate( -this.canvas.height, 0 );

            this.rotated = true;

         } else if ( !result.matches && this.rotated ) {
            this.dialogRef.removePanelClass( 'rotate90deg' );
            this.dialogRef.updateSize( Math.min( 800, window.innerWidth ) + 'px', Math.min( 400, window.innerHeight ) + 'px' );

            const ctx = this.canvas.getContext( '2d' );
            ctx.translate( this.canvas.height, 0 );
            ctx.rotate( 0.5 * Math.PI );

            this.rotated = false;
         }
      });
   }

   public ngOnDestroy() {
      this._observerSubscription.unsubscribe();
   }

   public drawBegin(): void { }

   public drawComplete(): void {
      this.signature = this.signaturePad.toDataURL();
   }

   public clear(): void {
      const ctx = this.canvas.getContext( '2d' );
      ctx.clearRect( 0, 0 , this.canvas.height, this.canvas.width );
      this.signature = '';
      this.signaturePad.fromData([]);
   }
}
