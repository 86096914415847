<div class="attachment" matRipple *ngIf="attachment.id else placeholder" (click)="download.emit()" style="cursor: pointer;">
   <div class="image">
      <div class="top">
         <mat-icon color="primary" matTooltip="Download">cloud_download</mat-icon>

         <mat-checkbox *ngIf="selectable" (click)="$event.stopPropagation()" (change)="checked.emit()"></mat-checkbox>
      </div>

      <mat-icon class="icon" color="primary" [svgIcon]="getExtensionIcon(attachment.extension)">
      </mat-icon>

      <div class="size">{{attachment.size | filesize}}</div>
   </div>

   <div class="meta">
      <div class="header" color="primary">{{attachment.type}}</div>
      <div>{{attachment.name ? attachment.name : attachment.filename}}</div>
   </div>
</div>

<ng-template #placeholder>
   <div class="attachment">
      <div class="image">
         <mat-icon class="cloud" matTooltip="Download Unavailable" style="cursor: help;">cloud_off</mat-icon>

         <mat-icon class="icon" [svgIcon]="getExtensionIcon(attachment.extension)">
         </mat-icon>

         <div class="size">{{attachment.size | filesize}}</div>
      </div>

      <div class="meta">
      <div class="header">{{attachment.type}}</div>
      <div>{{attachment.name ? attachment.name : attachment.filename}}</div>
   </div>
   </div>
</ng-template>
