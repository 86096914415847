import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { BrandingService } from '@library/common/branding';
import {
   BrowserCheckService,
   BreakpointService,
   // ErrorMessageService,
   EnvironmentService,
   TextService,
   PrefetchService,
   GoogleAnalyticsService,
} from '@library/core/services';

import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet>`,
})
export class AppComponent {
   constructor(
      public router: Router,
      private e: EnvironmentService,
      private gtag: GoogleAnalyticsService,
      private brand: BrandingService,
      private check: BrowserCheckService,
      private breakpoints: BreakpointService,
      private text: TextService,
      private prefetch: PrefetchService,
   ) {
      this.e.environment = environment;
      this.gtag.load( environment?.gtagId );
      this.breakpoints.tap();
      this.check.check();

      // console.log( 'requesting server', this.server );
      this.brand.portal = 'Vendor';
      if ( environment.production ) { this.brand.load(); }

      this.text.loginTitle = `Welcome to Our Vendor Portal`;
      this.text.loginSubTitle = `The Radiant Vendor Portal provides access to vital information
         on all your shipments and has been designed to streamline the transportation vendor workflow.
         Log in today to manage shipment details.`;

      this.prefetch.items = [ 'currencies' ];
   }
}
