import { Injectable } from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';

import { Observable, of, forkJoin } from 'rxjs';
import { map, tap, shareReplay } from 'rxjs/operators';

import { Country, ShipmentTypes } from '@library/common/models';

import { EnumsService } from './enums.service';
import { CacheService } from './cache.service';

@Injectable({
   providedIn: 'root'
})
export class CountriesService {

   constructor(
      private enums: EnumsService,
      private cache: CacheService,
   ) {}

   get countries(): Observable<Country[]> {
      if ( this.cache.check( 'countries' )) {
         return of( this.cache.retrieve( 'countries' ));
      }

      return forkJoin( this.enums.countries(), this.enums.oceanPorts() ).pipe(
         map(([ countries, ports ]) => {
            const countryNames = countries.map( c => c.country );

            countries.sort(( a, b ) => a.country < b.country ? -1 : a.country > b.country ? 1 : 0 );
            ports.map(( p, i ) => ports[i].ports = p?.ports?.sort(( a, b ) => a < b ? -1 : a > b ? 1 : 0 ));

            ports.filter( p => countryNames.includes( p.country ))
               .map( p => countries.filter( c => c.code === p.code )[0].ports = p.ports );

            return countries;
         }),
         tap( countries => this.cache.store( 'countries', countries, 1400 )),
         shareReplay(),
      );
   }

   countriesByIdiocracy( type: ShipmentTypes ): Observable<Country[]> {
      return this.countries.pipe(
         map( countries => {
            switch ( type ) {
               case 'domestic':
                  return countries.filter( c => [ 'US', 'CA', 'MX' ].includes( c.code ));
               default:
                  return countries;
            }
         }),
      );
   }

   country( code: string ): Observable<Country> {
      return this.countries.pipe(
         map( countries => countries.filter( c => c.code === code )[0] ),
      );
   }

   allPorts(): Observable<string[]> {
      return this.countries.pipe(
         map( countries => countries.reduce(( a, c ) => [ ...a, ...c.ports ], [] ) ),
      );
   }

   setPostalCodeValidators( code: string, control: AbstractControl ): void {
      if ( !code || !control ) { return; }

      this.country( code ).subscribe( country => {
         const deets = country?.postalDetails;
         if ( !deets ) { return; }

         const validators = [
            deets.regex ? Validators.pattern( new RegExp( deets.regex )) : null,
            deets.required ? Validators.required : null,
            deets.maxLength ? Validators.maxLength( deets.maxLength ) : null,
         ].filter( _ => _ );

         control.setValidators( validators );
         control.updateValueAndValidity();
      });
   }
}
