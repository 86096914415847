import { FormGroup, FormControl, Validators } from '@angular/forms';

export interface UserModification {
   password?: string;

   fname?: string;
   lname?: string;

   title?: string;

   phone?: string;

   termsUse?: boolean;
   termsConditions?: boolean;
}

export class UserModification implements UserModification {
   constructor( m: any = {} ) {
      if ( m['password'] ) { this.password = m.password; }

      this.fname = m['fname'] || '';
      this.lname = m['lname'] || '';

      this.title = m['title'] || '';

      this.phone = m['phone'] || '';

      if ( m['termsUse'] ) { this.termsUse = m.termsUse; }
      if ( m['termsConditions'] ) { this.termsConditions = m.termsConditions; }
   }
}

export const UserLengths = {
   username: 64,
   password: 8,

   fname: 20,
   lname: 30,

   title: 35,
   email: 512,

   phone: 35
};

export function UserModificationGroup( user: any = {}) {
   return new FormGroup({
      fname: new FormControl( user['fname'], [ Validators.maxLength( UserLengths.fname )]),
      lname: new FormControl( user['lname'], [ Validators.maxLength( UserLengths.lname )]),

      title: new FormControl( user['title'], [ Validators.maxLength( UserLengths.title )]),
      phone: new FormControl( user['phone'], [ Validators.maxLength( UserLengths.phone )]),
   });
}

// Admin Stuff
export interface AdminUserModification extends UserModification {
   username: string;

   email?: string;

   sessionLifespan?: number;
   forceReset?: boolean;

   accounts?: {
      accountNumber: string;

      active: boolean;
      primary?: boolean;

      services: string[];
   };
}
