<ng-container *ngIf="user.pinned_reports | async as reports">
   <ng-container *ngIf="reports.length">
      <ng-container *ngIf="breakpoints.small else otherbutton">
         <button mat-icon-button [matMenuTriggerFor]="quickReportMenu" matTooltip="Quick Report">
            <mat-icon>timeline</mat-icon>
         </button>
      </ng-container>

      <ng-template #otherbutton>
         <button mat-button class="button-ish" [matMenuTriggerFor]="quickReportMenu">
            <mat-icon>timeline</mat-icon> Quick Report
         </button>
      </ng-template>

      <mat-menu #quickReportMenu="matMenu" xPosition="before">
         <button mat-menu-item *ngFor="let report of reports; index as i" [routerLink]="[ '/report/run/', report.id ]">
            <mat-chip color="primary" *ngIf="badges && ( i < 9 )" style="transform: scale( 0.8 )">{{i + 1}}</mat-chip>
            {{report.name}}
         </button>
      </mat-menu>
   </ng-container>
</ng-container>

<mat-chip-list></mat-chip-list>
