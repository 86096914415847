import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialsModule } from '@library/common/materials';

import { AlertDialogComponent } from './alert-dialog.component';
import { ConfirmDialogComponent } from './confirm-dialog.component';
import { MessageDialogComponent } from './message-dialog.component';

@NgModule({
   declarations: [
      AlertDialogComponent,
      ConfirmDialogComponent,
      MessageDialogComponent,
   ],
   imports: [
      CommonModule,
      MaterialsModule
   ],
   exports: [
      AlertDialogComponent,
      ConfirmDialogComponent,
      MessageDialogComponent,
   ],
})
export class DialogsModule { }
