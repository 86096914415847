import { FormControl, FormGroup, Validators } from '@angular/forms';

export interface CartageNote {
   date: string;
   time: string;

   code: string;
   note: string;
}

export class CartageNote implements CartageNote {
   constructor( c?: CartageNote ) {
      this.date = c?.date;
      this.time = c?.time;

      this.code = c?.code;
      this.note = c?.note;
   }
}

export function CartageNoteGroup( note: CartageNote = new CartageNote() ): FormGroup {
   return new FormGroup({
      code: new FormControl( note.code || '', Validators.required ),
      note: new FormControl( note.note || '', Validators.required ),
   }, ( g: FormGroup ) => {
      return g.get('code').value && g.get('note').value ? null : { both: true };
   });
}
