import * as faker from 'faker';
import { optional } from '@library/common/functions';

import { Calculator, DimUomUnits, WeightUomUnits } from '../base';

export class MockCalculator extends Calculator {
   constructor() {
      super({
         pieces: faker.datatype.number( 100 ),
         weight: faker.datatype.number( 10000 ),
         weight_type: faker.random.arrayElement( WeightUomUnits ),
         length: faker.datatype.number( 100 ),
         width: faker.datatype.number( 100 ),
         height: faker.datatype.number( 100 ),
         measure_type: faker.random.arrayElement( DimUomUnits ),
      });

      if ( optional() ) { this.description = faker.lorem.words(); }
      if ( optional() ) { this.reference = faker.lorem.words(); }
   }
}
