import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

import { Calculator, WeightUomUnits } from '@library/common/models';
import { WeightConvert } from './convert.pipe';

@Pipe({ name: 'convertweight' })
export class ConvertWeightPipe implements PipeTransform {
   transform( value: number, from: WeightUomUnits, to: WeightUomUnits ): number {
      return WeightConvert( value, from, to );
   }
}

@Pipe({ name: 'dualweights' })
export class DualWeightsPipe extends DecimalPipe implements PipeTransform {
   transform( value: number, valueIs: WeightUomUnits = 'lbs' ): string {
      const lbs = super.transform( WeightConvert( value, valueIs, 'lbs' ).toFixed( 2 ));
      const kgs = super.transform( WeightConvert( value, valueIs, 'kgs' ).toFixed( 2 ));

      return `${lbs} lbs, ${kgs} kgs`;
   }
}

@Pipe({ name: 'totalweight', pure: false })
export class TotalWeightPipe implements PipeTransform {
   transform( value: Calculator[], output: WeightUomUnits = 'lbs' ): number {
      return value.map( item => WeightConvert( item.weight, item.weight_type, output ))
         .reduce(( a, c ) => a + c, 0 );
   }
}
