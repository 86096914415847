import { Calculator, calcArray } from './calculator';

export interface Container {
   number?: string;
   type: string;

   sealNumber?: string;

   items?: Calculator[];
}

export class Container implements Container {
   constructor( c?: Container ) {
      this.number = c?.number;
      this.type = c?.type;

      if ( c?.sealNumber ) { this.sealNumber = c?.sealNumber; }
      if ( c?.items ) {
         this.items = c?.items.map( item => new Calculator( item ));
      }
   }
}

import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
export function containerGroup( c: any = {} ): FormGroup {
   return new FormGroup({
      number: new FormControl( c['number'] ),
      type: new FormControl( c['type'] || '', [ Validators.required ]),
      items: calcArray( c['items'] ),
   });
}

export function containerArray( containers: Container[] = [] ): FormArray {
   const cont = new FormArray( [] );

   containers.filter( c => c.type )
      .map( c => cont.push( containerGroup( c )));

   if ( cont.length < 1 ) {
      cont.push( containerGroup() );
   }

   return cont;
}
