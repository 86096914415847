import { Component, EventEmitter, ElementRef } from '@angular/core';

import { Attachment } from '@library/common/models';

@Component({
   selector: 'lib-attachment-card',
   inputs: [ 'attachment', 'selectable' ],
   outputs: [ 'download', 'checked' ],
   templateUrl: './attachment-card.component.html',
   styleUrls: ['./attachment-card.component.scss']
})
export class AttachmentCardComponent {

   attachment: Attachment;
   selectable = false;

   download: EventEmitter<void> = new EventEmitter();
   checked: EventEmitter<void> = new EventEmitter();

   constructor( public el: ElementRef ) { }

   getExtensionIcon( extension: string = '' ): string {
      switch ( extension ) {
         case 'jpeg':
            return 'JPG';
         case 'tif':
            return 'TIFF';
         default:
            return extension.toUpperCase();
      }
   }
}
