import { FormControl, Validators } from '@angular/forms';

export enum DateTokens {
   Today          = 'T',
   WeekStart      = 'WS',
   WeekEnd        = 'WE',
   MonthStart     = 'MS',
   MonthEnd       = 'ME',
   YearStart      = 'YS',
   YearEnd        = 'YE',
}

export enum RelativeTokens {
   Week_Start         = 'WS',
   Week_End           = 'WE',
   Month_Start        = 'MS',
   Month_End          = 'ME',
   Year_Start         = 'YS',
   Year_End           = 'YE',

   The_Next_Monday    = '+D1',
   The_Next_Tuesday   = '+D2',
   The_Next_Wednesday = '+D3',
   The_Next_Thursday  = '+D4',
   The_Next_Friday    = '+D5',
   The_Next_Saturday  = '+D6',
   The_Next_Sunday    = '+D0',

   Quarter_Start      = 'QS',
   Quarter_End        = 'QE',
}

export enum RelativeDateTokens {
   DayOfTheWeek   = '[+-]D[0-6]',

   WeekStart      = 'WS',
   WeekEnd        = 'WE',
   MonthStart     = 'MS',
   MonthEnd       = 'ME',
   YearStart      = 'YS',
   YearEnd        = 'YE',

   QuarterStart   = 'QS',
   QuarterEnd     = 'QE',
   QuarterMove    = 'Q[1-4][SE]',
}

export const DaysOfTheWeek = [ 'sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', ] as const;
export type DaysOfTheWeek = typeof DaysOfTheWeek[number];

export const MonthsOfTheYear = [
   'january', 'february', 'march', 'april', 'may', 'june',
   'july', 'august', 'september', 'october', 'november', 'december',
] as const;
export type MonthsOfTheYear = typeof MonthsOfTheYear[number];

export const TimePeriods = [ 'D', 'W', 'M', 'Y' ] as const;
export type TimePeriods = typeof TimePeriods[number];
export const TimePeriodsMap = new Map<string, string>([
   [ 'D', 'Day'   ],
   [ 'W', 'Week'  ],
   [ 'M', 'Month' ],
   [ 'Y', 'Year'  ],
]);

export const Delimiters = [ '\\', '/', '-', '.', ' ' ] as const;
export type Delimiters = typeof Delimiters[number];
export const DelimitersMap = new Map<string, string>([
   [ '\\', 'Backslash'    ],
   [ '/',  'Forwardslash' ],
   [ '-',  'Dash'         ],
   [ '.',  'Dot'          ],
   [ ' ',  'Space'        ],
]);

export type Maths = '+' | '-';
export interface Operand {
   operation?: Maths;
   amount?: FormControl;
   period?: string;
   token?: string;
}

export class Operand {
   constructor( operand: any = {} ) {
      if ( operand['token'] ) {
         this.token = operand['token'];
      } else {
         this.operation = operand['operation'] || '+';
         this.amount = new FormControl( operand['amount'] || 1, [
            Validators.min( 1 ),
            Validators.max( 999 ),
            Validators.pattern( /^-?[0-9][^\.]*$/ )
         ]);
         this.period = operand['period'] || TimePeriods[0];
      }
   }
}

export interface ParsedDateString {
   start: string;
   operands: Operand[];
}
