import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import {
   AccountService,
   StartpageService,
   UserService,
} from '@library/modules/user';

@Component({
   selector: 'lib-user-menu',
   templateUrl: './user-menu.component.html',
   styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {

   constructor(
      public user: UserService,
      public account: AccountService,
      public dialog: MatDialog,
      public start: StartpageService,
   ) { }

   ngOnInit() { }
}
