import {
   InternationalShipment,
   internationalForBooking,
   internationalShipmentGroup,
   internationalShipmentCopy,
} from './international-shipment';

import { Milestones } from './milestones'; // enumeration

export const AwbTypes = [ 'Back-to-Back', 'Co-Load', 'Normal Consolidation' ] as const;
export type AwbTypes = typeof AwbTypes[number];

export interface InternationalAirShipment extends InternationalShipment {
   mode: 'air';

   awbType?: AwbTypes;
}

export class InternationalAirShipment extends InternationalShipment {
   mode: 'air' = 'air';

   awbType?: AwbTypes;

   constructor( s?: InternationalAirShipment | InternationalShipment ) {
      super( s );

      this.milestones = Milestones( 'air', s['milestones'] );
      if ( s['awbType'] ) { this.awbType = s['awbType']; }
   }
}

import { FormGroup, FormControl } from '@angular/forms';
export function internationalAirShipmentGroup( s: any = {} ): FormGroup {
   const fg = internationalShipmentGroup( s );

   fg.addControl( 'awbType', new FormControl( s['awbType'] ));

   return fg;
}

export function internationalAirForBooking<S extends InternationalAirShipment>( s: S ): S {
   return ({
      ...internationalForBooking<S>( s ),
      awbType: s['awbType'] ?? undefined,
   });
}

export function internationalAirShipmentCopy<S extends InternationalAirShipment>( s: S, mode: null | 'continue' | 'reverse' = null ): S {
   return ({
      ...internationalShipmentCopy<S>( s, mode ),
      awbType: s['awbType'],
   });
}
