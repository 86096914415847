import { Injectable } from '@angular/core';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';

import { BehaviorSubject, Observable, of, combineLatest, fromEvent } from 'rxjs';
import {
   concatMap,
   delay,
   filter,
   map,
   share,
   startWith,
   switchMap,
   toArray,
   withLatestFrom,
} from 'rxjs/operators';

import { BreadcrumbService } from '@library/core/services';
import { UseraccessService, StartpageService } from '@library/modules/user';

export class NavWidths {
   one = 0;
   two = 0;
   full = 0;
}

export interface NavItem {
   label: string;
   path: string;
   icon: string;
   access?: string;
}

@Injectable({
   providedIn: 'root'
})
export class NavMenuService {
   title = '[portal]';

   container: BehaviorSubject<number> = new BehaviorSubject( 0 );

   navWidths: BehaviorSubject<NavWidths> = new BehaviorSubject( new NavWidths() );

   below$: Observable<boolean> = combineLatest(
      this.container,
      this.navWidths,
      fromEvent( window, 'resize' ).pipe( startWith( true )),
   ).pipe(
      delay( 1 ),
      map(([ container, navs ]) => container < navs.one ),
      share(),
   );

   items: NavItem[] = [];
   showTracking = false;

   get items$(): Observable<NavItem[]> {
      return of( this.items ).pipe(
         switchMap( i => i ),
         concatMap( item => this.access.hasAccess( item?.access )),
         toArray(),
         withLatestFrom( of( this.items ), ( bools, items ) => items.filter(( _, i ) => bools[i] )),
      );
   }

   constructor(
      private breadcrumbs: BreadcrumbService,
      private router: Router,
      private access: UseraccessService,
      private start: StartpageService,
   ) {}

   load( items: NavItem[] ) {
      this.items = items;
      this.start._default = this.items[0];

      this.router.events.pipe(
         filter( event => event instanceof NavigationEnd ),
         startWith({ url: this.router.url }),
      ).subscribe(( event: RouterEvent ) => {
         if ( event.url === '/' ) {
            this.start.startPage$.pipe(
               switchMap( page => page ? of( page ) : this.items$.pipe(
                  map( navItems => navItems[0].path ),
               ))
            ).subscribe( path => {
               this.router.navigate([ path ]);
            });
         }

         // if current path matches exactly with a base route, start a new crumb
         const current = items.filter( item => item.path === event.url )[0];
         if ( current ) {
            this.breadcrumbs.newCrumb( current );
         }

         // setting base breadcrumb if it doesn't have one
         if ( this.breadcrumbs.breadcrumbs.size === 0 ) {
            let url = event.url.slice();
            if ( url.includes( '/', 1 )) {
               url = url.slice( 0, url.indexOf( '/', 1 ));
            }
            const base = items.filter( item => item.path === url )[0];
            if ( base ) {
               this.breadcrumbs.newCrumb( base );
            }
         }
      });
   }
}
