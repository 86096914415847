export const ErrorMessageTypes = [ 'error', 'alert', 'notification' ] as const;
export type ErrorMessageTypes = typeof ErrorMessageTypes[number];

export interface ErrorMessage {
   id?: number;
   type: ErrorMessageTypes;
   code: string;
   message: string;
   data?: any;
}

export class ErrorMessage implements ErrorMessage {
   constructor( e?: ErrorMessage ) {
      if ( e?.id ) { this.id = e?.id; }
      this.type = e?.type;
      this.code = e?.code;
      this.message = e?.message;
      if ( e?.data ) { this.data = e?.data; }
   }
}

export interface Pagination {
   hash: string;
   page: number;
   total?: number;
}

export interface ResponseWrapper<Type = any> {
   messages: ErrorMessage[];
   type: string;
   response: {
      collection: Type[];
      pagination?: Pagination;
   };
}
