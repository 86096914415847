import { ShipmentModes } from '../shipment-modes';

import { RoadMilestones } from './road-milestones';
import { AirMilestones } from './air-milestones';
import { OceanMilestones } from './ocean-milestones';

export function Milestones( mode: ShipmentModes = 'road', current?: any ) {
   switch ( mode ) {
      case 'ocean':
         return new OceanMilestones( current );
      case 'air':
         return new AirMilestones( current );
      case 'road':
      default:
         return new RoadMilestones( current );
   }
}

export interface Milestone {
   code: string;
   order: number;
   position: number;
   icon: string;
   label: string;
}

export class Milestone {
   code = '';
   order = 0;
   position = 0;
   icon = '';
   label = '';
}

export function MilestonesConst( modes: ShipmentModes[] = ShipmentModes.slice() ): Milestone[] {

   return modes.map( m => {
      switch ( m ) {
         case 'ocean':
            return oceanMilestones;
         case 'air':
            return airMilestones;
         case 'road':
         default:
            return roadMilestones;
      }
   }).reduce(( a, c ) => [ ...a, ...c ], [] )
      .filter(( v, i, a ) => a.map( m => m.code ).indexOf( v.code ) === i )
      .sort(( a, b ) => a.order - b.order );
}

export const [
   Booked, Confirmed, Cancelled, Pickedup,
   InTransit,
   TenderedToAirline, DepartedAir, ArrivedAir,
   GatedIn, DepartedOcean, ArrivedOcean, InlandDelivery,
   OutForDelivery, Delivered, CompletePODD, CompletePOD,
]: Milestone[] = [
   { code: 'booked',        order: 0,  position: 0, icon: 'booked',         label: 'Booked' },
   { code: 'confirmed',     order: 1,  position: 0, icon: 'confirmed',      label: 'Confirmed' },
   { code: 'cancelled',     order: 2,  position: 0, icon: 'cancelled',      label: 'Cancelled' },
   { code: 'pickedup',      order: 3,  position: 1, icon: 'pickedup',       label: 'Picked Up' },

   // Road
   { code: 'intransit',     order: 6,  position: 2, icon: 'intransit',      label: 'In Transit' },

   // Air
   { code: 'tendered',      order: 4,  position: 1, icon: 'tendered',       label: 'Tendered to Airline' },
   { code: 'departedair',   order: 7,  position: 2, icon: 'departedair',    label: 'Departed Origin Airport' },
   { code: 'arrivedair',    order: 9,  position: 3, icon: 'arrivedair',     label: 'Arrived Destination Airport' },

   // Ocean
   { code: 'gated',         order: 5,  position: 1, icon: 'gated',          label: 'Gated In' },
   { code: 'departedocean', order: 8,  position: 2, icon: 'departedocean',  label: 'Departed Origin Port' },
   { code: 'arrivedocean',  order: 10, position: 3, icon: 'arrivedocean',   label: 'Arrived Destination Port' },
   { code: 'inland',        order: 11, position: 3, icon: 'outfordelivery', label: 'Arrived Inland Destination' },

   // Shared
   { code: 'delivery',      order: 12, position: 3, icon: 'outfordelivery', label: 'Out for Delivery' },
   { code: 'delivered',     order: 13, position: 4, icon: 'delivered',      label: 'Delivered' },
   { code: 'podd',          order: 14, position: 4, icon: 'pod',            label: 'Completed - PODD' },
   { code: 'pod',           order: 15, position: 4, icon: 'pod',            label: 'Completed - POD' },
];

export const roadMilestones: Milestone[] = [
   Booked, Confirmed, Cancelled, Pickedup, InTransit, OutForDelivery, Delivered, CompletePOD,
];

export const airMilestones: Milestone[] = [
   Booked, Confirmed, Cancelled, Pickedup, TenderedToAirline, DepartedAir, ArrivedAir, OutForDelivery, CompletePODD, CompletePOD,
];

export const oceanMilestones: Milestone[] = [
   Booked, Confirmed, Cancelled, Pickedup, GatedIn, DepartedOcean, ArrivedOcean,
   // InlandDelivery,
   CompletePODD, CompletePOD,
];
