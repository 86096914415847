import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { SignatureCaptureComponent } from './signature-capture.component';

import { MaterialsModule } from '@library/common/materials';
import { DirectivesModule } from '@library/core/directives';

import { Angular2SignaturepadModule } from 'angular2-signaturepad';

@NgModule({
   declarations: [ SignatureCaptureComponent ],
   imports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule,

      MaterialsModule,

      DirectivesModule,

      Angular2SignaturepadModule,
   ],
   exports: [ SignatureCaptureComponent ],
   entryComponents: [ SignatureCaptureComponent ],
})
export class SignatureCaptureModule { }
