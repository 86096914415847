export const CODTypes = [ 'check', 'other', ] as const;
export type CODTypes = typeof CODTypes[number];

export interface CODInterface {
   type: CODTypes;
   amount: number;
   currency: string;
}

export class COD implements CODInterface {
   type: CODTypes;
   amount: number;
   currency: string;

   constructor( cod?: COD ) {
      this.type = cod?.type;
      this.amount = cod?.amount;
      this.currency = cod?.currency;
   }
}

import { FormGroup, FormControl } from '@angular/forms';
export function codGroup( cod: any = {} ): FormGroup {
   return new FormGroup({
      type: new FormControl( cod['type'] ),
      amount: new FormControl( cod['amount'] ),
      currency: new FormControl( cod['currency'] )
   }, {
      validators: ( form: FormGroup ) => {
         if ( form.controls?.type?.value === 'check' ) {
            const allOf = [
               form.controls?.type?.value ? null : 'Type',
               form.controls?.amount?.value ? null : 'Amount',
               form.controls?.currency?.value ? null : 'Currency',
            ].filter( _ => !!_ );

            return allOf.length ? { allOf } : null;
         }

         return null;
      }
   });
}
