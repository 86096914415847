export interface StatusInterface {
   code: string;
   status: string;
   date: string;
}

export class Status implements StatusInterface {
   code: string;
   status: string;
   date: string;

   constructor( s?: Status ) {
      this.code = s.code;
      this.status = s.status;
      this.date = s.date;
   }

   public toString = (): string => {
      return this.status + '◬' + this.date;
   }
}
