import * as faker from 'faker';
import { randomArray, optional } from '@library/common/functions';

import { MockAttachment } from '@library/common/models/mock';
import { MockCartageCharge } from '../models/mock/cartage-charge.mock';
import { MockCartagePod } from '../models/mock/cartage-pod.mock';
import { MockCartageStatus } from '../models/mock/cartage-status.mock';

import { BulkUpdateTypes } from '../update';

import { BulkQueue, BulkQueueJob } from './bulk-queue';

export class MockBulkQueue extends BulkQueue {
   constructor( type?: BulkUpdateTypes ) {
      super({
         id: faker.datatype.uuid(),
         label: faker.company.catchPhrase(),

         user: faker.name.findName(),
         account: faker.company.companyName(),
         uploaded: faker.date.recent(),

         actionType: type || faker.random.arrayElement( BulkUpdateTypes ),
         actions: [],
         jobs: randomArray( MockBulkQueueJob, 20, 1 ),
      });

      this.actions = getAction( this.actionType );
   }
}

function getAction( type: BulkUpdateTypes = BulkUpdateTypes[0] ): any[] {
   switch ( type ) {
      case 'driver':
         return [ faker.name.findName() ];
      case 'status':
         return randomArray( MockCartageStatus, 3, 1 );
      case 'manifest':
         return [ new MockAttachment() ];
      case 'attachment':
         return randomArray( MockAttachment, 10, 1 );
      case 'note':
         return [ faker.lorem.paragraphs(7) ];
      case 'pod':
         return [ new MockCartagePod() ];
      case 'charge':
         return randomArray( MockCartageCharge, 10, 1 );
   }
}

export class MockBulkQueueJob extends BulkQueueJob {
   constructor() {
      super({
         job: faker.datatype.number( 999999999 ),
         completed: optional( 70 ),
         error: optional( 30 ),
      });
   }
}
