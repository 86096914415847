export interface Theme {
   parent: string;
   title: string;
   contactus: string;
   terms: string;
   favIcon: string;
   logo: string;

   primaryColor: string;
   primaryContrast: string;
   accentColor: string;
   accentContrast: string;
   warnColor: string;
   warnContrast: string;

   headerColor: string;
   headerContrast: string;
   secondaryColor: string;
   secondaryContrast: string;
}

const $dark_primary_text = 'rgba(black, 0.87)';
// const $dark_secondary_text = 'rgba(black, 0.54)';
const $light_primary_text = 'white';
// const $light_secondary_text = 'rgba(white, 0.7)';

// const CDN = 'https://rgl-static-cdn.s3.amazonaws.com';
// const monograms = 'https://rgl-static-cdn.s3.amazonaws.com/monograms/';
// const emailAssets = 'https://rgl-static-cdn.s3.amazonaws.com/email-assets/';

const defaults = {
   parent: 'http://www.radiantdelivers.com',
   contactus: 'https://rgl.radiantdelivers.com/contact-us',
   terms: 'http://www.radiantdelivers.com/terms',
   title: 'Radiant',
   favIcon: 'radiant-favicon.ico',
   logo: '/assets/branding/logos/radiant-logo.png',

   primaryColor: '#151450',
   primaryContrast: $light_primary_text,
   accentColor: '#3496d0',
   accentContrast: $light_primary_text,
   warnColor: '#F44336',
   warnContrast: $light_primary_text,

   headerColor: '#b71c1c',
   headerContrast: $light_primary_text,
   secondaryColor: '#F5F5F5',
   secondaryContrast: $dark_primary_text,
};

export const Themes: { [ key: string ]: Theme } = {
   radiant: { ...defaults },

   dev: {
      ...defaults,
      parent: 'http://www.google.com',
      contactus: 'www.google.com/intl/en/about/',
      terms: 'https://policies.google.com/terms',
      title: 'Development',
      favIcon: 'dev-favicon.ico',
      logo: '/assets/branding/logos/dev-logo.png',

      primaryColor: '#80c684',
      primaryContrast: $dark_primary_text,
      accentColor: '#bbf6cc',
      accentContrast: $dark_primary_text,

      headerColor: '#1c5f20',
      headerContrast: $light_primary_text,
      secondaryColor: '#558469',
      secondaryContrast: $light_primary_text,
   },

   airgroup: {
      ...defaults,
      parent: 'http://www.airgroup.com',
      contactus: 'https://airgroup.com/contact-us',
      terms: 'http://www.airgroup.com/terms',
      title: 'Airgroup',
      favIcon: 'airgroup-favicon.ico',
      logo: '/assets/branding/logos/airgroup-logo.png',

      accentColor: '#295499',
      accentContrast: $light_primary_text,

      headerColor: '#295499',
      headerContrast: $light_primary_text,
   },

   adcom: {
      ...defaults,
      parent: 'http://www.adcomworldwide.com',
      contactus: 'https://www.adcomworldwide.com/contact-us',
      terms: 'http://www.adcomworldwide.com/terms',
      title: 'Adcom Worldwide',
      favIcon: 'adcom-favicon.ico',
      logo: '/assets/branding/logos/adcom-logo.png',
   },

   dba: {
      ...defaults,
      parent: 'http://www.dbaco.com',
      contactus: 'https://dbaco.com/contact-us',
      terms: 'http://www.dbaco.com/terms',
      title: 'Distribution by Air',
      favIcon: 'dba-favicon.ico',
      logo: '/assets/branding/logos/dba-logo.png',

      headerColor: '#179b36',
      headerContrast: $light_primary_text,
   },

   sba: {
      ...defaults,
      parent: 'http://www.sbaglobal.com',
      contactus: 'https://www.sbaglobal.com/contact-us',
      terms: 'http://www.sbaglobal.com/terms',
      title: 'SBA Global Logistic Services',
      favIcon: 'sba-favicon.ico',
      logo: '/assets/branding/logos/sba-logo.png',

      headerColor: '#3597d0',
      headerContrast: $light_primary_text,
   },

   clipper: {
      ...defaults,
      parent: 'http://www.radiantclipper.com',
      contactus: 'https://www.radiantclipper.com/contact-us',
      terms: 'http://www.radiantclipper.com/terms',
      title: 'Radiant Clipper',
      favIcon: 'clipper-favicon.ico',
      logo: '/assets/branding/logos/clipper-logo.png',

      accentColor: '#295499',
      accentContrast: $light_primary_text,

      headerColor: '#295499',
      headerContrast: $light_primary_text,
   },

   canada: {
      ...defaults,
      parent: 'http://www.radiantdeliversca.com',
      contactus: 'https://radiantdeliversca.com/contact-us',
      terms: 'http://www.radiantdeliversca.com/terms',
      title: 'Radiant Canada',
      favIcon: 'canada-favicon.ico',
      logo: '/assets/branding/logos/canada-logo.png',
   },

   vintage: {
      ...defaults,
      parent: 'http://www.vintage-logistics.com',
      contactus: 'www.vintage-logistics.com/contact.html',
      terms: 'http://www.vintage-logistics.com/terms',
      title: 'Vintage',
      favIcon: 'vintage-favicon.ico',
      logo: '/assets/branding/logos/vintage-logo.png',

      primaryColor: '#183039',
      primaryContrast: $light_primary_text,
      accentColor: '#406b82',
      accentContrast: $light_primary_text,
      warnColor: '#b51c1c',
      warnContrast: $light_primary_text,

      headerColor: '#bfd5e3',
      headerContrast: $light_primary_text,
   },

   hawaiianair: {
      ...defaults,
      title: 'Hawaiian Airlines',
      favIcon: 'hawaiianair-favicon.ico',
      logo: '/assets/branding/logos/hawaiianair-logo.png',

      primaryColor: '#463c8f',
      primaryContrast: $light_primary_text,
      accentColor: '#ce0c88',
      accentContrast: $light_primary_text,

      headerColor: '#ffffff',
      headerContrast: $dark_primary_text,
   },
};
