import { ValidatorFn, ValidationErrors, AbstractControl } from '@angular/forms';
import * as moment_ from 'moment';
const moment = moment_;

export function minDateValidator( compare: AbstractControl ): ValidatorFn {
   return ( control: AbstractControl ): ValidationErrors | null => {
      const date = moment( compare?.value ?? moment().startOf( 'day' ));

      if ( moment( date ).isAfter( moment( control.value ))) {
         return { matDatepickerMin: { min: date, actual: control.value }};
      }

      return null;
   };
}
