import * as faker from 'faker';

import { CartageListItem } from '../cartage-list-item';

import { MockCartageBase } from './cartage-base.mock';

export class MockCartageListItem extends CartageListItem {
   constructor() {
      super({
         ...new MockCartageBase() as any,
         pieces: faker.datatype.number(),
         piecesType: faker.random.arrayElement([ 'pcs', 'crt' ]),
         weight: faker.datatype.number(),
         weightType: faker.random.arrayElement([ 'lbs', 'kgs' ]),
         chargeTotal: faker.datatype.number(),
         currency: 'USD',
      });
   }
}
