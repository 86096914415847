export const RisksTypes = [ 'declared_value', 'insurance', ] as const;
export type RisksTypes = typeof RisksTypes[number];

export interface RisksInterface {
   type: RisksTypes;
   amount: number;
   currency: string;

   dangerousGoods?: boolean;
}

export class Risks implements RisksInterface {
   type: RisksTypes;
   amount: number;
   currency: string;

   dangerousGoods?: boolean;

   constructor( risks?: Risks ) {
      if ( risks?.type ) {
         this.type = risks?.type;
         this.amount = risks?.amount;
         this.currency = risks?.currency;
      }

      if ( risks?.dangerousGoods ) {
         this.dangerousGoods = risks?.dangerousGoods;
      }
   }
}

import { FormGroup, FormControl } from '@angular/forms';
export function risksGroup( risks: any = {} ): FormGroup {
   return new FormGroup({
         type: new FormControl( risks['type'] ),
         amount: new FormControl({ value: risks['amount'], disabled: true }),
         currency: new FormControl({ value: risks['currency'], disabled: true }),
         dangerousGoods: new FormControl( risks?.dangerousGoods ),
      }, {
         validators: ( form: FormGroup ) => {
            const opts = { onlySelf: true, emitEvent: false };
            if ( form.get( 'type' ).value ) {
               form.get( 'amount' ).enable( opts );
               form.get( 'currency' ).enable( opts );

               const allOf = [
                  form.get( 'type' ).value ? null : 'Type',
                  form.get( 'amount' ).value ? null : 'Amount',
                  form.get( 'currency' ).value ? null : 'Currency',
               ].filter( _ => !!_ );

               return allOf.length ? { allOf } : null;
            } else {
               form.get( 'amount' ).disable( opts );
               form.get( 'currency' ).disable( opts );

               return null;
            }
         }
      });
}
