import { Injectable } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { environment } from './environment';

import { MockService } from './mock.service';

import { MockHttpInterceptorBase } from '@library/common/http';

@Injectable()
export class MockHttpInterceptor extends MockHttpInterceptorBase {
   constructor( public mockService: MockService ) {
     super();
   }

   isMock(): boolean {
      return environment.mock;
   }
}

export let mockHttpInterceptor = {
   provide: HTTP_INTERCEPTORS,
   useClass: MockHttpInterceptor,
   multi: true
};
