import {
   InternationalShipment,
   internationalForBooking,
   internationalShipmentGroup,
   internationalShipmentCopy,
} from './international-shipment';

import { Milestones } from './milestones';

import { calcGroup, Container, containerArray } from '../base';

export const ShippingTypes = [ 'FCL', 'LCL', 'RORO', 'Break Bulk Cargo', 'Charter Cargo' ];
export type ShippingTypes = typeof ShippingTypes[number];

export interface InternationalOceanShipment extends InternationalShipment {
   mode: 'ocean';

   itemsType: 'LCL' | 'FCL';
   itemized: boolean;
   containers?: Container[];

   shippingType?: ShippingTypes;

   teu?: number;

   origin?: string;
   destination?: string;
}

export class InternationalOceanShipment extends InternationalShipment {
   mode: 'ocean' = 'ocean';

   constructor( s?: InternationalOceanShipment | InternationalShipment ) {
      super( s );

      if ( s['shippingType'] ) { this.shippingType = s['shippingType'] || 'LCL'; }
      if ( s['teu'] ) { this.teu = s['teu']; }

      this.itemsType = s['itemsType'] || 'LCL';
      this.itemized = s['itemized'] || false;
      if ( s['containers'] ) { this.containers = s['containers']; }

      if ( s['origin'] ) { this.origin = s['origin']; }
      if ( s['destination'] ) { this.destination = s['destination']; }

      if ( s['shippingType'] && s['shippingType'] !== 'LCL' ) {
         if ( s['calculator'] ) { delete( s.calculator); }
         delete( this.calculator );
      }

      this.milestones = Milestones( 'ocean', s['milestones'] );
   }
}

import { FormGroup, FormControl, Validators } from '@angular/forms';
export function internationalOceanShipmentGroup( s: any = {} ): FormGroup {
   const fg = internationalShipmentGroup( s );

   fg.addControl( 'itemsType', new FormControl( s['itemsType'] || 'LCL', Validators.required ));
   fg.addControl( 'itemized', new FormControl( s['itemized'] || false, Validators.required ));

   fg.addControl( 'containers', containerArray( s['containers'] ));

   fg.addControl( 'shippingType', new FormControl( s['shippingType'] || 'LCL' ));
   fg.addControl( 'teu', new FormControl( s['teu'] || '' ));

   fg.addControl( 'origin', new FormControl( s['origin'] || '' ));
   fg.addControl( 'destination', new FormControl( s['destination'] || '' ));

   return fg;
}

export function internationalOceanForBooking<S extends InternationalOceanShipment>( s: S ): S {
   const shipment = internationalForBooking( s );

   if ( s.itemsType === 'FCL' ) {
      const containers = s.containers.filter( c => c.type );
      containers.map(( c, index ) => {
         containers[index].items = c.items.filter( i => i.pieces && i.weight )
            .map( i => ({ ...i, pieces: i.pieces || 1, weight: i.weight || 1 }));
         if ( !containers[index].items.length ) {
            // make sure all calc items have at least one item with pcs/wgt
            containers[index].items.push( calcGroup({ pieces: 1, weight: 1 }).value );
         }
      });
      shipment.containers = s.containers;
      delete( shipment.calculator );
   } else {
      delete( shipment.containers );
   }

   return ({
      ...shipment,
      shippingType: s.shippingType,

      origin: s.origin,
      destination: s.destination,

      itemsType: s.itemsType,
      itemized: s.itemized,
   }) as S;
}

export function internationalOceanShipmentCopy<S extends InternationalOceanShipment>(
   s: S, mode: null | 'continue' | 'reverse' = null
): S {
   return ({
      ...internationalShipmentCopy<S>( s, mode ),
      itemsType: s['itemsType'],
      itemized: s['itemized'],
      containers: s['containers'],
      shippingType: s['shippingType'],
      teu: s['teu'],
      origin: s['origin'],
      destination: s['destination'],
   });
}
