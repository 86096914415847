export const SharedKeys = [ 'private', 'owned', 'public' ] as const;
export type SharedKeys = typeof SharedKeys[number];

export function SharedKeysMap( obj: { shared: boolean, owned: boolean }): SharedKeys {
   return obj.shared ? obj.owned ? 'owned' : 'public' : 'private';
}

export enum SharedKeysEnum {
   private = 'Private',
   owned = 'Shared by you',
   public = 'Shared with you',
}
