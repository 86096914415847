import { Component, OnInit, Input } from '@angular/core';
import { FormArray } from '@angular/forms';
import { EnumsService } from '@library/core/services';
import { CartageEnumsService } from '../../cartage.enums.service';
import { CartageChargeGroup } from '../../models/cartage-charge';
import { CartageItemTypes } from '../../models/cartage-item-types';

@Component({
  selector: 'app-charge-update',
  templateUrl: './charge-update.component.html',
  styleUrls: ['./charge-update.component.scss', '../cartage-update.scss']
})
export class ChargeUpdateComponent implements OnInit {

   @Input() readonly form: FormArray;
   @Input() readonly clearable: boolean = true;
   @Input() readonly type: CartageItemTypes;

  constructor(
     public cartageEnums: CartageEnumsService,
     public enums: EnumsService,
  ) { }

  ngOnInit(): void {
  }

  addGroup(): void {
     this.form.push( CartageChargeGroup() );
  }
}
