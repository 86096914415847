import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';

import { BreakpointService } from '@library/core/services';

@Component({
   selector: 'lib-save-button',
   templateUrl: './save-button.component.html',
   styleUrls: ['./save-button.component.scss'],
   animations: [
      trigger( 'checkfade', [
         transition( ':enter', [
            style({ opacity: 0 }),
            animate( 200 ),
            animate( 1000 ),
            animate( 1000, style({ opacity: 0 })),
         ]),
      ]),
   ],
})
export class SaveButtonComponent implements OnChanges {

   @Input() state: 'show' | 'saving' | 'saved';
   @Input() disabled = true;
   @Input() showCancel = false;

   @Input() saveButtonText = 'save';
   @Input() cancelButtonText = 'cancel';

   @Input() saveButtonIcon = 'save';
   @Input() cancelButtonIcon = 'clear';

   @Input() buttonColor = 'accent';

   @Output() save: EventEmitter<boolean> = new EventEmitter();
   @Output() cancel: EventEmitter<boolean> = new EventEmitter();

   timeout;

   constructor(
      public breakpoints: BreakpointService,
   ) { }

   ngOnChanges() {
      clearTimeout( this.timeout );
      if ( this.state === 'saved' ) {
         this.timeout = setTimeout( _ => this.state = null, 2000 );
      }
   }
}
