import { optional } from '@library/common/functions';
import * as faker from 'faker';

import { mockCountries } from './countries.mock';
import { MockContact } from './contact.mock';

import { Business } from '../base';

export class MockBusiness extends Business {
   constructor() {
      super();

      this.id = faker.datatype.number();
      this.name = faker.company.companyName();
      this.address1 = faker.address.streetAddress();
      if ( optional() ) { this.address2 = faker.address.secondaryAddress(); }
      if ( optional() ) { this.address3 = faker.address.secondaryAddress(); }
      this.city = faker.address.city();

      const country = faker.random.arrayElement( mockCountries );
      this.country = country.code;
      if ( country['regions'] ) {
         this.state = faker.random.arrayElement( country.regions ).code;
         this.postalcode = country.postalDetails.test;
      }

      if ( optional() ) { this.phone = faker.phone.phoneNumber().slice( 0, 25 ); }
      if ( optional() ) { this.fax = faker.phone.phoneNumber().slice( 0, 25 ); }
      if ( optional() ) { this.email = faker.internet.email(); }
      if ( optional() ) { this.contacts = Array.from({ length: faker.datatype.number( 10 ) }, _ => new MockContact() ); }
   }
}
