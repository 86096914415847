import * as faker from 'faker';

export class PinnedReport {
   id: number;
   name: string;
}

export class MockPinnedReport extends PinnedReport {
   constructor() {
      super();

      this.id = faker.datatype.number( 100 );
      this.name = faker.company.catchPhrase();
   }
}
