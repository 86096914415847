import * as faker from 'faker';
import { optional } from '@library/common/functions';
import { DatePipe } from '@angular/common';

import { MockAttachment } from '@library/common/models/mock';

import { CartageItemTypes } from '../cartage-item-types';
import { CartageMilestones } from '../milestones';

import { dateTimeFormat } from '@library/common/models';

import { CartageDetail } from '../cartage-detail';

import { MockCartageBase } from './cartage-base.mock';
import { MockCartageDetailItem } from './cartage-detail-item.mock';
import { MockCartageCharge } from './cartage-charge.mock';
import { MockCartagePod } from './cartage-pod.mock';

import moment from 'moment';

export class MockCartageDetail extends CartageDetail {
   constructor( type?: CartageItemTypes ) {
      super({
         ...new MockCartageBase( type ) as any,
      });

      if ( optional() ) { this.pickupInstructions = optional( 80 ) ? faker.lorem.words() : faker.lorem.paragraphs(); }
      if ( optional() ) { this.deliveryInstructions = optional( 80 ) ? faker.lorem.words() : faker.lorem.paragraphs(); }

      this.items = Array.from({ length: faker.datatype.number({ min: 1, max: 10 }) }, _ => new MockCartageDetailItem() );

      this.charges = Array.from({ length: faker.datatype.number({ min: 1, max: 20 }) }, _ => new MockCartageCharge() );

      if ( optional() ) { this.notes = Array.from({ length: faker.datatype.number( 3 )}, _ => mockCartageNote() ).join( `\r\n` ); }

      this.attachments = Array.from({ length: faker.datatype.number( 3 )}, _ => new MockAttachment() );

      if ( optional( 40 ) ) { this.pod = new MockCartagePod(); }

      this.milestones = CartageMilestones( this.type );
      Object.keys( this.milestones ).map( key =>
         this.milestones[key] = optional() ? moment(faker.date.past()).format( dateTimeFormat ) : null );
   }
}

function mockCartageNote() {
   const date = new DatePipe('en').transform( faker.date.recent(), 'medium' );
   const user = optional( 80 ) ? faker.name.findName() : faker.internet.email();
   const note = faker.lorem.paragraphs( faker.datatype.number({ min: 1, max: 5 }));

   return `[ ${date} - VP ${user} ] \r\n ${note} \r\n`;
}
