import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'spacers' })
export class SpacersPipe implements PipeTransform {
   transform( value: string ): string {
      if ( !value ) { return; }

      return value.replace( /_/g, ' ' );
   }
}
