import { Injectable } from '@angular/core';

import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
   providedIn: 'root'
})
export class BrowserCheckService {

   constructor( private snackbar: MatSnackBar ) {
      this.check();
   }

   check() {
      // pop a warning for old browsers

      // Internet Explorer 6-11
      const isIE = /*@cc_on!@*/false || !!(document as any).documentMode;

      if ( isIE ) {
         this.snackbar.open(
            'This website was designed for use in modern browsers. Some features may not be available.',
            'Ok',
            {
               verticalPosition: 'top',
               duration: 10000
            }
         );
      }
   }
}
