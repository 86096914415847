import { Injectable } from '@angular/core';

@Injectable({
   providedIn: 'root'
})
export class HelpService {
   tooltips: string[] = [];

   reset() {
      this.tooltips = [];
   }
}
