import * as faker from 'faker';
import { optional } from '@library/common/functions';

import { MockCalculator } from './calculator.mock';

import { Container } from '../base';

export class MockContainer extends Container {
   constructor( itemized = false ) {
      super({
         type: faker.lorem.word(),
      });

      if ( optional() ) { this.number = faker.datatype.number(); }
      if ( optional() ) { this.sealNumber = faker.lorem.word(); }
      if ( itemized || optional() ) {
         this.items = Array.from({ length: faker.datatype.number( 10 )}, _ => new MockCalculator() );
      }
   }
}
