import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';

import { Attachment, attachmentGroup } from '@library/common/models';
import moment from 'moment';
import {
   CartageCharge, CartageChargeGroup,
   CartageItemTypes,
   CartagePod, CartagePodGroup,
   CartageStatus, CartageStatusGroup,
} from '../models';

export const BulkUpdateTypes = [ 'status', 'driver', 'manifest', 'note', 'attachment', 'pod', 'charge' ] as const;
export type BulkUpdateTypes = typeof BulkUpdateTypes[ number ];

export const BulkUpdateTypesIconMap = {
   status: 'add_location',
   driver: 'directions_car',
   manifest: 'text_snippet',
   note: 'notes',
   attachment: 'add_a_photo',
   pod: 'edit',
   charge: 'attach_money',
};

export interface UpdateJob {
   job: number;
   type: CartageItemTypes;
}

export interface CartageUpdate {
   jobs: UpdateJob[];
   async: boolean;
   actionType: BulkUpdateTypes;

   batchLabel?: string;

   driver?: string[];
   status?: CartageStatus[];
   charge?: CartageCharge[];
   note?: string[];
   attachment?: Attachment[];
   pod?: CartagePod[];
}

export class CartageUpdate implements CartageUpdate {
   constructor( obj: any = {} ) {
      this.jobs = obj?.jobs || [];
      this.async = obj?.async || obj?.jobs?.length > 1;
      this.actionType = obj?.actionType || obj?.type;

      if ( obj?.batchLabel ) { this.batchLabel = obj.batchLabel; }

      if ( obj?.driver ) { this.driver = obj.driver; }
      if ( obj?.status ) { this.status = obj.status; }
      if ( obj?.charge ) { this.charge = obj.charge; }
      if ( obj?.note ) { this.note = obj.note; }
      if ( obj?.attachment ) { this.attachment = obj.attachment; }

      if ( obj?.update ) {
         switch ( this.actionType ) {
            case 'driver':
               this.driver = obj.update;
               break;
            case 'status':
               this.status = obj.update.map( s => new CartageStatus( s ));
               break;
            case 'manifest':
               this.attachment = [ extractManifest(( this.batchLabel ? this.batchLabel : this.jobs[0].job.toString() ), obj.update ) ];
               break;
            case 'pod':
               this.pod = extractPOD(( this.batchLabel ? this.batchLabel : this.jobs[0].job.toString() ), obj.update[0] );
               break;
            default:
               this[this.actionType] = obj.update;
         }
      }
   }
}

export function CartageUpdateGroup( values? ): FormGroup {
   const formGroup = new FormGroup({
      type: new FormControl( values.type ),
      jobs: new FormControl( values.jobs ),
      batchLabel: new FormControl( values?.batchLabel ),
      update: new FormArray([], [
         Validators.required,
         Validators.minLength( 1 ),
      ]),
   }, [
         group => { // Batch Label Validator
            return ( group.get( 'jobs' ).value.length > 1 && !group.get( 'batchLabel' ).value )
               ? { batchLabel: 'required' }
               : null;
         },
         group => { // Manifest Validator
            if ( group.get( 'type' ).value !== 'manifest' ) { return null; }

            const array = ( group.get( 'update' ) as FormArray );
            const pass = array.length > 1 && array.controls.slice( 0, -1 ).every( c => !!c.value );

            return pass ? null : { error: 'invalid' };
         }
      ],
   );

   if ( values.update ) {
      values?.update.map( update =>
         ( formGroup.get( 'update' ) as FormArray ).push( getGroup( values.type, update )));
   }

   return formGroup;
}

export function getGroup( type: BulkUpdateTypes, value?: any ) {
   switch ( type ) {
      case 'status':
         return CartageStatusGroup( value );
      case 'driver':
         return new FormControl( value, [ Validators.required ]);
      case 'manifest':
         return new FormControl( value );
      case 'note':
         return new FormControl( value, [ Validators.required ]);
      case 'attachment':
         return attachmentGroup( value );
      case 'pod':
         return CartagePodGroup( value );
      case 'charge':
         return CartageChargeGroup( value );
      default:
         return;
   }
}

function extractManifest( label: string, values: string[] ): Attachment {
   let dataString = '';

   values.filter( _ => _ ).forEach( item => {
      dataString += item + '\r\n';
   });

   const filename: string = 'manifest_' + label + '.txt';
   const newFile = new File([ dataString ], filename, { type: 'text/plain' });

   const manifest: Attachment = {
      type: 'Attachment',
      filename: newFile.name,
      extension: '.txt',
      size: newFile.size,
      mimetype: newFile.type,
      data: dataString,
      name: filename.split( '.' )[0]
   };

   return manifest;
}

function extractPOD( label: string, value: any ): CartagePod[] {
   const attachment = [];

   const pod: CartagePod = new CartagePod( value );
   pod.date = moment( pod.date ).format( 'YYYY-MM-DD' ); // Datetime picker gives us a moment

   value.attachmentData?.forEach( data => {
      const signatureFile = new File( [ data ], `signature_${label}.jpg`, { type: 'image/jpeg' });

      const sig: Attachment = {
         type: 'POD Signature',
         filename: signatureFile.name,
         extension: 'jpg',
         size: signatureFile.size,
         mimetype: signatureFile.type,
         data: data,
         name: signatureFile.name.split( '.' )[0],
      };

      attachment.push( sig );
      delete( value.attachmentData );

      pod.attachment = attachment;
   });

   return [ pod ];
}
