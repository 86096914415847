import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map, tap, switchMap } from 'rxjs/operators';

import { EnvironmentService } from '@library/core/services';

import { CartageService } from '../../cartage.service';

class Driver {
   name: string;
   ex: Date;

   constructor( name: string, longCache: boolean = false ) {
      this.name = name;
      this.ex = new Date();

      if ( longCache ) {
         this.ex.setHours( this.ex.getHours() + 96 ); // cache for 96 hours
      } else {
         this.ex.setMinutes( this.ex.getMinutes() + 1 ); // cache for 1 minute
      }
   }
}

@Injectable({
   providedIn: 'root'
})
export class DriverService {
   constructor(
      private cartage: CartageService,
      private environment: EnvironmentService,
   ) {
      this.cartage.refresh$.pipe(
         switchMap( _ => this.cartage.list() ),
         map( list => list.map( item => item.driver )),
         map( drivers => [ ...new Set( drivers ) ].filter( d => d !== 'Unassigned' ).filter( d => d )),
         map( drivers => drivers.map( d => new Driver( d, this.environment.mock ))),
         tap( _ => localStorage.removeItem( 'drivers' )),
         map( newDrivers => {
            const oldDrivers = JSON.parse( sessionStorage.getItem( 'Drivers' ) || '[]' );

            return [ ...newDrivers, ...oldDrivers ].reduce(( unique, driver ) =>
               // no duplicate names
               unique.map( d => d.name ).includes( driver.name ) ? unique : [ ...unique, driver ], [] );
         })
      ).subscribe( drivers => sessionStorage.setItem( 'Drivers', JSON.stringify( drivers )));
   }

   getDrivers(): Observable<string[]> {
      return of( JSON.parse( sessionStorage.getItem( 'Drivers' ) || '[]' )).pipe(
         map( list => list // remove expired drivers
            .map( d => ({ ...d, ex: new Date( d.ex ) }) )
            .filter(( driver: Driver ) => driver.ex > new Date() )
         ),
         tap( drivers => sessionStorage.setItem( 'Drivers', JSON.stringify( drivers ))),
         map( list => list.map( d => d.name )),
      );
   }
}
