import { FormGroup, FormArray, FormControl } from '@angular/forms';

export function ValidateFormFields( formGroup: FormGroup, emit: boolean = false ): void {
   if ( !formGroup ) { return; }

   Object.keys( formGroup.controls ).forEach( field => {
      const control = formGroup.get( field );

      if ( control instanceof FormControl ) {
         control.markAsTouched({ onlySelf: true });
         control.updateValueAndValidity({ emitEvent: emit });
         if ( control.errors ) { console.log( field, control.errors ); }
      } else if ( control instanceof FormGroup ) {
         ValidateFormFields( control );
      } else if ( control instanceof FormArray ) {
         control.controls.map(( c: FormGroup ) => ValidateFormFields( c ));
      }
   });

   formGroup.markAsTouched({ onlySelf: true });
   formGroup.updateValueAndValidity();
}
