/* tslint:disable no-unused-expression */
import {
   Component,
   OnInit,
   AfterViewInit,
   AfterContentInit,
   OnDestroy,
   ViewChild,
   ElementRef,
   TemplateRef,
   EventEmitter,
   ContentChild,
} from '@angular/core';
import { moveItemInArray } from '@angular/cdk/drag-drop';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ResizeSensor } from 'css-element-queries';

import { equal } from '@library/common/functions';
import { BreakpointService, AppContainerService } from '@library/core/services';

import { slideDown } from '@library/common/animations';

import {
   Filter,
   FilterBy,
} from './sidebar';

import { SidebarService } from './sidebar.service';

@Component({
   selector: 'lib-sidebar',
   templateUrl: './sidebar.component.html',
   styleUrls: ['./sidebar.component.scss'],
   animations: [ slideDown ],
   inputs: [ 'template', 'title', 'expandButton' ],
   outputs: [ 'closed', 'changed' ],
})
export class SidebarComponent implements OnInit, AfterViewInit, OnDestroy, AfterContentInit {

   template: TemplateRef<any>;
   title = 'Filters';
   expandButton = 'filter_list';

   closed: EventEmitter<void> = new EventEmitter();
   changed: EventEmitter<void> = new EventEmitter();

   @ContentChild( 'header' ) header!: any;

   equal = equal;

   editing: string;
   expanded = false;

   @ViewChild( 'backdrop' ) backdrop!: ElementRef;
   stickyTop = 0;

   _onDestroy: Subject<void> = new Subject();

   constructor(
      public sidebar: SidebarService,
      public breakpoints: BreakpointService,
      public container: AppContainerService,
   ) { }

   ngOnInit() {
      this.sidebar.close$.pipe( takeUntil( this._onDestroy )).subscribe( _ => this.closed.emit() );
      this.sidebar.refresh$.pipe( takeUntil( this._onDestroy )).subscribe( _ => this.changed.emit() );
   }

   ngAfterViewInit(): void {
      if ( this.backdrop ) {
         new ResizeSensor( this.backdrop.nativeElement, () =>
            this.stickyTop = this.backdrop.nativeElement.offsetHeight );
      }
   }

   ngAfterContentInit() {
      console.log( this.header );
   }

   ngOnDestroy() {
      this._onDestroy.next();
      this._onDestroy.complete();
      this.sidebar.reset();
   }

   toggle( filterBy: FilterBy, filter: Filter<any> ) {
      if ( filter.selectable ) {
         filterBy.selected = !filterBy.selected;
      } else if ( filter.order > -1 && filter.active && equal( filter.active.code, filterBy.code )) {
         filter.active = null;
      } else {
         filter.active = filterBy;
      }

      this.sidebar.save();
   }

   move( index: number, newIndex: number ) {
      if ( this.sidebar.filters[newIndex]?.hard ) { return; }
      if ( this.sidebar.filters.length - 1 < newIndex || newIndex < 0 ) { return; }

      moveItemInArray( this.sidebar.filters, index, newIndex );
      this.sidebar.save();
   }
}
