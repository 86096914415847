import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// HTTP
import { HttpClientModule } from '@angular/common/http';
import { HttpInteceptorModule } from './http';

// Library Stuff
import { SvgIconsModule, MaterialsModule } from '@library/common';
import { PipesModule } from '@library/core/pipes';
import { AuthModule } from '@library/modules/auth';
import { UserAccessGuard } from '@library/modules/user';

// App Specific
import { AppMenuModule } from './components/menu';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Signature Pad
import { Angular2SignaturepadModule } from 'angular2-signaturepad';
import { SignaturePadDialogComponent, SignaturePadComponent } from './components/signature-pad/signature-pad.component';

@NgModule({
   declarations: [
      AppComponent,

      SignaturePadDialogComponent,
      SignaturePadComponent,
   ],
   imports: [
      BrowserModule,
      BrowserAnimationsModule,
      FormsModule, ReactiveFormsModule,

      // HTTP
      HttpClientModule,
      HttpInteceptorModule,

      // Library
      AuthModule,
      PipesModule,
      MaterialsModule,
      SvgIconsModule,

      // App
      AppRoutingModule,
      AppMenuModule,

      Angular2SignaturepadModule,
   ],
   providers: [
      UserAccessGuard,
   ],
   bootstrap: [ AppComponent ]
})
export class AppModule { }
