import { MilestonesConst } from '../shipment/milestones';

import * as faker from 'faker';

import { Status } from '../base';

export class MockStatus extends Status {
   constructor() {
      super({
         code: faker.random.arrayElement( MilestonesConst() ).code,
         status: faker.random.arrayElement( MilestonesConst() ).label,
         date: faker.date.past(),
      });
   }
}
