import { FormGroup, FormControl, Validators } from '@angular/forms';

export interface CartageCharge {
   type: string;
   amount: number;
   currency: string;
}

export class CartageCharge implements CartageCharge {
   constructor( c?: CartageCharge ) {
      this.type = c?.type;
      this.amount = c?.amount;
      this.currency = c?.currency;
   }
}

export function CartageChargeGroup( charge: CartageCharge = new CartageCharge() ): FormGroup {
   return new FormGroup({
      type: new FormControl( charge.type || '', [ Validators.required ]),
      amount: new FormControl( charge.amount || '', [ Validators.required, Validators.min( 0 )]),
      currency: new FormControl( charge.currency || 'USD', [ Validators.required ]),
   });
}
