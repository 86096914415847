import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { trigger, transition, style, animate } from '@angular/animations';
import { MatButton } from '@angular/material/button';

import { Observable, of, Subject } from 'rxjs';
import { map, switchMap, filter } from 'rxjs/operators';

import { BreadcrumbService } from '@library/core/services';
import { UseraccessService } from '@library/modules/user';

import { NavMenuService } from '../nav-menu.service';

import { TrackItem } from '../tracking';

@Component({
   selector: 'lib-nav-menu',
   templateUrl: './nav-menu.component.html',
   styleUrls: ['./nav-menu.component.scss'],
   animations: [
      trigger( 'slideIn', [
         transition( ':enter', [
            style({ width: 0, 'margin-left': 0 }),
            animate( '300ms ease-in-out', style({ width: '*', 'margin-left': '*' }))
         ]),
         transition( ':leave', [
            animate( '300ms ease-in-out', style({ width: 0, 'margin-left': 0 }))
         ]),
      ]),
   ],
})
export class NavMenuComponent implements OnInit, AfterViewInit, OnDestroy {
   tracking = false;

   @ViewChild('nav_container') nav_container: ElementRef;
   @ViewChild('hidden_button') hidden_button: MatButton;
   hiddenText = '';

   get navContainerWidth() { return this.nav_container?.nativeElement.offsetWidth || 0; }

   _onDestroy: Subject<void> = new Subject();

   show$: Observable<'one' | 'two' | 'full'> =
      this.nav.below$.pipe(
         switchMap( below => below ? of( this.navContainerWidth ) : this.nav.container ),
         switchMap( _ => this.nav.navWidths, ( container, widths ) => {
            if ( container > widths.full ) { return 'full'; }
            if ( container > widths.two ) { return 'two'; }

            return 'one';
         }),
      );

   constructor(
      public nav: NavMenuService,
      public router: Router,
      public access: UseraccessService,
      public breadcrumbs: BreadcrumbService,
      public changes: ChangeDetectorRef,
   ) { }

   ngOnInit() { }

   ngAfterViewInit() {
      this.nav.items$.pipe(
         filter( items => !!items ),
         map( items => {
            const active = items.filter( item => this.router.isActive( item.path, false ))[0];
            const all = [( this.nav.showTracking ? TrackItem : null ), ...items ].filter( _ => _ ).map( m => m.label );

            // get full width
            const full = all.map( label => this.getButtonWidth( label )).reduce(( a, c ) => a + c, 80 );

            // get width with two buttons
            const two = [ all[0], active?.label, ...Array.from({ length: all.length - 2 }, _ => '' )]
               .map( label => this.getButtonWidth( label )).reduce(( a, c ) => a + c, 80 );

            // get width with just active button
            const one = [ active?.label, ...Array.from({ length: all.length - 1 }, _ => '' )]
               .map( label => this.getButtonWidth( label )).reduce(( a, c ) => a + c, 80 );

            return { one, two, full };
         }),
      ).subscribe( widths => this.nav.navWidths.next( widths ));

      this.nav.items$.subscribe( items => this.nav.load( items ));
   }

   ngOnDestroy() {
      this._onDestroy.next();
      this._onDestroy.complete();
   }

   getButtonWidth( label: string = '' ): number {
      this.hiddenText = label.toUpperCase();
      this.changes.detectChanges();

      return this.hidden_button._elementRef.nativeElement.clientWidth;
   }
}
