import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { MaterialsModule } from '@library/common/materials';
import { DirectivesModule } from '@library/core/directives';
import { PipesModule } from '@library/core/pipes';
import { BarcodeScannerComponent } from './barcode-scanner.component';

import { ZXingScannerModule } from '@zxing/ngx-scanner';

@NgModule({
   declarations: [ BarcodeScannerComponent ],
   imports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule,

      MaterialsModule,

      DirectivesModule,

      PipesModule,

      ZXingScannerModule,
   ],
   exports: [ BarcodeScannerComponent ],
   entryComponents: [ BarcodeScannerComponent ],
})
export class BarcodeScannerModule { }
