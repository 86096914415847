import * as faker from 'faker';

import { dateTimeFormat } from '@library/common/models';

import { CartageMilestonesMap } from '../milestones';
import { CartageItemTypes } from '../cartage-item-types';
import { CartageStatus } from '../cartage-status';

import moment from 'moment';

export class MockCartageStatus implements CartageStatus {
   constructor( type?: CartageItemTypes) {
      const randomMilestone = getRandomMilestone( type );

      this.code = randomMilestone.code;
      this.status = randomMilestone.label;
   }

   code: string;
   status: string;
   dateTime: string = moment( faker.date.past() ).format( dateTimeFormat );
}

function getRandomMilestone( type?: CartageItemTypes ) {
   const itemType = type ? type : faker.random.arrayElement( CartageItemTypes );

   return faker.random.arrayElement( CartageMilestonesMap( itemType ));
}
