import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MaterialsModule } from '@library/common/materials';
import { PipesModule } from '@library/core/pipes';

import { UseravatarModule } from './user-avatar/useravatar.module';

import { UserRoutingModule } from './user-routing.module';

import { AccountComponent } from './account/account.component';
import { PasswordComponent } from './password/password.component';
import { UserComponent } from './user/user.component';

@NgModule({
   declarations: [
      AccountComponent,
      PasswordComponent,
      UserComponent,
   ],
   imports: [
      CommonModule,
      FormsModule, ReactiveFormsModule,
      MaterialsModule,
      PipesModule,
      UseravatarModule,
      UserRoutingModule,
   ],
   exports: [
      AccountComponent,
      UserComponent,
   ],
})
export class UserModule { }
