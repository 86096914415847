<span *ngIf="tracking" class="track-container">
   <mat-form-field floatLabel="never">
      <span matPrefix style="margin-right: 2ex;">
         <mat-icon svgIcon="track"></mat-icon>
      </span>
      <input matInput #trackInput placeholder="Enter a HAWB" [(ngModel)]="track_value" (keyup.enter)="track()" (keyup.esc)="tracking = false" />
      <button mat-button matSuffix (click)="track()">Go</button>
   </mat-form-field>

   <button mat-icon-button (click)="tracking = false" style="margin-left: 1em;">
      <mat-icon>chevron_left</mat-icon>
   </button>
</span>

<ng-container *ngIf="show && !tracking">
   <ng-container *ngIf="trackItem as item">
      <button mat-button class="nav-item" (click)="tracking = true;" [matTooltip]="!showLabel ? item.label : ''">
         <mat-icon [svgIcon]="item.icon"></mat-icon>
         <span *ngIf="showLabel">
            {{item.label | uppercase}}
         </span>
      </button>
   </ng-container>
</ng-container>
