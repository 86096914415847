import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';

@Injectable({
   providedIn: 'root'
})
export class NotificationsService {

   constructor() { }

   notifications(): Observable<any> {
      return of( );
   }
}
