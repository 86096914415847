import { NgModule } from '@angular/core';

import { ConvertUomPipe } from './convert.pipe';
import { CurrencyCodePipe } from './currencycode.pipe';
import { EllipsisPipe } from './ellipsis.pipe';
import { FilesizePipe } from './filesize.pipe';
import { FilterListPipe } from './filterlist.pipe';
import { HighlightPipe } from './highlight.pipe';
import { PhonePipe } from './phone.pipe';
import { PluralPipe } from './plural.pipe';
import { SpacersPipe } from './spacers.pipe';
import { ReadablePipe } from './readable.pipe';
import { TimePipe } from './time.pipe';
import { ToFixedPipe } from './tofixed.pipe';
import { TotalPipe } from './total.pipe';
import { ConvertWeightPipe, DualWeightsPipe, TotalWeightPipe } from './weights.pipe';
import { ConvertVolumePipe, DualVolumePipe, TotalVolumePipe } from './volume.pipe';

@NgModule({
   declarations: [
      ConvertUomPipe,
      ConvertVolumePipe,
      ConvertWeightPipe,
      CurrencyCodePipe,
      DualVolumePipe,
      DualWeightsPipe,
      EllipsisPipe,
      FilesizePipe,
      FilterListPipe,
      HighlightPipe,
      PhonePipe,
      PluralPipe,
      ReadablePipe,
      SpacersPipe,
      TimePipe,
      ToFixedPipe,
      TotalPipe,
      TotalVolumePipe,
      TotalWeightPipe,
   ],
   exports: [
      ConvertUomPipe,
      ConvertVolumePipe,
      ConvertWeightPipe,
      CurrencyCodePipe,
      DualVolumePipe,
      DualWeightsPipe,
      EllipsisPipe,
      FilesizePipe,
      FilterListPipe,
      HighlightPipe,
      PhonePipe,
      PluralPipe,
      ReadablePipe,
      SpacersPipe,
      TimePipe,
      ToFixedPipe,
      TotalPipe,
      TotalVolumePipe,
      TotalWeightPipe,
   ]
})
export class PipesModule { }
