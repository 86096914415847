import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialsModule } from '@library/common/materials';

import { AvatarModule } from 'ngx-avatar';

import { UserAvatarComponent } from './user-avatar.component';

@NgModule({
   declarations: [
      UserAvatarComponent,
   ],
   imports: [
      CommonModule,
      AvatarModule,
      MaterialsModule,
   ],
   exports: [
      UserAvatarComponent,
   ]
})
export class UseravatarModule { }
