<span #nav_container class="nav-container" [class.below]="nav.below$ | async">
   <ng-container *ngIf="nav.items$ | async as items">
      <span [class.spanner]="(nav.below$ | async) ? false : true"></span>

      <span class="items-wrapper">
         <lib-tracking *ngIf="access.hasAccess( 'tracking' ) | async" [show]="nav.showTracking" [showLabel]="(show$ | async) === 'two' || (show$ | async) === 'full'" (active)="tracking = $event"></lib-tracking>

         <ng-container *ngIf="!tracking">
            <ng-container *ngFor="let item of items">
               <button mat-button class="nav-item" [class.active]="(show$ | async) === 'full' && rla.isActive" [routerLink]="item.path" routerLinkActive #rla="routerLinkActive" [matTooltip]="rla.isActive ? '' : item.label">
                  <mat-icon [svgIcon]="item.icon"></mat-icon>
                  <span class="slideable" *ngIf="(show$ | async) === 'full' || rla.isActive">{{item.label | uppercase}}</span>
               </button>
            </ng-container>
         </ng-container>
      </span>
   </ng-container>
</span>

<span class="nav-container" style="visibility: hidden; position: absolute; top: -100%;">
   <span class="items-wrapper">
      <button mat-button #hidden_button class="nav-item">
         <mat-icon>info</mat-icon>
         <span *ngIf="hiddenText">{{ hiddenText }}</span>
      </button>
   </span>
</span>
