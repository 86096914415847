export const BillingParties = [ 'shipper', 'consignee', 'bill_to', ] as const;
export type BillingParties = typeof BillingParties[number];

export interface BillingPartyInterface {
   id: number;

   name: string;
   address1: string;
   address2?: string;
   address3?: string;
   city: string;
   state?: string;
   postalcode?: string;
   country: string;

   phone?: string;
   fax?: string;
   email?: string;

   contact?: {
      name: string;
      phone: string;
   };
}

export interface BillingParty extends BillingPartyInterface {
   toString: () => string;
}

export class BillingParty implements BillingParty {
   constructor( b?: BillingParty ) {
      this.id = b?.id;

      this.name = b?.name || '';
      this.address1 = b?.address1 || '';

      if ( b?.address2 ) { this.address2 = b.address2; }
      if ( b?.address3 ) { this.address3 = b.address3; }

      this.city = b?.city || '';
      if ( b?.state ) { this.state = b.state; }
      if ( b?.postalcode ) { this.postalcode = b.postalcode; }
      this.country = b?.country || 'US';

      if ( b?.phone ) { this.phone = b.phone; }
      if ( b?.fax ) { this.fax = b.fax; }
      if ( b?.email ) { this.email = b.email; }

      if ( b?.contact ) { this.contact = b.contact; }
   }

   public toString = () => {
      return [
         this.name,
         this.address1,
         ( this.address2 ) ? this.address2 : '',
         ( this.address3 ) ? this.address3 : '',
         this.city,
         ( this.state ) ? this.state : '',
         ( this.postalcode ) ? this.postalcode : '',
         this.country,
         ( this.phone ) ? this.phone : '',
         ( this.fax ) ? this.fax : '',
         ( this.email ) ? this.email : '',
         ( this.contact ) ? this.contact.name + '◬' + this.contact.phone : '',
      ].join('◬').toLowerCase();
   }
}

import { FormGroup, FormControl } from '@angular/forms';
export function BillingPartyGroup( business: BillingParty = new BillingParty() ) {
   return new FormGroup({
      id: new FormControl( business?.id ),
      name: new FormControl( business?.name || '' ),
      address1: new FormControl( business?.address1 || '' ),
      address2: new FormControl( business?.address2 || '' ),
      address3: new FormControl( business?.address3 || '' ),
      city: new FormControl( business?.city || '' ),
      state: new FormControl( business?.state || '' ),
      postalcode: new FormControl( business?.postalcode || '' ),
      country: new FormControl(( business?.country || '' ).slice( 0, 2 )),
      phone: new FormControl( business?.phone || '' ),
      fax: new FormControl( business?.fax || '' ),
      contact: new FormGroup({
         name: new FormControl( business?.contact?.name ?? '' ),
         phone: new FormControl( business?.contact?.phone ?? '' )
      })
   });
}
