export interface AirMilestones {
   booked: string;
   confirmed: string;
   cancelled: string;
   pickedup: string;
   tendered: string;
   departedair: string;
   arrivedair: string;
   delivery: string;
   podd: string;
   pod: string;
}

export class AirMilestones {
   constructor( current?: AirMilestones ) {
      this.booked       = current && current['booked'] || '';
      this.confirmed    = current && current['confirmed'] || '';
      this.cancelled    = current && current['cancelled'] || '';
      this.pickedup     = current && current['pickedup'] || '';
      this.tendered     = current && current['tendered'] || '';
      this.departedair  = current && current['departedair'] || '';
      this.arrivedair   = current && current['arrivedair'] || '';
      this.delivery     = current && current['delivery'] || '';
      this.podd         = current && current['podd'] || '';
      this.pod          = current && current['pod'] || '';
   }
}
