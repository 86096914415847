<button mat-icon-button [matMenuTriggerFor]="settingsMenu" matTooltip="Settings">
   <mat-icon>settings</mat-icon>
</button>

<mat-menu #settingsMenu="matMenu">
   <ng-container *ngIf="( accountService.length | async ) <= 1 else multiple">
      <button mat-menu-item (click)="sp.setStartPageAll()">
         <mat-icon>web</mat-icon>
         Set as Start Page
      </button>
   </ng-container>

   <ng-template #multiple>
      <button mat-menu-item [matMenuTriggerFor]="subMenu">
         <mat-icon>web</mat-icon>
         Set as Start Page
      </button>

      <mat-menu #subMenu="matMenu">
         <button mat-menu-item (click)="sp.setStartPageCurrent()">
            For {{( accountService.active | async )?.name }}
         </button>
         <button mat-menu-item (click)="sp.setStartPageAll()">
            For Your User
         </button>
      </mat-menu>
   </ng-template>

   <button mat-menu-item *ngIf="bs.large" (click)="ks.showKeyboardShortcuts();">
      <mat-icon>keyboard</mat-icon>
      Keyboard Shortcuts
   </button>
</mat-menu>
