import { NgModule } from '@angular/core';

import { httpInterceptor2 } from './http-interceptor-2';
import { cacheHttpInterceptor } from './http-interceptor-cache';
import { messagesHttpInterceptor } from './http-interceptor-messages';
import { mockHttpInterceptor } from './http-interceptor-mock';
import { analyticsHttpInterceptor } from './http-interceptor-analytics';

@NgModule({
   providers: [
      cacheHttpInterceptor,
      messagesHttpInterceptor,
      mockHttpInterceptor,
      analyticsHttpInterceptor,
      httpInterceptor2,
   ],
})
export class HttpInteceptorModuleBase { }
