<div #signature_content class="signature-capture" style="height: 100%; width: 100%;">
   <div #signature_content class="pad-wrapper">
      <signature-pad class="pad" [options]="options"></signature-pad>
   </div>

   <div class="signature-overlay">
      <div class="action-bar">
         <button mat-mini-fab color="primary" (click)="dialogRef.close()">
            <mat-icon>clear</mat-icon>
         </button>

         <div style="user-select: none; align-self: center;">
            <span>Sign Here</span>
         </div>

         <button mat-mini-fab color="primary" (click)="save();" [disabled]="!signaturePad || signaturePad.isEmpty()">
            <mat-icon>done</mat-icon>
         </button>
      </div>
   </div>
</div>
