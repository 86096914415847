import { Component } from '@angular/core';

import { BreakpointService } from '@library/core/services';

import { HttpPaginationService } from './http-pagination.service';

@Component({
   selector: 'lib-http-pagination',
   inputs: [ 'diameter' ],
   templateUrl: './http-pagination.component.html',
   styleUrls: ['./http-pagination.component.scss']
})
export class HttpPaginationComponent {

   diameter = 70;

   constructor(
      public pagination: HttpPaginationService,
      public breakpoints: BreakpointService,
   ) { }
}
