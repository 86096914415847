import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { BreadcrumbService } from '@library/core/services';
import { StartpageService } from '../startpage.service';
import { AccountService } from '../account';
import { UserService } from './user.service';

import {
   UserLengths,
   UserModification,
   UserModificationGroup,
} from './user-modification';

@Component({
  selector: 'lib-user.account',
  templateUrl: './user.component.html',
  styleUrls: [ './user.component.scss' ],
})
export class UserComponent implements OnInit {

   userForm: FormGroup;
   lengths = UserLengths;

   saving = false;

   constructor(
      public userService: UserService,
      public accountService: AccountService,
      public startpage: StartpageService,
      private breadcrumbs: BreadcrumbService,
   ) { }

   ngOnInit() {
      this.breadcrumbs.newCrumb({ label: 'User', path: '/user' });

      this.userService.user.subscribe( user =>
         this.userForm = UserModificationGroup( user ));
   }

   save() {
      const modify = new UserModification( this.userForm.value );
      this.saving = true;

      // save the user info
      this.userService.update( modify ).subscribe( _ => this.saving = false );
   }
}
