import { AbstractControl, ValidatorFn } from '@angular/forms';

export function RegExArrayValidator( array: { title: string, regex: string }[] ): ValidatorFn {
   return ( control: AbstractControl ): { [key: string]: any } => {
      const response = [];
      array.forEach( expression => {
         if ( !control.value ) {
            response[ expression.title ] = {};
         } else if ( !new RegExp( expression.regex ).test( control.value )) {
            response[ expression.title ] = { value: control.value };
         }
      });

      return response;
   };
}
