import * as faker from 'faker';
import { optional } from '@library/common/functions';
import { dateTimeFormat } from '@library/common/models';

import moment from 'moment';

import { MockBillingParty } from '@library/common/models/mock';

import { MockLeg } from './cartage-leg.mock';

import { CartageItemTypes } from '../cartage-item-types';
import { CartageBase } from '../cartage-base';

export class MockCartageBase extends CartageBase {
   constructor( type?: CartageItemTypes ) {
      super();

      this.type = type || faker.random.arrayElement( CartageItemTypes );
      this.job = faker.datatype.number( 9999999999 );

      if ( optional() ) { this.proNumber = faker.random.alphaNumeric( 12 ); }

      if ( this.type === 'pickup' ) {
         this.successorLeg = new MockLeg();
      } else if ( this.type === 'delivery' ) {
         this.predecessorLeg = new MockLeg();
      }

      this.pickupLocation = new MockBillingParty();
      this.pickupDate = faker.date.past();
      this.pickupTime = moment( faker.date.past() ).format( 'HH:mm:ss' );

      this.deliveryLocation = new MockBillingParty();
      this.deliveryDate = faker.date.future();
      this.deliveryTime = moment( faker.date.future() ).format( 'HH:mm:ss' );

      if ( optional() ) { this.serviceLevel = faker.commerce.productName(); }

      if ( optional( 40 ) ) { this.driver = faker.name.findName(); }

      if ( optional() ) { this.dangerousGoods = faker.datatype.boolean(); }

      if ( optional()) { this.origin = faker.address.state().slice( 0, 3 ).toUpperCase(); }
      if ( optional()) { this.destination = faker.address.state().slice( 0, 3 ).toUpperCase(); }

      // this.blockedActions = [ ...new Set(
      //    Array.from({
      //       length: faker.datatype.number( CartageBlockedActionTypes.length - 1 )
      //    }, _ => faker.random.arrayElement( CartageBlockedActionTypes ))
      // )];

      this.statuses = Array.from({ length: faker.datatype.number({ min: 1, max: 10 })}, _ => ({
         code: faker.lorem.word(),
         status: faker.company.catchPhrase(),
         dateTime: optional() ? undefined : moment(faker.date.past()).format( dateTimeFormat )
      }));
   }
}
