/* tslint:disable no-unused-expression */
import { Component, AfterViewInit, ViewChild, ElementRef } from '@angular/core';

import { ResizeSensor } from 'css-element-queries';

import { BreakpointService, AppContainerService } from '@library/core/services';

import { NavMenuService } from '../nav-menu.service';

@Component({
   selector: 'lib-menu-bar',
   templateUrl: './menu-bar.component.html',
   styleUrls: ['./menu-bar.component.scss']
})
export class MenuBarComponent implements AfterViewInit {
   @ViewChild('navContainer') navContainer!: ElementRef;
   @ViewChild('menuContainer') menuContainer!: ElementRef;

   get hamburger() { return this.breakpoints.xsmall; }

   constructor(
      public containerService: AppContainerService,
      public navMenu: NavMenuService,
      public breakpoints: BreakpointService,
   ) {}

   ngAfterViewInit() {
      new ResizeSensor( this.navContainer.nativeElement, () =>
         this.navMenu.container.next( this.navContainer.nativeElement.clientWidth ));

      new ResizeSensor( this.menuContainer.nativeElement, () =>
         this.containerService.menuHeight = this.menuContainer.nativeElement.offsetHeight );
   }
}
