import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'ellipsis' })
export class EllipsisPipe implements PipeTransform {
   transform( value: string, length: number ): string {
      if ( !length || value.length <= length ) {
         return value;
      }

      return value.slice( 0, length ) + '...';
   }
}
