import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
   name: 'highlight'
})
export class HighlightPipe implements PipeTransform {

   constructor( private santizier: DomSanitizer ) { }

   transform( value: string, args: string ): any {
      if ( args && value ) {
         const startIndex = value.toLowerCase().indexOf( args.toLowerCase() );

         if ( startIndex !== -1 ) {
            const matchingString = value.substr(startIndex, args.length);
            const preSpan = '<prespan>' + value.replace( matchingString, '<mark>' + matchingString + '</mark>' ) + '</prespan>';

            return this.santizier.bypassSecurityTrustHtml( preSpan );
         }
      }

      return value;
   }
}
