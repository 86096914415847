import { Injectable } from '@angular/core';

import { Observable, of, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserService } from './user';

import { AllServices, AccessRoles } from './useraccess';

@Injectable({
   providedIn: 'root'
})
export class UseraccessService {
   constructor( private userService: UserService ) { }

   hasService( service: string | string[] ): Observable<boolean> {
      if ( service instanceof Array ) {
         return forkJoin( service.map( s => this.hasService( s )))
            .pipe( map( s => s.filter( _ => _ ).length > 0 ));
      }

      return this.userService.user.pipe(
         map( user => user.services ),
         map( services => services.indexOf( service ) !== -1 ),
      );
   }

   hasAccess( mod: string | string[] ): Observable<boolean> {
      if ( !mod || !mod.length ) { return of( true ); }

      if ( mod instanceof Array ) {
         return forkJoin( mod.map( m => this.hasAccess( m )))
            .pipe( map( m => m.filter( _ => _ ).length > 0 ));
      }

      return this.userService.user.pipe( map( user => user.services )).pipe(
         map( services => {
            // fallback for old format of services
            if ( !( services instanceof Array )) { return false; }

            // checking if accessing a specific service
            if ( AllServices.includes( mod )) { return services.includes( mod ); }

            // no specific service - check access roles
            // fallback for unset access roles (closed)
            if ( !AccessRoles[ mod ] ) { return false; }

            return AccessRoles[ mod ].filter( role => services.includes( role )).length > 0;
         }),
      );
   }
}
