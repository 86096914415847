import { Contact, ContactGroup } from './contact';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';

export interface Business {
   id: number;

   name: string;
   address1: string;
   address2?: string;
   address3?: string;
   city: string;
   state?: string;
   postalcode: string;
   country: string ;

   phone?: string;
   fax?: string;
   email?: string;

   contacts?: Contact[];
}


export class Business implements Business {
   constructor( b: any = {} ) {
      this.id = b?.id;

      this.name = b?.name || '';
      this.address1 = b?.address1 || '';

      if ( b?.address2 ) { this.address2 = b?.address2; }
      if ( b?.address3 ) { this.address3 = b?.address3; }

      this.city = b?.city || '';
      this.state = b.state || '';
      this.postalcode = b?.postalcode || '';
      this.country = b?.country || 'US';

      if ( b?.phone ) { this.phone = b.phone; }
      if ( b?.fax ) { this.fax = b.fax; }
      if ( b?.email ) { this.email = b.email; }

      this.contacts = [];
      if ( b?.contact && b?.contact?.name && b?.contact?.phone ) {
         this.contacts.push( new Contact( b['contact'] ));
      }

      if ( b?.contacts ) {
         b.contacts.map( c => this.contacts.push( new Contact( c ) ));
      }
   }

   public toString = (): string => {
      return [
         this.name,
         this.address1,
         this.address2,
         this.address3,
         this.city,
         this.state,
         this.postalcode,
         this.country,
         this.phone,
         this.fax,
         this.email,
         (this.contacts) ? this.contacts.map( c => c.toString()) : '',
      ].join('◬');
   }

   public save(): Business {
      return {
         id: this.id,
         name: this.name,
         address1: this.address1,
         address2: this.address2,
         address3: this.address3,
         city: this.city,
         state: this.state,
         postalcode: this.postalcode,
         country: this.country,
         phone: this.phone,
         fax: this.fax,
         contacts: this.contacts
      } as Business;
   }
}

export const BusinessLengths = {
   name: 100,
   address1: 100,
   address2: 100,
   address3: 100,
   city: 60,
   state: 60,
   postalcode: 20,
   country: 2,
   phone: 25,
   fax: 25,
};

export function BusinessGroup( business: Business = new Business() ) {
   const fg = new FormGroup({
      id: new FormControl( business.id ),
      name: new FormControl( business.name ?? '', [ Validators.maxLength( BusinessLengths.name ), Validators.required ] ),
      address1: new FormControl( business.address1 ?? '', [ Validators.required, Validators.maxLength( BusinessLengths.address1 ) ]),
      address2: new FormControl( business.address2 ?? '', Validators.maxLength( BusinessLengths.address2 )),
      address3: new FormControl( business.address3 ?? '', Validators.maxLength( BusinessLengths.address3 )),
      city: new FormControl( business.city ?? '', [ Validators.required, Validators.maxLength( BusinessLengths.city ) ]),
      state: new FormControl( business.state ?? '', [ Validators.maxLength( BusinessLengths.state ) ]),
      postalcode: new FormControl( business.postalcode ?? '' ),
      country: new FormControl( business.country.slice( 0, 2 ), [ Validators.required, Validators.maxLength( BusinessLengths.country ) ]),
      phone: new FormControl( business.phone ?? '', Validators.maxLength( BusinessLengths.phone )),
      fax: new FormControl( business.fax ?? '', Validators.maxLength( BusinessLengths.fax )),
      contacts: new FormArray([]),
   });

   if ( business.contacts ) {
      const contacts = fg.get('contacts') as FormArray;
      business.contacts.map( c => contacts.push( ContactGroup( c )));
   }

   return fg;
}
