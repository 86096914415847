import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component( {
   selector: 'lib-confirm-dialog',
   template: `
      <h2 mat-dialog-title>{{title}}</h2>
      <mat-dialog-content>{{message}}</mat-dialog-content>
      <mat-dialog-actions>
         <button mat-raised-button mat-dialog-close>Ok</button>
      </mat-dialog-actions>`
})
export class AlertDialogComponent {
   title: string;
   message: string;

   constructor(
      public dialogRef: MatDialogRef<AlertDialogComponent>,
      @Inject( MAT_DIALOG_DATA ) public data: any
   ) {
      this.title = (data.title) ? data.title : 'Alert';
      this.message = (data.message) ? data.message : 'Are you sure you wish to perform this action?';
   }
}
