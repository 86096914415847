import { Injectable } from '@angular/core';

import { ResponseWrapper, Pagination } from '@library/common/http';

import { randomArray, optional, titleCase } from '@library/common/functions';

import { MockAttachment, ShipmentFactory } from '@library/common/models/mock';
import { MockUser } from '@library/modules/user/mock';

import { MockCartageDetail } from '../components/cartage/models/mock/cartage-detail.mock';
import { MockCartageListItem } from '../components/cartage/models/mock/cartage-list-item.mock';
import { MockBulkQueue } from '../components/cartage/bulk-queue/bulk-queue.mock';

import * as faker from 'faker';

const mockUser = new MockUser();

@Injectable({
   providedIn: 'root'
})
export class MockService {

   constructor() { }

   mock( url: string, body? ): any {
      return JSON.parse( JSON.stringify( this.wrap( url.match( /((?:\/?[a-zA-Z_]+)+)(\/[\w-]+)?(\?.*)?$/ ), body )));
   }

   pagination( pagination?: any ): Pagination {
      return {
         hash: pagination?.hash || 'mock-hash',
         page: pagination?.page || 1,
         total: pagination?.total || Math.ceil( Math.random() * 10 )
      };
   }

   wrap( parsedURL, body? ) {
      console.log( '* mock', parsedURL );
      if ( body ) { console.log( '* mock - passed body', body ); }

      const [ , url, id, queryParams ] = parsedURL;

      if ( id ) { console.log( '* mock - found id', id ); }

      if ( queryParams ) { console.log( '* mock - queryParams', queryParams ); }

      const wrapper = {
         messages: [],
         type: 'mock',
         response: { collection: [] }
      } as ResponseWrapper<any>;

      switch ( url ) {
         case 'GETshipment':
            return ({ ...wrapper, response: { collection: [ ShipmentFactory.mock() ] }});

         case 'GETattachment/get':
            const mockAttachment: MockAttachment = new MockAttachment();
            mockAttachment.mimetype = 'image/png';
            mockAttachment.data = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQAAAAEACAIAAADTED8xAAADMElEQVR4nOzVwQnAIBQFQYXff81RUkQCOyDj1YOPnbXWPmeTRef+/3O/OyBjzh3CD95BfqICMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMO0TAAD//2Anhf4QtqobAAAAAElFTkSuQmCC';

            return ({ ...wrapper, response: { collection: [ mockAttachment ]}});

         case 'GETuser':
            return ({ ...wrapper, response: { collection: [ mockUser ] }});
         case 'GETrouting/bulkqueue':
            if ( id ) {
               return ({ ...wrapper, response: { collection: [ new MockBulkQueue() ]}});
            }

            return ({ ...wrapper, response: {
               collection: randomArray( MockBulkQueue ).map( item => ({
                  ...item,
                  user: optional( 70 ) ? item.user : `${mockUser.fname} ${mockUser.lname}`,
               }))
            }});
         case 'POSTrouting/bulkqueue/archive':
            return ({ ...wrapper, response: {
               pagination: this.pagination( body?.pagination ),
               collection: randomArray( MockBulkQueue ).map( item => ({
                  ...item,
                  user: optional( 70 ) ? item.user : `${mockUser.fname} ${mockUser.lname}`,
                  uploaded: faker.date.past(),
               }))
            }});
         case 'POSTrouting/update':
            return wrapper;

         case 'GETrouting':
            if ( id || queryParams ) {
               return ({ ...wrapper, response: { collection: [ new MockCartageDetail() ]}});
            }

            return ({ ...wrapper, response: { collection: randomArray( MockCartageListItem, 100 ) }});
         case 'POSTrouting/list':
            return ({ ...wrapper, response: {
               pagination: this.pagination( body?.pagination ),
               collection: optional( 85 ) ? randomArray( MockCartageListItem, 100 ) : [],
            }});

         // enums
         case 'GETenum/currencies':
            return ({ ...wrapper, response: { collection: [ 'USD', 'CAD' ]}});

         case 'GETenum/status_types/pickup':
            return ({ ...wrapper, response: { collection: [
               {
                  "code": "pConfirmed",
                  "label": "Pickup Alert Confirmed"
              },
              {
                  "code": "pTendered",
                  "label": "Tendered to Carrier"
              },
              {
                  "code": "pPOD",
                  "label": "Proof of Delivery"
              },
            ]}});
         case 'GETenum/status_types/delivery':
            return ({ ...wrapper, response: { collection: [
               {
                  "code": "dOut",
                  "label": "Out for Delivery"
               },
               {
                  "code": "dPOD",
                  "label": "Proof of Delivery"
               },
               {
                  "code": "dArrivedDestination",
                  "label": "Arrival at Carrier Destination Facility"
               },
            ]}});
         case 'GETenum/status_types/linehaul':
            return ({ ...wrapper, response: { collection: [
               {
                  "code": "lPickedUp",
                  "label": "Pick up"
               },
               {
                  "code": "lReWeigh",
                  "label": "Re-Weigh"
               },
               {
                  "code": "larrivedDestination",
                  "label": "Arrival at Destination"
               },
            ]}});

         case 'POSTuser/preferences':
            console.log( '* mock - POSTuser/preferences', body );
      }

      if ( url.includes( 'enum' )) {
         return ({ ...wrapper, response: { collection:
            Array.from({
               length: faker.datatype.number({ min: 1, max: 10 })
            }, _ => titleCase( faker.company.bs() ) as string )
         }});
      }

      return wrapper;
   }
}
