import { Component } from '@angular/core';

import { FormControl } from '@angular/forms';

@Component({
   selector: 'app-note-update',
   inputs: [ 'control' ],
   templateUrl: './note-update.component.html',
   styleUrls: ['./note-update.component.scss', '../cartage-update.scss']
})
export class NoteUpdateComponent {
   control: FormControl;
}
