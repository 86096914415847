export function contains( value: string, toCheck?: string ): boolean {
   if ( !value || !toCheck ) { return true; }

   value = value.trim().toLowerCase();
   let regExp;

   try {
      regExp = new RegExp( toCheck );
   } catch ( e ) {
      regExp = undefined;
   }

   return regExp ? regExp.test( value ) : value.includes( toCheck.trim().toLowerCase() );
}
