import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { RegExArrayValidator } from '@library/core/directives';
import { ErrorMessageService } from '@library/core/services';

import { UserService } from '../user';
import { PasswordRequirement, PasswordService } from './password.service';

function passwordMatchValidator(g: FormGroup) {
   return g.get('passwordTest').value === g.get('confirmTest').value
      ? null : { mismatch: true };
}

@Component({
   selector: 'lib-password-reset',
   templateUrl: './password.component.html',
   styleUrls: ['./password.component.scss']
})
export class PasswordComponent implements OnInit {

   message: string;
   loading = false;

   passwordForm: FormGroup = new FormGroup({
      passwordTest: new FormControl( false ),
      confirmTest: new FormControl( false ),
   });

   validation: PasswordRequirement[];

   constructor(
      public error: ErrorMessageService,
      private passwordService: PasswordService,
      public router: Router,
      public userService: UserService,
   ) { }

   ngOnInit() {
      this.passwordService.getRequirements().subscribe( res => {
         this.validation = res;

         this.passwordForm = this.setPasswordForm();
      });
   }

   get passwordTest() { return this.passwordForm.get('passwordTest'); }
   get confirmTest() { return this.passwordForm.get('confirmTest'); }

   setPasswordForm(): FormGroup {
      return new FormGroup({
         passwordTest: new FormControl( '', [ Validators.required, RegExArrayValidator( this.validation )]),
         confirmTest: new FormControl( '', [ Validators.required ]),
      }, passwordMatchValidator );
   }

   resetPassword() {
      if ( this.passwordForm.invalid ) {
         this.message = 'Minimum password standards are not met';

         return false;
      }

      this.loading = true;
      const modify = { password: this.passwordForm.get('passwordTest').value };
      this.userService.update( modify ).subscribe( () => {
         this.router.navigate([ 'redirect', '/user' ], { skipLocationChange: true });
      });
   }
}
