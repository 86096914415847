export interface PickupMilestones {
   new: string;
   confirmed: string;
   arranged: string;
   pickedup: string;
   tendered: string;
}

export class PickupMilestones implements PickupMilestones {
   constructor( current?: PickupMilestones ) {
      this.new = current?.new || '';
      this.confirmed = current?.confirmed || '';
      this.arranged = current?.arranged || '';
      this.pickedup = current?.pickedup || '';
      this.tendered = current?.tendered || '';
   }
}
