import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ReplaySubject } from 'rxjs';
import { debounceTime, switchMap, filter } from 'rxjs/operators';

@Injectable({
   providedIn: 'root',
})
export class Preferences {
   private url = 'user/preferences';

   private _prefs = {};

   private save: ReplaySubject<void> = new ReplaySubject();
   listen = false;

   get prefs() { return this._prefs; }
   set prefs( value ) {
      this._prefs = value;
      localStorage.setItem( 'prefs', JSON.stringify( value ));
   }

   getPref( component: string ): any {
      return this.prefs[ component ];
   }

   setPref( pref: any ): void {
      this.prefs = { ...this.prefs, ...pref };

      this.save.next();
   }

   upsertPref( component: string, prop: object | string ): void {
      if ( typeof prop === 'string' ) {
         this.setPref({ [component]: prop });
      }
      if ( typeof prop === 'object' ) {
         this.setPref({ [component]: { ...this.prefs[component], ...prop }});
      }
   }

   clearPref( component: string, property?: string ): void {
      if ( this.prefs[component] ) {
         if ( !property ) {
            delete this.prefs[component];
         } else {
            delete this.prefs[component][property];
            if ( !Object.keys( this.prefs[component] ).length ) {
               this.clearPref( component );
            }
         }
         this.prefs = this.prefs;
         this.setPref( this.prefs ); // to trigger backend call
      }
   }

   getPageSize( component: string ): number {
      return this.prefs[component] && this.prefs[component].pageSize;
   }

   setPageSize( component: string, size: number ): void {
      this.setPref({
         [component]: {
            ...this.prefs[component],
            pageSize: size
         }
      });
   }

   clearPageSize( component: string ): void {
      this.clearPref( component, 'pageSize' );
   }

   constructor( private http: HttpClient ) {
      if ( localStorage.getItem( 'prefs' )) {
         const prefs = JSON.parse( localStorage.getItem( 'prefs' ));
         this.prefs = { ...this.prefs, ...prefs };
      } else {
         this.prefs = this._prefs;
      }

      this.save.pipe(
         debounceTime( 1000 ),
         filter( _ => !!this.listen ),
         switchMap( _ => this.http.post( this.url, this.prefs )),
      ).subscribe();
   }

   reset() {
      this.listen = false;
   }
}
