import { Injectable } from '@angular/core';

import { FormControl, FormGroup } from '@angular/forms';

import { contains } from '@library/common/functions';

export const SearchParams = new FormGroup({
   shipType: new FormControl(),
   mode: new FormControl(),
   text: new FormControl(),
});

@Injectable({
   providedIn: 'root',
})
export class SearchService {
   params: FormGroup = SearchParams;

   showSearch = false;

   get text() {
      return this.params.get( 'text' ) as FormControl;
   }
   set text( value: FormControl ) {
      this.params.get( 'text' ).setValue( value );
   }

   toggle( prop: string, value: any ) {
      if ( this.params.get( prop ).value === value ) {
         this.params.get( prop ).reset();
      } else {
         this.params.get( prop ).setValue( value );
      }
   }

   empty(): boolean {
      return !Object.keys( this.params.value )
         .map( key => this.params.value[key] )
         .filter( v => v ).length;
   }

   reset(): void {
      this.params.reset();
   }

   objectToString( object: object ): string {
      if ( !( object instanceof Object )) { return object; }

      return Object.keys( object ).reduce(( current: string, key: string ) => {
         if ( typeof( object[key] ) === 'boolean' ) { return current; }
         if ( current.length ) { current += '◬'; }

         return current + this.objectToString( object[key] );
      }, '' ).toLowerCase();
   }

   textMatches( value: string ): boolean {
      return contains( value, this.text.value );
   }
}
