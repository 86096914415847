import { Customs, customsGroup, } from '../base';

import { ShipmentModes } from './shipment-modes';
import {
   Shipment,
   shipmentForBooking,
   shipmentGroup,
   shipmentCopy,
} from './shipment';

export const TrafficDirections = [ 'import', 'export', 'transit' ] as const;
export type TrafficDirections = typeof TrafficDirections[number];

export const MovementTypes = [ 'door', 'port' ] as const;
export type MovementTypes = typeof MovementTypes[number];

export interface MovementType {
   from: MovementTypes;
   to: MovementTypes;
}

export interface InternationalShipment extends Shipment<'international', ShipmentModes> {
   shipType: 'international';

   movementType: MovementType;

   incoterms?: string;

   customs?: Customs;

   trafficDirection?: TrafficDirections;
}

export class InternationalShipment extends Shipment<'international', ShipmentModes> {
   shipType: 'international' = 'international';

   constructor( s?: InternationalShipment ) {
      super( s );

      this.movementType = s['movementType'];
      if ( s['incoterms'] ) { this.incoterms = s['incoterms']; }
      if ( s['customs'] ) { this.customs = s['customs']; }
      if ( s['trafficDirection'] ) { this.trafficDirection = s['trafficDirection']; }
   }
}

import { FormGroup, FormControl, Validators } from '@angular/forms';
export function internationalShipmentGroup( s: any = {} ): FormGroup {
   const fg = shipmentGroup( s );

   fg.removeControl( 'cod' );

   fg.addControl( 'movementType', new FormGroup({
      from: new FormControl( s['movementType'] && s['movementType']['from'] || MovementTypes[0], Validators.required ),
      to: new FormControl( s['movementType'] && s['movementType']['to'] || MovementTypes[0], Validators.required ),
   }));
   fg.addControl( 'incoterms', new FormControl( s['incoterms'] || '' ));
   fg.addControl( 'customs', customsGroup( s['customs'] || {} ));
   fg.addControl( 'trafficDirection', new FormControl( s['trafficDirection'] ));

   return fg;
}

export function internationalForBooking<S extends InternationalShipment>( s: S ): S {
   const customs = new Customs( s?.customs );

   return ({
      ...shipmentForBooking<S>( s ),
      movementType: s.movementType,
      incoterms: s['incoterms'] ?? undefined,
      customs: Object.keys( customs ).length ? customs : undefined,
   });
}

export function internationalShipmentCopy<S extends InternationalShipment>( s: S, mode: null | 'continue' | 'reverse' = null ): S {
   const movementType = {...s['movementType']};

   switch ( mode ) {
      case 'continue':
         movementType.from = s['movementType'].to;
         movementType.to = 'door';
         break;
      case 'reverse':
         movementType.to = s['movementType'].from;
         movementType.from = s['movementType'].to;
         break;
   }

   return ({
      ...shipmentCopy<S>( s, mode ),
      movementType,
      incoterms: s['incoterms'],
   });
}
