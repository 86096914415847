import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'plural' })
export class PluralPipe implements PipeTransform {
   transform( value: string, amount: number, substitutions?: string[] ): string {
      if ( substitutions ) {
         return substitutions[amount] || substitutions.slice( -1 )[0];
      }

      let replace = 's';
      if ( value.slice( -1 ) === 's' ) { replace = 'es'; }

      return value + ( amount === 1 ? '' : replace );
   }
}
