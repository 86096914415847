
import { CartagePod } from './cartage-pod';
import { CartageDetailItem } from './cartage-detail-item';
import { CartageCharge } from './cartage-charge';
import { CartageBase } from './cartage-base';
import { CartageMilestones } from './milestones';
import { Attachment } from '@library/common/models';

export interface CartageDetail extends CartageBase {
   pickupInstructions?: string;
   deliveryInstructions?: string;

   items: CartageDetailItem[];

   charges: CartageCharge[];

   notes?: string;

   attachments: Attachment[];

   pod?: CartagePod;

   milestones: {};
}

export class CartageDetail extends CartageBase {
   constructor( c?: CartageDetail ) {
      super( c );

      if ( c?.pickupInstructions ) { this.pickupInstructions = c?.pickupInstructions; }
      if ( c?.deliveryInstructions ) { this.deliveryInstructions = c?.deliveryInstructions; }

      this.items = c?.items;

      this.charges = c?.charges;

      if ( c?.notes ) { this.notes = c?.notes; }

      this.attachments = c?.attachments;

      if ( c?.pod ) { this.pod = c?.pod; }

      this.milestones = CartageMilestones( this.type, c?.milestones );
   }
}
