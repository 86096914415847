import { Injectable } from '@angular/core';

interface Environment {
   production: boolean;
   origin: string;
   mock: boolean;
   cybersourceUrl?: string;
   version?: string;
   gtagId?: string;
}

class Environment implements Environment { }

@Injectable({
   providedIn: 'root'
})
export class EnvironmentService extends Environment {
   get environment() {
      return Object.keys( this ).reduce(( a, c ) => ({
         ...a,
         [c]: this[c],
      }), new Environment() );
   }

   set environment( e: Environment ) {
      Object.keys( e ).map( key =>
         this[key] = e[key] );
   }
}
