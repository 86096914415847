import { Pipe, PipeTransform } from '@angular/core';

// Takes a size in bytes and pipes it into an easy to read string
@Pipe({ name: 'filesize' })
export class FilesizePipe implements PipeTransform {
   transform( value: number ): string {
      let count = 0;
      const units: string[] = ['b', 'kb', 'mb', 'gb', 'tb'];

      while ( value / 1024 >= 1 ) {
         count++;
         value /= 1024;
      }

      return value.toFixed(0) + units[count];
   }
}
