import * as faker from 'faker';
import { optional } from '@library/common/functions';

import { MockAccount } from './account.mock';
import { AllServices } from '../useraccess';
import { MockPinnedReport } from '../user/pinned-report';
import { User } from '../user/user';

export class MockUser extends User {
   constructor() {
      super();

      this.username = faker.internet.userName();
      this.fname = optional( 70 ) ? faker.name.firstName() : '';
      this.lname = optional( 70 ) ? faker.name.lastName() : '';
      if ( optional()) { this.avatar = faker.image.avatar(); }
      this.email = faker.internet.email();
      this.title = optional() ? faker.name.jobTitle() : '';
      this.phone = optional() ? faker.phone.phoneNumber() : '';

      this.services = AllServices;
      // this.services = AllServices.filter( s => s.includes( 'booking' ) ? optional() : true );
      // this.services = AllServices.filter( s => optional() );

      this.accounts = Array.from({ length: Math.random() * 20 + 1 }, _ => new MockAccount() );
      if ( this.accounts.length ) {
         this.accounts[0].primary = true;
         this.accounts[0].active = true;
      }
      this.pinned_reports = Array.from({ length: Math.random() * 10 + 1 }, _ => new MockPinnedReport() );
      this.preferences = JSON.parse( localStorage.getItem( 'prefs' ) || '{}' );
   }
}
