/* tslint:disable ban-types */
import { Injectable } from '@angular/core';

import {
   HttpInterceptor,
   HttpRequest,
   HttpHandler,
   HttpEvent,
   HttpResponse,
   HTTP_INTERCEPTORS,
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

import { isAssets } from './httpFunctions';

declare let gtag: Function;

function diffMinutes( d1: Date, d2: Date ): number {
   let diff = ( d2.getTime() - d1.getTime() ) / 1000;
   diff /= 60;

   return Math.abs( Math.round( diff ));
}

@Injectable()
export class AnalyticsHttpInterceptorBase implements HttpInterceptor {
   constructor() {}

   inFlight = new Map<string, Date>();

   intercept(
      request: HttpRequest<any>,
      next: HttpHandler,
   ): Observable<HttpEvent<any>> {
      if ( typeof gtag !== 'function' || isAssets( request.url )) {
         return next.handle( request );
      }

      const key = request.method + request.url;

      this.inFlight.forEach(( v, k ) => {
         if ( diffMinutes( v, new Date() ) > 1 ) {
            setTimeout( _ => this.inFlight.delete( k ));
         }
      });

      if ( !this.inFlight.has( key )) {
         this.inFlight.set( key, new Date() );
      }

      return next.handle( request ).pipe(
         tap( result => {
            if ( result instanceof HttpResponse ) {
               const date = this.inFlight.get( key );
               const now = new Date();
               if ( date ) {
                  gtag( 'event', 'timing_complete', {
                     eventCategory: 'XHR',
                     name: request.url,
                     value: now.getTime() - date.getTime(),
                  });
                  this.inFlight.delete( key );
               }
            }
         }),
         catchError( error => {
            if ( this.inFlight.has( key )) {
               this.inFlight.delete( key );
            }

            throw error;
         }),
      );
   }
}

export let analyticsHttpInterceptor = {
   provide: HTTP_INTERCEPTORS,
   useClass: AnalyticsHttpInterceptorBase,
   multi: true
};
