import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { MaterialsModule } from '@library/common/materials';
import { DirectivesModule } from '@library/core/directives';
import { PipesModule } from '@library/core/pipes';
import { SaveButtonComponent } from './save-button.component';

@NgModule({
   declarations: [
      SaveButtonComponent,
   ],

   imports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule,

      MaterialsModule,

      DirectivesModule,

      PipesModule,
   ],

   exports: [ SaveButtonComponent ],
})
export class SaveButtonModule { }
