import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { MatDialog } from '@angular/material/dialog';
import { BarcodeScannerComponent } from '@library/modules/barcode-scanner';

@Component({
  selector: 'app-manifest-update',
  templateUrl: './manifest-update.component.html',
  styleUrls: ['./manifest-update.component.scss', '../cartage-update.scss']
})
export class ManifestUpdateComponent implements OnInit, OnDestroy {

   @Input() readonly form: FormArray;

   formSub: Subscription;

   newControl: FormControl;
   controlSub: Subscription;

   constructor(
      public dialog: MatDialog,
   ) { }

   ngOnInit(): void {
      this.formSub = this.form.valueChanges.pipe(
         distinctUntilChanged(),
         debounceTime( 300 ),
      ).subscribe( _ => { // * magic *
         if ( this.form.at( this.form.length - 1 ).dirty ) {
            this.form.push( new FormControl() );
         }
      });

      this.setControl();
   }

   ngOnDestroy() {
      this.formSub.unsubscribe();
   }

   setControl() {
      if ( this.newControl ) {
         this.controlSub.unsubscribe();
         this.form.push( this.newControl );
      }

      this.newControl = new FormControl();
      this.controlSub = this.newControl.valueChanges.subscribe( _ => this.setControl() );
   }

   startScanner(): void {
      const dialogRef = this.dialog.open( BarcodeScannerComponent );

      dialogRef.afterClosed().subscribe( result => {
         if ( result?.length ) {
            this.form.removeAt( this.form.length - 1 );
            result.forEach( item => this.form.push( new FormControl( item )));
            this.form.push( new FormControl() );
         }
      });
   }
}
