import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable, pipe } from 'rxjs';
import { map, tap, shareReplay } from 'rxjs/operators';

import { ResponseWrapper } from '@library/common/http';

import { CacheService, Preferences, ResetService } from '@library/core/services';

import { Account } from './account';
import { User } from '../user/user';
import { UserService } from '../user/user.service';

import { MatSnackBar } from '@angular/material/snack-bar';

const sortPipe = pipe(
   map(( list: Account[] ) => list.sort(( a, b ) => {
      const stat1 = a?.stationCode?.toLowerCase();
      const stat2 = b?.stationCode?.toLowerCase();
      if ( !stat1 ) { return 0; }

      return ( stat1 > stat2 ) ? 1 : ( stat1 < stat2 ) ? -1 : 0;
   })),
   map( list => list.sort(( a, b ) => {
      const name1 = a?.name?.toLowerCase();
      const name2 = b?.name?.toLowerCase();
      if ( !name1 ) { return 0; }

      return ( name1 > name2 ) ? 1 : ( name1 < name2 ) ? -1 : 0;
   })),
   map( list => list.sort(( a, b ) => +b?.pinned - +a?.pinned )),
   map( list => list.sort(( a, b ) => +b?.active - +a?.active )),
   map( list => list.sort(( a, b ) => +b?.primary - +a?.primary )),
);

@Injectable({
   providedIn: 'root',
})
export class AccountService {
   url = 'user/account';

   max = 7;

   constructor(
      private router: Router,
      private http: HttpClient,
      private cache: CacheService,
      private resetService: ResetService,
      private user: UserService,
      private userprefs: Preferences,
      private snackbar: MatSnackBar,
   ) {
      // remove deprecated customer pref
      if ( this.userprefs.getPref( 'customer' )) {
         if ( !this.userprefs.getPref( 'account' )) {
            // move deprecated customer pref to account pref
            this.userprefs.upsertPref( 'account', this.userprefs.getPref( 'customer' ));
         }
         this.userprefs.clearPref( 'customer' );
      }
   }

   get bookmarked() { return this.userprefs.getPref( 'account' )?.bookmarked || []; }
   set bookmarked( bookmarked: number[] ) {
      this.userprefs.clearPref( 'account', 'bookmarked' );

      if ( !bookmarked.length ) { return; }

      this.userprefs.upsertPref( 'account', { bookmarked });
   }

   get list(): Observable<Account[]> {
      return this.cache.observable( 'accountlist' ) ??
         this.user.accounts.pipe(
            // set pinned
            map( list => list.map( a => ({ ...a, pinned: ( this.bookmarked?.includes( a.id ) || a.primary )}) )),
            sortPipe,
            tap( list => this.bookmarked = list.filter( a => !a?.primary ).filter( a => a?.pinned ).map( a => a?.id )),
            tap( list => this.cache.store( 'accountlist', list, 1440 )),
            shareReplay(),
         );
   }

   get length(): Observable<number> {
      return this.list.pipe( map( c => c.length ));
   }

   get slice(): Observable<Account[]> {
      return this.list.pipe( map( list => list.splice( 0, this.max )));
   }

   get primary(): Observable<Account> {
      return this.list.pipe( map( list => list.filter( c => c.primary )[0] ));
   }

   get active(): Observable<Account> {
      return this.list.pipe( map( list => list.filter( c => c.active )[0] ));
   }

   switchAccount( id: number ): Observable<any> {
      return this.http.patch<ResponseWrapper<User>>( this.url + '/' + id, '' )
         .pipe(
            tap( _ => this.user.logout() ),
            tap( _ => this.resetService.reset() ),
            tap( _ => this.router.navigate([ 'redirect', '/', { skipLocationChange: true }])),
         );
   }

   bookmark( account: Account ) {
      if ( !account?.pinned ) { // pinning
         if ( this.bookmarked.length + 1 >= this.max ) { // the + 1 is for the primary account
            this.snackbar.open( 'You have reached the maximum number of bookmarks', null, { duration: 2000 });

            return;
         }

         this.bookmarked = [ ...this.bookmarked, account.id ];
      }

      if ( account?.pinned ) { // unpinning
         if ( account?.primary ) {
            this.snackbar.open( 'Primary account is always bookmarked', null, { duration: 2000 });

            return;
         }

         this.bookmarked = this.bookmarked.filter( a => a !== account?.id );
      }

      this.reset();
   }

   reset() {
      this.cache.expire( 'accountlist' );
   }
}
