import { Component, OnInit } from '@angular/core';

import { environment } from '@vp/http';

import { NavMenuService, NavItem } from '@library/modules/menu';
export const MenuItems: NavItem[] = [
   { label: 'Cartage', path: '/cartage', icon: 'cartage' },
];

@Component({
   selector: 'app-menu',
   templateUrl: './menu.component.html',
   styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

   environment = environment;

   constructor( private nav: NavMenuService ) { }

   ngOnInit(): void {
      this.nav.load( MenuItems );
      this.nav.title = 'vendor portal';
   }
}
