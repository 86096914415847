<ng-template #loading>
   <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-template>

<ng-template #spinner>
   <mat-spinner diameter="30" style="margin-right: 2.5em;"></mat-spinner>
</ng-template>

<ng-template #noresults>
   No Matching Accounts
</ng-template>

<div class="manage-account" *ngIf="accountService.list | async as accounts else loading">
   <ng-container *ngIf="accounts.length else noresults">
      <div>Bookmarked Accounts</div>
      <br/>

      <div style="display: flex; flex-direction: column; flex-wrap: wrap;" [style.maxHeight]="!breakpoints.small ? '18em' : ''">
         <ng-container *ngFor="let account of accounts | filterlist: 'pinned' : true | filterlist: '' : filterString | slice: 0 : accountService.max">
            <ng-container *ngTemplateOutlet="accountButton; context: { account: account }"></ng-container>
         </ng-container>
      </div>

      <br/><mat-divider></mat-divider><br/>

      <div>Click to switch to account</div>
      <br/>

      <div style="display: flex; flex-direction: column; flex-wrap: wrap;" [style.maxHeight]="!breakpoints.small ? '26em' : ''">
         <ng-container *ngFor="let account of accounts | filterlist: 'pinned' : false | filterlist: '' : filterString | slice: startIndex : endIndex">
            <ng-container *ngTemplateOutlet="accountButton; context: { account: account }"></ng-container>
         </ng-container>

         <mat-paginator
            *ngIf="(accounts | filterlist: 'pinned' : false).length > 10"
            hidePageSize="true"
            [length]="(accounts | filterlist: 'pinned' : false).length"
            [pageSize]="pageSize"
            (page)="page = $event.pageIndex">
         </mat-paginator>
      </div>
   </ng-container>
</div>

<ng-template #accountButton let-account="account">
   <div class="clickable" style="padding: 1em; width: 50%; display: flex; justify-content: space-between; align-items: center;">
      <button mat-icon-button *ngIf="_loading !== account.id; else spinner" (click)="accountService.bookmark( account )" style="margin-right: 1em;">
         <mat-icon>{{(account.primary) ? 'star' : (account.pinned) ? 'bookmark' : 'bookmark_bordered'}}</mat-icon>
      </button>
      <div (click)="switch( account.id )" style="flex-grow: 1;">
         <span>{{account.name}}</span>
         <ng-container *ngIf="account?.accountNumber">
            <span style="margin: 0 2ex;">|</span>
            <span>Account #{{account.accountNumber}}</span>
         </ng-container>
         <div *ngIf="account?.stationCode" class="station-code">{{account.stationCode}}</div>
      </div>
   </div>
</ng-template>
