import { Injectable, Injector } from '@angular/core';

@Injectable({
   providedIn: 'root',
})
export class ResetService {
   constructor( private injector: Injector ) { }

   reset() {
      const theGrandPumba = this.injector.get( Injector ) as any;

      theGrandPumba.records.forEach( c  => {
         if ( !c || c.value instanceof ResetService ) { return; }
         if ( c.value?.reset && typeof c.value.reset === 'function' ) {
            // console.log( 'reset function found on', c, '!' );
            c.value.reset();
         }
      });
   }
}
