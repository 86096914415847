import { Injectable } from '@angular/core';
import { timer, Observable, of } from 'rxjs';

export const DefaultExpiryLength = 5; // Minutes

@Injectable({
   providedIn: 'root'
})
export class CacheService {

   cached = {};
   expiry = {};

   observable<T>( property: string ): Observable<T> {
      if ( this.check( property )) {
         return of( this.retrieve( property ));
      }
   }

   check( property: string ): boolean {
      if ( !this.expiry[property] ) { delete( this.cached[property] ); }

      return !!this.expiry[property];
   }

   retrieve( property: string ): any {
      if ( !this.check( property )) { return; }

      return this.cached[property];
   }

   /** length is in minutes */
   store( property: string, data: any, length: number = DefaultExpiryLength ): void {
      this.cached[property] = data;
      if ( this.expiry[property] ) { this.expiry[property].unsubscribe(); }
      this.expiry[property] = timer( length * 60 * 1000 ).subscribe( _ => this.expire( property ));
   }

   clearSet( property: string ) {
      if ( !property ) { return; }
      Object.keys( this.expiry )
         .filter( prop => prop.includes( property ))
         .map( prop => this.expire( prop ));
   }

   expire( property: string ): void {
      if ( this.expiry[property] ) {
         this.expiry[property].unsubscribe();
         delete( this.expiry[property] );
         // delete( this.cached[property] );
      }
   }

   reset(): void {
      this.cached = {};
      Object.keys( this.expiry ).map( key => this.expiry[key].unsubscribe() );
      this.expiry = {};
   }
}
