import * as faker from 'faker';

import { Risks, RisksTypes } from '../base';

export class MockRisks extends Risks {
   constructor() {
      super({
         type: faker.random.arrayElement( RisksTypes ),
         amount: faker.finance.amount(),
         currency: faker.finance.currencyCode(),
      });

      this.dangerousGoods = faker.datatype.boolean();
   }
}
