/* tslint:disable ban-types */
import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { filter, tap } from 'rxjs/operators';

declare let gtag: Function;

@Injectable({
   providedIn: 'root'
})
export class GoogleAnalyticsService {
   constructor( public router: Router ) { }

   load( gtagId?: string ) {
      if ( !gtagId ) { return; }

      console.log( 'creating gtag script', gtagId );

      // register gtag
      const gtagManagerScript = document.createElement( 'script' );
      gtagManagerScript.async = true;
      gtagManagerScript.src = `https://www.googletagmanager.com/gtag/js?id=${gtagId}`;
      document.head.appendChild( gtagManagerScript );

      // register google analytics
      const gtagScript = document.createElement( 'script' );
      gtagScript.innerHTML = `
         window.dataLayer = window.dataLayer || [];
         function gtag(){dataLayer.push(arguments);}
         gtag('js', new Date());

         gtag( 'config', '${gtagId}' );`;

      document.head.appendChild( gtagScript );

      // register listener
      this.router.events.pipe(
         filter( event => event instanceof NavigationEnd ),
         tap( event => console.log( 'found event', event )),
         tap( _ => console.log( 'gtag function', typeof gtag === 'function' )),
      ).subscribe(( event: NavigationEnd ) =>
         gtag( 'event', 'page_view', {
            page_path: event.urlAfterRedirects
         }));
   }
}
