<div class="action-menu">
   <ng-container *ngIf="userService.user | async">
      <lib-menu-help></lib-menu-help>

      <lib-quick-reports *ngIf="access.hasAccess( 'tracking' ) | async"></lib-quick-reports>

      <lib-theme-switcher></lib-theme-switcher>

      <lib-menu-settings></lib-menu-settings>

      <lib-notifications></lib-notifications>
   </ng-container>

   <lib-user-menu></lib-user-menu>
</div>
