import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule, } from '@angular/forms';

import { MaterialsModule } from '@library/common/materials';
import { PipesModule } from '@library/core/pipes';

import { AttachmentModule } from '@library/modules/attachment';

import { NoteUpdateComponent } from './notes/note-update.component';
import { DriverUpdateComponent } from './driver/driver-update.component';
import { PodUpdateComponent } from './pod/pod-update.component';
import { TrackingUpdateComponent } from './tracking/tracking-update.component';
import { ChargeUpdateComponent } from './charges/charge-update.component';
import { ManifestUpdateComponent } from './manifest/manifest-update.component';

import { OverflowLineComponent } from './overflow-line/overflow-line.component';

@NgModule({
   declarations: [
      NoteUpdateComponent,
      DriverUpdateComponent,
      PodUpdateComponent,
      TrackingUpdateComponent,
      ChargeUpdateComponent,
      ManifestUpdateComponent,
      OverflowLineComponent,
   ],
   imports: [
      CommonModule,
      FormsModule, ReactiveFormsModule,

      MaterialsModule,
      PipesModule,

      AttachmentModule,
   ],
   exports: [
      NoteUpdateComponent,
      DriverUpdateComponent,
      PodUpdateComponent,
      TrackingUpdateComponent,
      ChargeUpdateComponent,
      ManifestUpdateComponent,
      OverflowLineComponent,
   ]
})
export class CartageUpdateModule { }
