import { Pipe, PipeTransform } from '@angular/core';

import { parsePhoneNumber } from 'libphonenumber-js';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {

  transform( phoneValue: number | string, country: any = 'US' ): string {
    const stringPhone = phoneValue + '';
    const phoneNumber = parsePhoneNumber( stringPhone, country );
    const formatted = phoneNumber.formatInternational();

    return formatted;
  }

}
