import * as faker from 'faker';
import { optional } from '@library/common/functions';

import { mockCountries } from './countries.mock';
import { BillingParty } from '../base';

export class MockBillingParty extends BillingParty {
   constructor() {
      super();

      this.id = faker.datatype.number();
      this.name = faker.company.companyName();
      this.address1 = faker.address.streetAddress();
      if ( optional() ) { this.address2 = faker.address.secondaryAddress(); }
      if ( optional() ) { this.address3 = faker.address.secondaryAddress(); }
      this.city = faker.address.city();
      this.postalcode = faker.address.zipCode();

      const country = faker.random.arrayElement( mockCountries );
      this.country = country.code;
      if ( country.regions && country.regions.length ) {
         this.state = faker.random.arrayElement( country.regions ).code;
         if ( optional() && country.postalDetails ) { this.postalcode = country.postalDetails.test; }
      }

      if ( optional() ) { this.phone = faker.phone.phoneNumber().slice( 0, 25 ); }
      if ( optional() ) { this.fax = faker.phone.phoneNumber().slice( 0, 25 ); }
      if ( optional() ) { this.email = faker.internet.email(); }
      if ( optional() ) {
         this.contact = ({
            name: faker.name.findName(),
            phone: faker.phone.phoneNumber().slice( 0, 25 )
         });
      }
   }
}
