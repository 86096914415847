export const AllServices: string[] = [
   'domestic_tracking',
   'domestic_booking',
   'domestic_quoting',
   'domestic_invoicing',
   'international_tracking',
   'international_booking',
   'international_quoting',
   'international_invoicing',
   'ocean_tracking',
   'ocean_booking',
   'ocean_quoting',
   'ocean_invoicing',
   'admin',
];

export const AccessRoles = {
   domestic: [
      'domestic_tracking',
      'domestic_booking',
      'domestic_quoting',
      'domestic_invoicing',
   ],
   international: [
      'international_tracking',
      'international_booking',
      'international_quoting',
      'international_invoicing',
   ],
   ocean: [
      'ocean_tracking',
      'ocean_booking',
      'ocean_quoting',
      'ocean_invoicing',
   ],

   tracking: [
      'domestic_tracking',
      'international_tracking',
      'ocean_tracking',
   ],

   booking: [
      'domestic_booking',
      'international_booking',
      'ocean_booking',
   ],
   quoting: [
      'domestic_quoting',
      'international_quoting',
      'ocean_quoting',
   ],
   invoicing: [
      'domestic_invoicing',
      'international_invoicing',
      'ocean_invoicing',
   ],
};
