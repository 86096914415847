import { BillingParty } from '@library/common/models';

// Used for Main/Successor/Predecessor
export interface Leg {
   carrier: string;
   proNumber: string;

   accountNumber?: string;
   serviceLevel?: string;

   shipper?: BillingParty;
   consignee?: BillingParty;
}

export class Leg implements Leg {
   constructor( leg?: Leg ) {
      this.carrier = leg?.carrier;
      this.proNumber = leg?.proNumber;

      if ( leg?.accountNumber ) { this.accountNumber = leg?.accountNumber; }
      if ( leg?.serviceLevel ) { this.serviceLevel = leg?.serviceLevel; }

      if ( leg?.shipper ) { this.shipper = leg?.shipper; }
      if ( leg?.consignee ) { this.consignee = leg?.consignee; }
   }
}
