import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { MatSnackBar } from '@angular/material/snack-bar';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { Preferences, BreadcrumbService, KeyboardShortcutsService } from '@library/core/services';
import { AccountService } from './account';

interface StartPage {
   path: string;
   label: string;
   account?: number;
}

@Injectable({
   providedIn: 'root'
})
export class StartpageService {

   _default = {
      path: null,
      label: ''
   };
   _hasNavigated = false;

   previous: any;

   constructor(
      private prefs: Preferences,
      private as: AccountService,
      private router: Router,
      private snackbar: MatSnackBar,
      private breadcrumbs: BreadcrumbService,
      private ks: KeyboardShortcutsService,
   ) {
      this.ks.register$({
         key: 'H',
         description: 'Go to your Start Page',
         keyDisplay: 'Shift + H',
         icon: 'dashboard',
      }).subscribe( _ =>
         this.startPage$.subscribe( page => this.router.navigate([ page ])));
   }

   get pref(): StartPage[] {
      return this.prefs.getPref( 'start' ) || [];
   }

   get active(): Observable<StartPage> {
      if ( this.pref && !this.pref[0]?.account ) {
         // Start Page for User
         return of( this.pref[0] );
      }

      return this.as.active.pipe(
         map( c => this.pref.filter( p => p?.account === c.id )),
         map( c => c?.length ? c[0] : this._default ),
      );
   }

   get startPage$(): Observable<string> {
      return this.active.pipe( map( p => p && p.path ));
   }

   get startPageLabel$(): Observable<string> {
      return this.active.pipe( map( p => p && p.label ));
   }

   setStartPageAll( path: string = this.router.url ) {
      if ( this.pref ) { this.previous = this.pref; }
      const label = [ ...this.breadcrumbs.breadcrumbs ].map( b => b.label ).join( ' > ' ).toUpperCase();
      this.prefs.setPref({ start: [{ path, label }] });

      this.changed();
   }

   setStartPageCurrent( path: string = this.router.url ) {
      if ( this.pref ) { this.previous = this.pref; }
      const label = [ ...this.breadcrumbs.breadcrumbs ].map( b => b.label ).join( ' > ' ).toUpperCase();
      this.as.active.subscribe( c => {
         if ( this.pref && this.pref.filter( p => p.account === c.id ).length ) {
            // have already set this account, go ahead and update
            this.pref.map( p => p.account === c.id ? { ...p, path } : p );
            this.prefs.setPref({ start: this.pref, label });
         } else {
            this.prefs.setPref({ start: [
               ...this.pref.filter( p => p.account ),
               { path, account: c.id, label },
            ]});
         }

         this.changed();
      });
   }

   cleanup() {
      this.as.list.pipe(
         map( list => list.map( c => c.id )),
      ).subscribe(( list: number[] ) => {
         if ( this.pref.length > 1 ) {
            this.prefs.setPref({ start: [
               this.pref.filter( p => list.includes( p.account ))
            ]});
         }
      });
   }

   changed() {
      const snackbarRef = this.snackbar.open( 'Start page set', 'Undo', { duration: 5000 });

      snackbarRef.afterDismissed().subscribe( event => {
         if ( event.dismissedByAction ) {
            this.prefs.setPref({ start: this.previous });
         }

         setTimeout( _ => this.cleanup());
      });
   }
}
