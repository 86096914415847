export interface Contact {
   id: number;

   default: boolean;
   pinned: boolean;

   name: string;
   title?: string;

   phone?: string;
   email?: string;
}

export class Contact implements Contact {
   constructor( c?: Contact ) {
      this.id = c?.id || null;

      this.default = c?.default || false;
      this.pinned = c?.pinned || false;

      this.name = c?.name || '';
      this.title = c?.title || '';

      this.phone = c?.phone || '';
      this.email = c?.email || '';
   }

   public toString = (): string => {
      return [
         this.name,
         this.title,
         this.phone,
         this.email,
      ].join('◬');
   }
}

export const ContactLengths = {
   name: 60,
   title: 30,
   phone: 25,
   email: 100
};

import { FormGroup, FormControl, Validators } from '@angular/forms';
export function ContactGroup( contact: Contact = new Contact() ) {
   return new FormGroup({
      id: new FormControl( contact.id ),
      default: new FormControl( contact.default || false ),
      pinned: new FormControl( contact.pinned || false ),
      name: new FormControl(  contact.name,  Validators.maxLength( ContactLengths.name )),
      title: new FormControl( contact.title, Validators.maxLength( ContactLengths.title )),
      phone: new FormControl( contact.phone, Validators.maxLength( ContactLengths.phone )),
      email: new FormControl( contact.email, [ Validators.maxLength( ContactLengths.email ), Validators.email ])
   }, {
      validators: ( form: FormGroup ) => {
         const name = form.controls['name'].value && form.controls['name'].value.trim();
         const title = form.controls['title'].value && form.controls['title'].value.trim();
         const phone = form.controls['phone'].value && form.controls['phone'].value.trim();
         const email = form.controls['email'].value && form.controls['email'].value.trim();

         if ( !name && ( title || phone || email )) {
            return { nameRequired: true };
         }

         if ( name && ( !phone && !email )) {
            return { oneRequired: true };
         }
      }
   });
}
