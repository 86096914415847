<ng-template #spinner>
   <mat-spinner diameter="24"></mat-spinner>
</ng-template>

<div class="statuses" *ngFor="let status of form.controls; index as i; first as first; last as last">
   <div class="item-wrapper" [formGroup]="status">
      <mat-form-field class="date">
         <mat-label>Date</mat-label>
         <input matInput required formControlName="date" placeholder="MM/DD/YYYY" [matDatepicker]="datepicker">
         <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
         <mat-datepicker #datepicker [touchUi]="true"></mat-datepicker>
      </mat-form-field>

      <mat-form-field class="time">
         <mat-label>Time</mat-label>
         <input #input matInput placeholder="HH:MM" required autocomplete="off" formControlName="time" (click)="breakpoints.large ? '' : openClock( i )">
         <mat-icon matSuffix class="clock" (click)="openClock( i )" zindex="-1">schedule</mat-icon>
      </mat-form-field>

      <div class="status">
         <mat-form-field style="width: 100%;" *ngIf="enums.statusTypes( type ) | async as statuses else spinner">
            <mat-label>Status</mat-label>
            <mat-select formControlName="code" required>
               <mat-option *ngFor="let status of statuses" [value]="status.code">{{status.label}}</mat-option>
            </mat-select>
         </mat-form-field>
      </div>

      <div *ngIf="clearable && !first" class="button">
         <button mat-icon-button class="warn-button" matTooltip="REMOVE STATUS" matTooltipPosition="before" (click)="form.removeAt( i )" tabindex="-1">
            <mat-icon>clear</mat-icon>
         </button>
      </div>
   </div>

   <mat-divider *ngIf="!last"></mat-divider>
</div>

<div class="add-button">
   <button mat-stroked-button color="accent" (click)="addGroup()">
      <mat-icon>add</mat-icon> ADD STATUS
   </button>
</div>
