export interface LinehaulMilestones {
   new: string;
   intransit: string;
   arrivedTerminal: string;
   recovered: string;
}

export class LinehaulMilestones implements LinehaulMilestones {
   constructor( current?: LinehaulMilestones ) {
      this.new = current?.new || '';
      this.intransit = current?.intransit || '';
      this.arrivedTerminal = current?.arrivedTerminal || '';
      this.recovered = current?.recovered || '';
   }
}
