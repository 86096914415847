import { FormControl, FormGroup, Validators } from '@angular/forms';

import { Attachment, PiecesUnits } from '@library/common/models';
import { TimeRegEx } from '@library/common/functions';
import moment from 'moment';

export interface CartagePod {
   pieces: number;
   piecesUom: PiecesUnits;

   name: string;
   date: string;
   time: string;

   attachment?: Attachment[];
}

export class CartagePod {
   constructor( pod: any = {} ) {
      this.pieces = pod?.pieces;
      this.piecesUom = pod?.piecesUom;
      this.name = pod?.name;
      this.date = pod?.date;
      this.time = pod?.time;

      if ( pod?.attachment ) { this.attachment = pod?.attachment; }
   }
}

export function CartagePodGroup( pod: CartagePod = {} as CartagePod ) {
   return new FormGroup({
      pieces: new FormControl( pod.pieces || '', [ Validators.required, Validators.min( 0 )]),
      piecesUom: new FormControl( pod.piecesUom || PiecesUnits[0], [ Validators.required ]),

      name: new FormControl( pod.name || '', [ Validators.required ]),
      date: new FormControl( moment( pod.date ) || moment(), [ Validators.required ]),
      time: new FormControl( pod.time || moment().format( 'HH:mm' ), [
         Validators.required,
         Validators.pattern( TimeRegEx )
      ]),

      attachmentData: new FormControl( pod.attachment?.map( a => a.data ) || [] ),
   });
}
