import { Component, Input, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';

@Component({
  selector: 'lib-barcode-scanner',
  templateUrl: './barcode-scanner.component.html',
  styleUrls: ['./barcode-scanner.component.scss']
})
export class BarcodeScannerComponent {

   @ViewChild( 'scanner' ) scanner: ZXingScannerComponent;

   @Input() scannerFormats: string[] = [
      'CODE_128'
   ];

   loading = true;

   device: FormControl = new FormControl( null );

   foundDevices: MediaDeviceInfo[] = [];

   scannedItems: string[] = [];

   allowDuplicates = false;
   success = false;

   cameraFound = true; // start with true in order to initialize scanner

   constructor(
      public dialogRef: MatDialogRef<BarcodeScannerComponent>,
   ) { }

   camerasFoundHandler( event: MediaDeviceInfo[] ): void {
      this.foundDevices = event;

      this.scanner.autostarted.subscribe( _ => {
         this.device.setValue( this.scanner.device );
         this.loading = false;
      });
   }

   scanSuccessHandler( item: string ): void {
      this.scannedItems.unshift( item );

      if ( !this.allowDuplicates ) {
         this.scannedItems = [ ...new Set( this.scannedItems )];
      }

      this.success = true;
      setTimeout( _ => this.success = false, 300 );
   }

   removeItem( index: number ) {
      this.scannedItems.splice( index, 1 );
   }
}
