import { Component, OnInit } from '@angular/core';

import { BreakpointService } from '@library/core/services';
import { AccountService, StartpageService } from '@library/modules/user';

import { KeyboardShortcutsDisplayService } from './keyboard-shortcuts/keyboard-shortcuts-display.service';

@Component({
   selector: 'lib-menu-settings',
   templateUrl: './settings.component.html',
   styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
   constructor(
      public bs: BreakpointService,
      public sp: StartpageService,
      public accountService: AccountService,
      public ks: KeyboardShortcutsDisplayService,
   ) { }

   ngOnInit() {
   }
}
