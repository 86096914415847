import * as faker from 'faker';
import { optional } from '@library/common/functions';

import { Attachment, AttachmentExtensionTypes } from '../base';

export class MockAttachment extends Attachment {
   constructor() {
      super({
         type: faker.random.arrayElement( ['Commercial Invoice', 'Country of Origin', 'Delivery Instructions'] ),
         filename: '',
         extension: faker.random.arrayElement( AttachmentExtensionTypes ),
         size: faker.datatype.number(1000000),
         mimetype: faker.system.mimeType(),
      });

      this.filename = faker.lorem.word() + this.extension;

      if ( optional() ) { this.id = faker.datatype.number(10000); }

      if ( optional() ) { this.data = faker.image.image(); }

      if ( optional() ) { this.name = faker.company.catchPhrase(); }
   }
}
