Welcome to Radiant Global Logistics, Inc.’s (“Radiant”) {{branding.portal}} website (“Portal”). This Portal is operated by Radiant for use by Radiant customers (“Customer”).  By using this Portal, Customer acknowledges that it has read and understood and agrees to be bound by these terms and conditions of use (“Terms”).  If Customer does not agree with any of these Terms, do not use the Portal.  These Terms are subject to and governed by Radiant’s Terms of Use located at <a [href]="branding.theme.terms">{{branding.theme.terms}}</a> that by this reference are incorporated herein.<br/><br/>
Please carefully review these Terms.  Through this Portal, you may access and update your account information, monitor shipping activity and view information about your goods in transit.<br/>
1. <strong>License Grant</strong>.  Subject to these Terms, Radiant grants Customer a personal, limited, non-exclusive, non-transferable license for the term of this agreement to use the Portal solely to access Customer’s information as it pertains to its business with Radiant.  Upon termination of this agreement, at any time and for any reason, Customer shall immediately cease using the Portal.  Other than information submitted by Customer, all of the Content obtained via the Portal, including for example all data, images, logos, graphics and text, represent valuable proprietary and intellectual property of Radiant and/or its licensors. Such Content and information remains the exclusive property of Radiant and is protected by all relevant state, federal and international laws, rules and regulations relating to intellectual or proprietary property.
<br/><br/>
2. <strong>Use Restrictions</strong>.  Customer shall not, directly or indirectly: (i) license, sell, convey, lease, assign or otherwise transfer any information, account or user information, logins, passwords or other data on this Portal (“Content”); (ii) alter or permit a third party to alter any part of the Content or Portal; (iii) access or use any Content, information or materials of any third party available through the Portal; (iv) use or permit the use of the Content or Portal except for the Customer’s access to its own information; (v) distribute or transfer any Content in any form (via any means or medium, digitally or otherwise), without Radiant’s express prior written consent; or (vi) disassemble, decompile, reverse engineer or otherwise attempt to derive source code or other trade secrets from the Portal or Content. All trademarks, service marks, names, trade names, trade dress, logos and images (“Marks”) are registered and/or common law trademarks or service marks of Radiant.  Use of this Portal in no way grants Customer, or any third party, any right or license to use such Marks.
<br/><br/>
3. <strong>Term</strong>.  Customer’s right to access the Portal and Content shall continue until the relationship between Radiant and Customer expires or earlier in the event of termination by Radiant, at any time and without cause.
<br/><br/>
4. <strong>Privacy Policy</strong>.  Use of this Portal is also governed by and subject to the terms and conditions of Radiant’s Privacy Policy located at <a [href]="branding.theme.parent">{{branding.theme.parent}}</a> the provisions of which are by this reference incorporated herein.
<br/><br/>
5. <strong>Customer Responsibility</strong>.  Customer acknowledges and agrees that it is solely responsible for the accuracy of any and all information input by Customer or provided to Radiant for use in conjunction with the Portal. Customer data shall not include any information that: (i) infringes or misappropriates, directly or indirectly, the copyright, trade secret, trademark or other intellectual property right of any third party; (ii) violates the privacy rights of any third party; or (iii) contains any obscene, defamatory, harassing, offensive or malicious material.  Customer is solely responsible and liable for maintaining the confidentiality of its passwords and information downloaded to the Portal.  Customer shall not allow others access to or use of this Portal.  Customer will notify Radiant immediately in the event there is a breach of Customer’s network or a breach of Customer’s login data.
<br/><br/>
6. <strong>Disclaimer</strong>.  The information and Content provided via this Portal is provided for informational purposes only and does not act as a warranty or guaranty.  THIS PORTAL AND THE CONTENT IS PROVIDED “AS IS” WITHOUT REPRESENTATIONS OR WARRANTIES OF ANY KIND.  RADIANT AND ITS LICENSORS AND SUPPLIERS HEREBY DISCLAIM ALL EXPRESS, IMPLIED AND STATUTORY WARRANTIES OF ANY KND, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.  FURTHERMORE, RADIANT DOES NOT WARRANT THAT THE PORTAL IS ERROR-FREE, WILL OPERATE WITHOUT INTERRUPTION OR WILL NOT CAUSE LOSS OF OR DAMAGE TO DATA STORED ON COMPUTING DEVICES ON WHICH IT IS INSTALLED.
<br/><br/>
7. <strong>Indemnification</strong>.  Customer agrees to defend, indemnify and hold harmless Radiant, its licensors and suppliers and their respective officers, directors, employees and agents from and against any and all damages, injuries, liabilities, costs, expenses, fees and fines (including reasonable attorneys’ fees) arising from or in any way related to a violation of these Terms or misuse of the Portal or Content by Customer, its employees or agents.
<br/><br/>
8. <strong>Damages Limitation</strong>.  IN NO EVENT WILL RADIANT, ITS LICENSORS OR SUPPLIERS BE LIABLE TO CUSTOMER, OR ANY THIRD PARTY, FOR ANY DIRECT, INDIRECT, SPECIAL OR OTHER CONSEQUENTIAL DAMAGES ARISING OUT OF OR IN WAY RELATED TO CUSTOMER’S USE OF THIS PORTAL OR THE CONTENT INCLUDING, WITHOUT LIMITATION, DAMAGES ARISING FROM ANY LOST REVENUES, PROFITS, DATA, BUSINESS INTERRUPTION, OR OTHERWISE, WHETHER BASED IN TORT, CONTRACT OR OTHER LEGAL THEORY, EVEN IF RADIANT WAS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
<br/><br/>
9. <strong>Modification</strong>.  Radiant may, at any time, in its sole discretion and without notice, change, modify, delete or update these Terms.  Customer’s use of this Portal following any such changes constitutes its acceptance of such modifications.  Radiant encourages Customer to periodically check for any such changes to these Terms.  Customer acknowledges and agrees that Radiant shall not be liable to Customer, or any third party, for such changes, modifications, suspension or termination of the Terms or this Portal.
<br/><br/>
10.   <strong>Governing Law</strong>.  These Terms are subject to and governed by the substantive laws of the State of Washington, U.S.A.  Venue for any and all actions shall be in the state or federal courts located in King County, Washington, U.S.A.  These terms are written in and shall be interpreted in accordance with the American English language.  The United Nations Convention on Contracts for the International Sale of Goods is expressly rejected and shall not apply in the interpretation or enforcement of these Terms.
<br/><br/>
11.   <strong>Remedies</strong>.  Radiant reserves the right to seek all remedies available by law or equity in the event of a violation of these Terms by Customer, its employees or agents.
<br/><br/>
12.   <strong>Entire Agreement</strong>.  These Terms and any other legal notices published by Radiant on the Portal and/or incorporated by reference constitute the entire agreement between Customer and Radiant concerning use of the Portal.
