import {
   Calculator, calcArray, calcGroup,
   COD, codGroup,
   POD,
   Reference, refArray,
   Risks, risksGroup,
   Status,
   Tradeshow, tradeshowArray, EmptyTradeshow,
   BillingParties, BillingParty, BillingPartyGroup,
   Attachment, attachmentArray,
} from '../base';

import { Milestones } from './milestones';

import { ShipmentTypes } from './shipment-types';
import { ShipmentModes } from './shipment-modes';

export interface Shipment<T extends ShipmentTypes, M extends ShipmentModes> {
   readonly shipType: T;
   readonly mode: M;

   hawb?: string;
   templateId?: number;
   templatename?: string;

   billingparty: BillingParties;

   station: BillingParty;
   shipper: BillingParty;
   consignee: BillingParty;
   bill_to: BillingParty;

   servicelevel: string;

   airportfrom?: string;
   airportto?: string;

   pdatefrom: string;
   pdateto: string;
   ptimefrom: string;
   ptimeto: string;

   ddatefrom: string;
   ddateto: string;
   dtimefrom: string;
   dtimeto: string;

   calculator: Calculator[];

   cod?: COD;

   pod?: POD;

   references?: Reference[];

   tradeshow?: Tradeshow[];

   risks?: Risks;

   accessorials?: string[];

   description?: string;
   specialinstructions?: string;

   statuses?: Status[];

   milestones?: {};

   attachments?: Attachment[];
}

export class Shipment<T, M> implements Shipment<T, M> {
   readonly shipType: T;
   readonly mode: M;

   constructor( s: Shipment<any, any> | {} = {} ) {
      if ( s['hawb'] ) { this.hawb = s['hawb']; }

      if ( s['templateId'] ) {
         this.templateId = s['templateId'];
         this.templatename = s['templatename'];
      }

      this.billingparty = s['billingparty'];

      this.station = new BillingParty( s['station'] );
      this.shipper = new BillingParty( s['shipper'] );
      this.consignee = new BillingParty( s['consignee'] );
      this.bill_to = new BillingParty( s['bill_to'] );

      this.servicelevel = s['servicelevel'];

      if ( s['airportfrom'] ) { this.airportfrom = s['airportfrom']; }
      if ( s['airportto'] ) { this.airportto = s['airportto']; }

      this.pdatefrom = s['pdatefrom'];
      this.pdateto = s['pdateto'];
      this.ptimefrom = s['ptimefrom'];
      this.ptimeto = s['ptimeto'];
      this.ddatefrom = s['ddatefrom'];
      this.ddateto = s['ddateto'];
      this.dtimefrom = s['dtimefrom'];
      this.dtimeto = s['dtimeto'];

      this.calculator = s['calculator']?.map( c => new Calculator( c )) || [];

      if ( s['cod'] ) { this.cod = new COD( s['cod'] ); }
      if ( s['pod'] ) { this.pod = new POD( s['pod'] ); }
      if ( s['references'] ) { this.references = s['references'].map( r => new Reference( r )); }
      if ( s['tradeshow'] ) { this.tradeshow = s['tradeshow'].map( t => new Tradeshow( t )); }
      if ( s['risks'] ) { this.risks = new Risks( s['risks'] ); }
      if ( s['accessorials'] ) { this.accessorials = s['accessorials']; }
      if ( s['description'] ) { this.description = s['description']; }
      if ( s['specialinstructions'] ) { this.specialinstructions = s['specialinstructions']; }

      if ( s['statuses'] ) { this.statuses = s['statuses']; }

      this.milestones = Milestones( 'road', s['milestones'] );

      if ( s['attachments'] ) { this.attachments = s['attachments']; }
   }
}

import { FormGroup, FormControl, ValidatorFn, AbstractControl } from '@angular/forms';
import { Moment } from 'moment';
export function minDateValidator( date: Moment ): ValidatorFn {
   return ( control: AbstractControl ): {[ key: string ]: any } | null => {
      return moment( date ).isAfter( moment( control.value )) ? { dateMin: control.value } : null;
   };
}

export function shipmentGroup( shipment: any = {} ): FormGroup {
   const fg = new FormGroup({
      shipType: new FormControl( shipment['shipType'] || ShipmentTypes[0] ),
      mode: new FormControl( shipment['mode'] || ShipmentModes[0] ),

      shipper: BillingPartyGroup( shipment['shipper'] ),
      consignee: BillingPartyGroup( shipment['consignee'] ),

      servicelevel: new FormControl( shipment['servicelevel'] || '' ),

      pdatefrom: new FormControl( shipment['pdatefrom'] ),
      pdateto: new FormControl( shipment['pdateto'] ),
      ddatefrom: new FormControl( shipment['ddatefrom'] ),
      ddateto: new FormControl( shipment['ddateto'] ),

      ptimefrom: new FormControl( shipment['ptimefrom'] ),
      ptimeto: new FormControl( shipment['ptimeto'] ),
      dtimefrom: new FormControl( shipment['dtimefrom'] ),
      dtimeto: new FormControl( shipment['dtimeto'] ),

      calculator: calcArray( shipment['calculator'] ),
      references: refArray( shipment['references'] ),

      risks: risksGroup( shipment['risks'] ),

      cod: codGroup( shipment['cod'] ),

      tradeshow: tradeshowArray( shipment['tradeshow'] ),

      description: new FormControl( shipment['description'] || '' ),
      specialinstructions: new FormControl( shipment['specialinstructions'] || '' ),

      attachments: attachmentArray( shipment['attachments'] ),
   });

   return fg;
}

import * as _moment from 'moment';
const moment = _moment;
export function shipmentForBooking<S extends Shipment<any, any>>( s: S ): S {
   if ( !s ) { return s; }

   // format dates
   [ 'pdatefrom', 'pdateto', 'ddatefrom', 'ddateto' ]
    .map( field => s[field] = moment( s[field], [ 'Y-MM-DD', 'MM/DD/YYYY' ] ).format( 'Y-MM-DD' ));

   // format times
   [ 'ptimefrom', 'ptimeto', 'dtimefrom', 'dtimeto' ]
      .map( field => s[field] =
            ([ null, '', 'Invalid date'].indexOf( s[field] ) === -1
               ? moment( s[field], [ 'HH:mm', 'HH:mm:ss' ] ).format( 'HH:mm:ss' )
               : '00:00:00' ));

   // remove blank calc items
   if ( s['calculator'] && s['calculator'].length ) {
      s['calculator'] = s['calculator'].filter( c => c.pieces && c.weight );
   }

   // make sure there is at least one calc item
   if ( !s['calculator'] || !s['calculator'].length ) {
      s['calculator'] = [ calcGroup().value ];
   }

   if ( s?.risks?.dangerousGoods ) {
      s.specialinstructions = 'DANGEROUS GOODS SHIPMENT ' + s.specialinstructions;
      delete( s.risks.dangerousGoods );
   }
   const risks = new Risks( s.risks );

   return {
      shipType: s['shipType'],
      mode: s['mode'],
      shipper: s['shipper'],
      consignee: s['consignee'],
      servicelevel: s['servicelevel'],
      airportfrom: s['airportfrom'],
      airportto: s['airportto'],
      pdatefrom: s['pdatefrom'],
      pdateto: s['pdateto'],
      ptimefrom: s['ptimefrom'],
      ptimeto: s['ptimeto'],
      ddatefrom: s['ddatefrom'],
      ddateto: s['ddateto'],
      dtimefrom: s['dtimefrom'],
      dtimeto: s['dtimeto'],
      calculator: s?.calculator.map( c => new Calculator( c )),
      cod: s['cod'],
      references: (s['references'] || []).filter( r => !!r.party || !!r.data ), // remove blank references
      tradeshow: (s['tradeshow'] || []).filter( t => !!t.name || !!t.booth || !!t.exhibitor ), // remove blank tradeshows
      risks: Object.keys( risks )?.length ? risks : undefined,
      accessorials: s['accessorials'],
      description: s['description'],
      specialinstructions: s['specialinstructions'],
      attachments: s['attachments'],
   } as S;
}

export function shipmentCopy<S extends Shipment<any, any>>( s: S, mode: null | 'continue' | 'reverse' = null ): S {
   if ( !s ) { return s; }

   let shipper = s.shipper;
   let consignee = s.consignee;
   let refs = s.references;
   let tradeshow = s.tradeshow;

   switch ( mode ) {
      case 'continue':
         shipper = s.consignee;
         consignee = new BillingParty();
         refs = ( s.references || [] ).slice()
            .filter( ref => ref.party !== 'shipper' )
            .map( ref => ({ ...ref,
               party: ( ref.party === 'consignee' ) ? 'shipper' : ref.party
            }));
         tradeshow = ( s?.tradeshow || EmptyTradeshow ).slice().map( t => new Tradeshow( t ));
         break;
      case 'reverse':
         shipper = s.consignee;
         consignee = s.shipper;

         const refMap = { shipper: 'consignee', consignee: 'shipper' };
         refs = ( s.references || [] ).slice()
            .map( ref => ({ ...ref,
               party: refMap[ref.party] ? refMap[ref.party] : ref.party
            }));

         tradeshow = ( s?.tradeshow || EmptyTradeshow ).slice().map( t => new Tradeshow( t ));
         break;
   }

   return {
      shipType: s['shipType'],
      mode: s['mode'],
      shipper,
      consignee,
      servicelevel: s['servicelevel'],
      airportfrom: s['airportfrom'],
      airportto: s['airportto'],
      calculator: s['calculator'],
      references: refs,
      tradeshow,
      risks: s['risks'],
      accessorials: s['accessorials'],
      description: s['description'],
      specialinstructions: s['specialinstructions'],
   } as S;
}
