import {
   InternationalShipment,
   internationalForBooking,
   internationalShipmentGroup,
   internationalShipmentCopy,
} from './international-shipment';

export interface InternationalRoadShipment extends InternationalShipment {
   mode: 'road';
}

export class InternationalRoadShipment extends InternationalShipment {
   mode: 'road' = 'road';

   constructor( shipment?: InternationalRoadShipment | InternationalShipment ) {
      super( shipment );
   }
}

import { FormGroup } from '@angular/forms';
export function internationalRoadShipmentGroup( s: any = {} ): FormGroup {
   return internationalShipmentGroup( s );
}

export function internationalRoadForBooking<S extends InternationalRoadShipment>( s: S ): S {
   return internationalForBooking( s ) as S;
}

export function internationalRoadShipmentCopy<S extends InternationalRoadShipment>( s: S, mode: null | 'continue' | 'reverse' = null ): S {
   return ({
      ...internationalShipmentCopy<S>( s, mode ),
   });
}
