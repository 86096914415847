import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component( {
   selector: 'lib-message-dialog',
   template: `<mat-dialog-content>{{message}}</mat-dialog-content>`,
   styles: [ `* { white-space: pre-wrap; }` ],
})
export class MessageDialogComponent {
   message: string;

   constructor(
      public dialogRef: MatDialogRef<MessageDialogComponent>,
      @Inject( MAT_DIALOG_DATA ) public data: any
   ) {
      this.message = (data.message) ? data.message : 'Hi!';
   }
}
