import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { UserComponent } from './user/user.component';
import { AccountComponent } from './account/account.component';
import { PasswordComponent } from './password/password.component';

const routes: Routes = [
   { path: 'customer', component: AccountComponent },
   { path: 'vendor', component: AccountComponent },
   { path: 'account', component: AccountComponent },
   { path: 'password', component: PasswordComponent },
   { path: '', component: UserComponent },

   { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserRoutingModule { }
