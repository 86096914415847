import { AfterViewInit, Component, ElementRef, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SignaturePad } from 'angular2-signaturepad';

import * as filesaver from 'file-saver';

@Component({
  selector: 'lib-signature-capture',
  templateUrl: './signature-capture.component.html',
  styleUrls: ['./signature-capture.component.scss']
})
export class SignatureCaptureComponent implements AfterViewInit {

   @ViewChild( SignaturePad ) signaturePad: SignaturePad;

   @ViewChildren( 'signature_content' ) content: QueryList<ElementRef>;

   options = {
      minWidth : 1,
      canvasWidth : 800,
      canvasHeight : 250,
   };

   get canvas(): HTMLCanvasElement {
      const content = this.content.first.nativeElement;

      return content.querySelector( 'canvas' );
   }

   constructor(
      public dialogRef: MatDialogRef<SignatureCaptureComponent>,
   ) { }

   ngAfterViewInit(): void {
      this.dialogRef.addPanelClass( 'signature-dialog' );
   }

   save(): void {
      if ( this.signaturePad.isEmpty() ) {
         this.dialogRef.close();
      } else {
         this.fillBackground();
         this.dialogRef.close( btoa( this.signaturePad.toDataURL( 'image/jpeg' )));
      }
   }

   fillBackground( color: string = 'rgb( 255, 255, 255 )'): void {
      const ctx = this.canvas.getContext('2d');

      ctx.globalCompositeOperation = 'destination-over';
      ctx.fillStyle = color;
      ctx.fillRect(0,0,this.canvas.width, this.canvas.height );
   }

   // Used for testing and viewing image
   downloadImage(): void {
      this.fillBackground();

      this.canvas.toBlob( blob => {
         const file = new File( [ blob ], 'testSignature.jpg', { type: 'image/jpeg' });
         filesaver.saveAs( file );
      });
   }
}
