<ng-template #spinner>
   <mat-spinner diameter="24"></mat-spinner>
</ng-template>

<ng-template #noCamera>
   <mat-error>No authorized camera found</mat-error>
</ng-template>

<div mat-dialog-content class="barcode-scanner">
   <ng-container *ngIf="cameraFound else noCamera">
      <div class="menu-wrapper" *ngIf="!loading else spinner">
         <div class="camera-menu">
            <mat-form-field>
               <mat-label>Select Camera</mat-label>
               <mat-select [formControl]="device">
                  <mat-option *ngFor="let d of foundDevices" [value]="d">{{d.label}}</mat-option>
               </mat-select>
            </mat-form-field>
         </div>

         <button mat-icon-button color="primary" [matMenuTriggerFor]="settingsMenu">
            <mat-icon>settings</mat-icon>
         </button>

         <mat-menu #settingsMenu="matMenu" xPosition="before">
            <span mat-menu-item>
               <mat-checkbox [(ngModel)]="allowDuplicates" labelPosition="before">Allow Duplicates</mat-checkbox>
            </span>
         </mat-menu>
      </div>

      <zxing-scanner #scanner
      class="scanner"
      [class.success]="success"
      [formats]="scannerFormats"
      [device]="device.value"
      (camerasFound)="camerasFoundHandler($event)"
      (camerasNotFound)="cameraFound = false"
      (scanSuccess)="scanSuccessHandler($event)"
      ></zxing-scanner>

      <div class="items" #items>
         <mat-list>
            <mat-list-item *ngFor="let item of scannedItems; index as i">
               <div class="item">
                  {{item}}
                  <button mat-icon-button color="warn" (click)="removeItem( i )">
                     <mat-icon matListIcon>clear</mat-icon>
                  </button>
               </div>
            </mat-list-item>
         </mat-list>
      </div>
   </ng-container>
</div>

<div mat-dialog-actions align="end" class="buttons">
   <button mat-button (click)="dialogRef.close()">CANCEL</button>

   <button mat-flat-button color="primary" [mat-dialog-close]="scannedItems" [disabled]="loading || !cameraFound || !scannedItems.length">
      SUBMIT
      <ng-container *ngIf="scannedItems.length">{{scannedItems.length}} ITEMS</ng-container>
   </button>
</div>
