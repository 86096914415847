import * as faker from 'faker';

import { Tradeshow, TLocations, TradeshowLocations } from '../base';

export class MockTradeshow extends Tradeshow {
   constructor( location: TLocations = TradeshowLocations[0] ) {
      super({
         location,
         name: faker.commerce.productName(),
         booth: faker.commerce.productAdjective(),
         exhibitor: faker.commerce.product(),
      });
   }
}
