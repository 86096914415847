import * as faker from 'faker';
import { optional } from '@library/common/functions';
import moment from 'moment';

import { DimUomUnits, PiecesUnits, WeightUomUnits } from '@library/common/models';

import {
   CartageDetailSubItem,
   CartageDetailItem,
} from '../cartage-detail-item';

export class MockCartageDetailSubItem extends CartageDetailSubItem {
   constructor() {
      super();

      this.pieces = faker.datatype.number( 99 );
      this.piecesUom = faker.random.arrayElement( PiecesUnits );

      if ( optional() ) {
         this.length = faker.datatype.number( 99 );
         this.width = faker.datatype.number( 99 );
         this.height = faker.datatype.number( 99 );
         this.dimUom = faker.random.arrayElement( DimUomUnits );
      }

      this.weight = faker.datatype.number( 999 );
      this.weightUom = faker.random.arrayElement( WeightUomUnits );

      if ( optional() ) { this.description = faker.lorem.words(); }
      if ( optional() ) { this.reference = faker.lorem.word(); }
   }
}

export class MockCartageDetailItem extends CartageDetailItem {
   constructor() {
      super();

      this.hawb = optional() ? faker.datatype.number( 9999999999 ).toString() : null;

      this.pieces = faker.datatype.number( 99 );
      this.piecesUom = faker.random.arrayElement( PiecesUnits );

      this.weight = faker.datatype.number( 999 );
      this.weightUom = faker.random.arrayElement( WeightUomUnits );

      this.subItems = new Array( Math.floor( Math.random() * 10 ) + 1 ).fill( 0 ).map( _ => new MockCartageDetailSubItem() );

      if ( optional( 70 )) {
         this.pickupFromDate = faker.date.past();
         this.pickupToDate = moment( this.pickupFromDate ).add( faker.datatype.number({ min: 1, max: 23 }), 'd' ).format( 'Y-MM-DD' );
         this.pickupFromTime = moment( faker.date.past() ).format( 'HH:mm:ss' );
         this.pickupToTime = moment( faker.date.past() ).add( faker.datatype.number({ min: 1, max: 720 }), 'm' ).format( 'HH:mm:ss' );
      }

      if ( optional( 70 )) {
         this.deliveryFromDate = faker.date.past();
         this.deliveryToDate = moment( this.deliveryFromDate ).add( faker.datatype.number({ min: 1, max: 23 }), 'd' ).format( 'Y-MM-DD' );
         this.deliveryFromTime = moment( faker.date.past() ).format( 'HH:mm:ss' );
         this.deliveryToTime = moment( faker.date.past() ).add( faker.datatype.number({ min: 1, max: 720 }), 'm' ).format( 'HH:mm:ss' );
      }
   }
}
