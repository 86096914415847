export const PiecesUnits = [ 'pcs', 'crt' ];
export type PiecesUnits = typeof PiecesUnits[number];

export const DimUomUnits = [ 'in', 'ft', 'cm', 'm' ] as const;
export type DimUomUnits = typeof DimUomUnits[number];

export const WeightUomUnits = [ 'lbs', 'kgs' ] as const;
export type WeightUomUnits = typeof WeightUomUnits[number];

export const VolUomUnits = [ 'cft', 'cbm' ] as const;
export type VolUomUnits = typeof VolUomUnits[number];

export interface Calculator {
   pieces: number;
   weight: number;

   length?: number;
   width?: number;
   height?: number;

   description?: string;
   reference?: string;

   weight_type: WeightUomUnits;
   measure_type: DimUomUnits;
}

export class Calculator implements Calculator {
   constructor( c?: any ) {
      this.pieces = c?.pieces ?? 1;
      this.weight = c?.weight ?? 1;

      if ( c?.length ) { this.length = c?.length; }
      if ( c?.width ) { this.width = c?.width; }
      if ( c?.height ) { this.height = c?.height; }

      if ( c?.description ) { this.description = c?.description; }
      if ( c?.reference ) { this.reference = c?.reference; }

      this.weight_type = c?.weight_type || WeightUomUnits[0];
      this.measure_type = c?.measure_type || DimUomUnits[0];
   }
}

import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
export function calcGroup( c?: any ): FormGroup {
   return new FormGroup({
      pieces: new FormControl( c?.pieces ),
      weight: new FormControl( c?.weight ),

      length: new FormControl( c?.length ),
      width:  new FormControl( c?.width  ),
      height: new FormControl( c?.height ),

      description:  new FormControl( c?.description ?? '' ),
      reference:  new FormControl( c?.reference ?? '' ),

      weight_type:  new FormControl( c?.weight_type ?? WeightUomUnits[0] ),
      measure_type: new FormControl( c?.measure_type ?? DimUomUnits[0] ),
   });
}

export function calcArray( calculator: Calculator[] = [] ): FormArray {
   const calc = new FormArray( [] );

   calculator.filter( c => JSON.stringify( new Calculator( c )) !== JSON.stringify( new Calculator() ))
      .map( c => calc.push( setCalcGroupValidators( calcGroup( c ))));

   // add "magic" item
   calc.push( calcGroup() );

   return calc;
}

export function setCalcGroupValidators( g: FormGroup ): FormGroup {
   g.get('pieces').setValidators([ Validators.min( 1 ), Validators.required ]);
   g.get('pieces').updateValueAndValidity({ onlySelf: true, emitEvent: false });

   g.get('weight').setValidators([ Validators.min( 1 ), Validators.required ]);
   g.get('weight').updateValueAndValidity({ onlySelf: true, emitEvent: false });

   g.get('length').setValidators([ Validators.min( 0 ) ]);
   g.get('length').updateValueAndValidity({ onlySelf: true, emitEvent: false });

   g.get('width').setValidators([ Validators.min( 0 ) ]);
   g.get('width').updateValueAndValidity({ onlySelf: true, emitEvent: false });

   g.get('height').setValidators([ Validators.min( 0 ) ]);
   g.get('height').updateValueAndValidity({ onlySelf: true, emitEvent: false });

   g.get('description').setValidators([ Validators.maxLength( 35 ) ]);
   g.get('description').updateValueAndValidity({ onlySelf: true, emitEvent: false });

   g.get('reference').setValidators([ Validators.maxLength( 35 ) ]);
   g.get('reference').updateValueAndValidity({ onlySelf: true, emitEvent: false });

   return g;
}
