import {
   Component,
   OnInit, OnDestroy,
   Input, Output,
   ViewChild, ElementRef,
   EventEmitter
} from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { Router } from '@angular/router';

import { BreadcrumbService, KeyboardShortcutsService } from '@library/core/services';

import { NavItem } from '../nav-menu.service';

export const TrackItem: NavItem = {
   label: 'Track a Shipment',
   path: '',
   icon: 'track'
};

@Component({
   selector: 'lib-tracking',
   templateUrl: './tracking.component.html',
   styleUrls: ['./tracking.component.scss'],
   animations: [
      trigger( 'slideIn', [
         transition( ':enter', [
            style({ width: 0, 'margin-left': 0 }),
            animate( '300ms ease-in-out', style({ width: '*', 'margin-left': '*' }))
         ]),
         transition( ':leave', [
            animate( '300ms ease-in-out', style({ width: 0, 'margin-left': 0 }))
         ]),
      ]),
   ],
})
export class TrackingComponent implements OnInit, OnDestroy {
   @Input() show = false;
   @Input() showLabel = false;
   @Output() active: EventEmitter<boolean> = new EventEmitter();

   @ViewChild('trackInput') trackInput: ElementRef;

   trackItem = TrackItem;

   track_value: string;
   _tracking = false;
   set tracking( value: boolean ) {
      this._tracking = value;
      if ( value ) {
         this.active.emit( true );
         setTimeout( _ => this.trackInput && this.trackInput.nativeElement.focus(), 310 );
      } else {
         this.active.emit( false );
      }
   }
   get tracking() { return this._tracking; }

   constructor(
      public router: Router,
      public breadcrumbs: BreadcrumbService,
      private ks: KeyboardShortcutsService,
   ) { }

   ngOnInit(): void {
      if ( this.show ) {
         this.ks.register$({
            description: 'Track a Shipment',
            key: 'T',
            keyDisplay: 'Shift + T',
            svgIcon: 'track',
         }).subscribe( _ => this.tracking = true );

         this.ks.escape$.subscribe( _ => {
            this.tracking = false;
            this.track_value = null;
         });
      }
   }

   ngOnDestroy() {
      this.ks.remove( 'T' );
   }

   track(): void {
      const value = this.track_value.trim();
      if ( value ) {
         this.breadcrumbs.newCrumb({ label: 'Tracking > ' + value, path: '/shipments' });
         this.router.navigate([ 'redirect', '/shipments/' + value ], { skipLocationChange: true });
         this.tracking = false;
         this.track_value = null;
      }
   }
}
