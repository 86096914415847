import { Injectable } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

import { filter, tap } from 'rxjs/operators';

import { BreakpointService, KeyboardShortcutsService } from '@library/core/services';
import { KeyboardShortcutsComponent } from '../keyboard-shortcuts';

@Injectable({
  providedIn: 'root'
})
export class KeyboardShortcutsDisplayService {

   private overlayRef: OverlayRef;

   constructor(
      private breakpoints: BreakpointService,
      private overlay: Overlay,
      private ks: KeyboardShortcutsService,
   ) {
      this.ks.register$({
         description: 'Show Keyboard Shortcuts',
         key: '?',
         keyDisplay: 'Shift + ?',
         icon: 'keyboard',
      }).pipe(
         filter( _ => this.breakpoints.large ),
         tap( _ => this.close() ),
      ).subscribe( _ => this.showKeyboardShortcuts() );

      this.ks.escape$.subscribe( _ => this.close() );
   }

   showKeyboardShortcuts() {
      this.overlayRef = this.overlay.create({ hasBackdrop: true });
      this.overlayRef.backdropClick().subscribe( _ => this.close() );
      const portal = new ComponentPortal( KeyboardShortcutsComponent );
      this.overlayRef.attach( portal );
   }

   close() {
      this.overlayRef?.dispose();
   }
}
