import * as faker from 'faker';

import { POD } from '../base';

export class MockPOD extends POD {
   constructor() {
      super();

      this.date = faker.date.recent();
      this.time = faker.fake(`{{datatype.number(24)}}:{{datatype.number(60)}}`);
      this.timezone = 'UTC';
      this.name = faker.name.findName();
      this.pieces = faker.datatype.number( 100 );
   }
}
