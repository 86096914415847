/**
 * {
 *    label ([ string, string? ]) - label to be displayed [ two strings can be used to display i8n-like values ]
 *    code (string[]) - passed to the filterList function as the code parameter
 *    [tooltip] (string) - optional tooltip
 *    [count] (number) - how many times the filtered item shows up in the list, should be set in your filterBy function
 *    [svgIcon] (string) - the svg icon to be displayed next to the label
 *    [icon] (string) - the materials icon to be displayed next to the label
 *    [sublabel] (string) - subtext to be displayed beneath the label
 *    [amount] ({ total: number, currency: string }) - for amount filters, will pass through the currencyCode pipe
 *    [selected] (boolean) - is the current filter selected [ used when Filter.selectable is true ]
 * }
 */
export interface FilterBy {
   /** two strings can be used to display i8n-like values */
   label: string[];
   /** used in the filterList function */
   code: string[];
   tooltip?: string;
   count?: number;
   svgIcon?: string;
   icon?: string;
   sublabel?: string;
   amount?: { total: number, currency: string };
   selected?: boolean;
}

export interface FilterBase {
   id: string; // unique
   title?: string;
   value?: string;
   hide?: boolean;
   active?: FilterBy;
}

export type FilterByFunction<T> = ( list: T[], value?: string, data?: any ) => FilterBy[];
export type FilterList<T> = ( list: T[], value: string, data?: string ) => T[];

/**
 * id (string) *unique* - The id of the filter
 * order (number) - -1: forced active value, 0: default, 1+: sort order in list
 * title (string) - Displayed title of the filter
 *
 * filterBy (function) - the function to calculate each FilterBy to be displayed in the sidebar
 *    / ( list: T[], value?: string, data?: any ) => FilterBy[]
 * filterList (function) - the function that actually filters the list
 *    / ( list: T[], value: string, data?: string ) => T[]
 * [items] (FilterBy[]) - and array of initial FilterBy objects that exist before the filterBy function is called
 * [data] (any) - data to be passed to the filterBy and filterList functions
 * [active] (boolean) - The current active FilterBy
 *
 * [matchValue] (boolean) - should the value input show up in the sidebar, will be passed as value to FilterByFunction
 * [value] (string) - optional value to be used in the filterBy function
 *
 * [hard] (boolean) - whether or not the filter can be customized
 * [hide] (boolean) - whether or not the filter is hidden in the sidebar
 * [selectable] (boolean) - use if filter should have checkboxes
 */
export interface Filter<T> extends FilterBase {
   /** -1: forced active value, 0: default, 1+: sort order in list */
   order: number;
   /** false: this filter can be customized, true: customization not allowed */
   hard?: boolean;
   matchValue?: boolean;
   items?: FilterBy[];
   selectable?: boolean;
   data?: any;

   /** (params) ( list: T[], value?: string, data?: any ) => FilterBy[] */
   filterBy: FilterByFunction<T>;
   /** (params) ( list: T[], value: string, data?: string ) => T[] */
   filterList: FilterList<T>;
}

export function FilterToFilterBase( filter: Filter<any>, defaults: Filter<any>[] = [] ): FilterBase {
   const current = defaults.filter( f => f.id === filter.id )[0];
   const fb: FilterBase = { id: filter.id };
   if ( filter.title && filter.title !== current.title ) { fb.title = filter.title; }
   if ( filter.value && filter.value !== current.value ) { fb.value = filter.value; }
   if ( filter.hide ) { fb.hide = filter.hide; }

   return fb;
}

export function FilterBaseToFilter( base: FilterBase, list: Filter<any>[] ): Filter<any> {
   const filter = list.filter( f => f.id === base.id );
   if ( filter.length && filter.length === 1 ) {
      return ({ ...filter[0], ...base });
   }
}
