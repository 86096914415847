import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormArray } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material/expansion';
import { BreakpointService } from '@library/core';
import { Attachment } from '@library/common';
import { Observable } from 'rxjs';
import { AttachmentService } from '../attachment.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'lib-attachment-display',
  templateUrl: './attachment-display.component.html',
  styleUrls: ['./attachment-display.component.scss']
})
export class AttachmentDisplayComponent implements OnInit {

   @Input() readonly attachments: Attachment[];
   @Input() readonly attachmentTypesEnum: Observable<string[]>;

   @Input() readonly updateForm: FormArray;
   @Input() readonly allowNewAttachments: boolean = false; // allows the addition of new attachments

   @Output() save: EventEmitter<Attachment[]> = new EventEmitter();

   @ViewChild( MatExpansionPanel ) panel: MatExpansionPanel;

   constructor(
      public breakpoints: BreakpointService,
      public attachmentService: AttachmentService,
   ) { }

   ngOnInit(): void {
      this.attachmentService.savingState = '';

      this.updateForm.valueChanges.pipe(
         filter( _ => this.attachmentService.savingState !== 'saving' ),
      ).subscribe( _ => {
         this.attachmentService.savingState = this.updateForm?.length ? 'show' : '';
      });

      if ( this.allowNewAttachments ) {
         this.attachmentTypesEnum.subscribe(); // preload enums
      }
   }

   attachFile( event ): void {
      const files: FileList = event.target.files;

      this.attachmentService.getFormArray( files ).subscribe( attachments => {
         attachments.controls.forEach( a => this.updateForm.push( a ));
      }, err => {
         console.error( err );
      });
   }
}
