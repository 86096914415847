import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

export interface Breadcrumb {
    label: string;
    path?: string;
}

@Injectable({
   providedIn: 'root'
})
export class BreadcrumbService {

   breadcrumbs: Set<Breadcrumb> = new Set();
   history: Set<Breadcrumb> = new Set();

   constructor( private router: Router ) { }

   loaf( crumbs: Breadcrumb[] ): BreadcrumbService {
      this.reset();

      crumbs.map( b => this.addCrumb( b ));

      return this;
   }

   newCrumb( b: Breadcrumb ): BreadcrumbService {
      this.reset();
      this.addCrumb( b );

      return this;
   }

   addCrumb( b: Breadcrumb ): BreadcrumbService {
      if ( !b.path ) {
         b.path = this.router.url;
      }
      this.breadcrumbs.add( b );
      this.history.add( b );

      return this;
   }

   addCrumble( label: string ): BreadcrumbService {
      this.addCrumb({ label });

      return this;
   }

   goToCrumb( b: Breadcrumb ): void {
      if ( this.router.url === b.path ) { return; }
      this.breadcrumbs.clear();
      this.router.navigate([ b.path ]);
   }

   reset(): void {
      this.breadcrumbs.clear();
      this.history.clear();
   }
}
