import { Injectable } from '@angular/core';

import { EnumsService } from './enums.service';

@Injectable({
   providedIn: 'root'
})
export class PrefetchService {

   items: string[] = [];

   constructor(
      private enums: EnumsService,
   ) { }

   prefetch() {
      if ( !this.items.length ) { return; }

      this.enums.prefetch( this.items );
   }
}
