import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';

import { trigger, state, style, transition, animate } from '@angular/animations';

import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { UseraccessService } from '@library/modules/user';

import { NavMenuService } from '../nav-menu.service';

@Component({
   selector: 'lib-menu-xsmall',
   templateUrl: './menu-xsmall.component.html',
   styleUrls: ['./menu-xsmall.component.scss'],
   animations: [
      trigger( 'slideInOut', [
         state( 'out', style({ transform: 'translateY(-100%)', height: 0 })),
         transition( 'out -> in', [
            style({ transform: 'translateY(-100%)', height: '*' }),
            animate( '300ms', style({ transform: 'translateY(0)' }))
         ]),
         transition( 'in -> out', [
            animate( '300ms', style({ transform: 'translateY(-100%)' }))
         ]),
      ]),
   ],
})
export class MenuXsmallComponent implements OnInit, OnDestroy {

   @Input() showTracking = false;

   opened = false;

   track_value: string;

   _onDestroy: Subject<void> = new Subject();

   constructor(
      private router: Router,
      public nav: NavMenuService,
      public access: UseraccessService,
   ) { }

   ngOnInit() {
      this.router.events.pipe(
         filter( event => event instanceof NavigationStart ),
         takeUntil( this._onDestroy ),
      ).subscribe( _ => this.opened = false );
   }

   ngOnDestroy() {
      this._onDestroy.next();
      this._onDestroy.complete();
   }

   track(): void {
      const value = this.track_value.trim();
      if ( value ) {
         this.router.navigate([ 'redirect', '/shipments/' + value ], { skipLocationChange: true });
         this.opened = false;
      }
   }
}
