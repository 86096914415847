import { DimUomUnits, PiecesUnits, WeightUomUnits } from '@library/common/models';

export interface CartageDetailSubItem {
   pieces: number;
   piecesUom: PiecesUnits;

   length?: number;
   width?: number;
   height?: number;
   dimUom?: DimUomUnits;

   weight: number;
   weightUom: WeightUomUnits;

   description?: string;
   reference?: string;
}

export class CartageDetailSubItem implements CartageDetailSubItem {
   constructor( c?: CartageDetailSubItem) {
      this.pieces = c?.pieces;
      this.piecesUom = c?.piecesUom;

      if ( c?.length ) { this.length = c?.length; }
      if ( c?.width ) { this.width = c?.width; }
      if ( c?.height ) { this.height = c?.height; }
      if ( c?.dimUom ) { this.dimUom = c?.dimUom; }

      this.weight = c?.weight;
      this.weightUom = c?.weightUom;

      if ( c?.description ) { this.description = c?.description; }
      if ( c?.reference ) { this.reference = c?.reference; }
   }
}

export interface CartageDetailItem {
   hawb: string;

   pieces: number;
   piecesUom: PiecesUnits;

   weight: number;
   weightUom: WeightUomUnits;

   subItems: CartageDetailSubItem[];

   pickupFromDate?: string;
   pickupToDate?: string;
   pickupFromTime?: string;
   pickupToTime?: string;

   deliveryFromDate?: string;
   deliveryToDate?: string;
   deliveryFromTime?: string;
   deliveryToTime?: string;
}

export class CartageDetailItem implements CartageDetailItem {
   constructor( c?: CartageDetailItem ) {
      this.hawb = c?.hawb;

      this.pieces = c?.pieces;
      this.piecesUom = c?.piecesUom;

      this.weight = c?.weight;
      this.weightUom = c?.weightUom;

      this.subItems = c?.subItems;

      if ( c?.pickupFromDate ) { this.pickupFromDate = c?.pickupFromDate; }
      if ( c?.pickupToDate ) { this.pickupToDate = c?.pickupToDate; }
      if ( c?.pickupFromTime ) { this.pickupFromTime = c?.pickupFromTime; }
      if ( c?.pickupToTime ) { this.pickupToTime = c?.pickupToTime; }

      if ( c?.deliveryFromDate ) { this.deliveryFromDate = c?.deliveryFromDate; }
      if ( c?.deliveryToDate ) { this.deliveryToDate = c?.deliveryToDate; }
      if ( c?.deliveryFromTime ) { this.deliveryFromTime = c?.deliveryFromTime; }
      if ( c?.deliveryToTime ) { this.deliveryToTime = c?.deliveryToTime; }
   }
}
