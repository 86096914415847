import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { MaterialsModule } from '@library/common/materials';
import { DirectivesModule } from '@library/core/directives';
import { PipesModule } from '@library/core/pipes';
import { SaveButtonModule } from '@library/modules/save-button';
import { ErrorsModule } from '@library/modules/errors';

import { AttachmentCardComponent } from './attachment-card/attachment-card.component';
import { AttachmentDisplayComponent } from './attachment-display/attachment-display.component';
import { AttachmentCreateComponent } from './attachment-create/attachment-create.component';
import { AttachmentSelectComponent } from './attachment-select/attachment-select.component';

@NgModule({
   declarations: [
      AttachmentCardComponent,
      AttachmentDisplayComponent,
      AttachmentCreateComponent,
      AttachmentSelectComponent,
   ],

   imports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule,

      MaterialsModule,

      DirectivesModule,

      PipesModule,

      SaveButtonModule,
      ErrorsModule,
   ],

   exports: [
      AttachmentCardComponent,
      AttachmentDisplayComponent,
      AttachmentCreateComponent,
      AttachmentSelectComponent,
   ],
})
export class AttachmentModule { }
