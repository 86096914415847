export function average( values: number[] ): number {
   return Math.round( values.map( v => v ).reduce(( a, b ) => a += b ) / values.length );
}

export function standardDeviation( values: number[] ): number {
   const avg = average(values);
   const squareDiffs = values.map( v => Math.pow( v - avg, 2 ));
   const avgSquareDiff = squareDiffs.map( d => d ).reduce(( a, b) => a += b ) / squareDiffs.length;

   return Math.round(Math.sqrt(avgSquareDiff));
}

// return an array with numbers filled into a lower, middle and upper band based off the idea of a normal distribution
// adjust bias with a value between 0 and 1 to change shape of curve. 1 being closest to a normal distribution
export function getDeviationBands( values: number[], bias: number = 1 ): any[] {
   if ( values.length === 1 ) { return [ [], [ values[0] ], [] ]; }

   const avg = average( values );
   const sd = standardDeviation( values );

   const lowerBand = values.filter( v => v <= avg - bias * sd );
   const middleBand = values.filter( v => v > avg - bias * sd && v < avg + bias * sd );
   const upperBand = values.filter( v => v >= avg + bias * sd );

   return [ lowerBand, middleBand, upperBand ];
}
