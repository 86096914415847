import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { trigger, transition, style, animate } from '@angular/animations';
import { Router, NavigationStart } from '@angular/router';

import { filter } from 'rxjs/operators';

import {
   BreakpointService,
   BreadcrumbService,
   SearchService,
   KeyboardShortcutsService,
} from '@library/core/services';

@Component({
   selector: 'lib-activity-bar',
   templateUrl: './activity-bar.component.html',
   styleUrls: ['./activity-bar.component.scss'],
   animations: [
      trigger( 'slideIn', [
         transition( ':enter', [
            style({ width: 0, 'margin-left': 0 }),
            animate( '300ms ease-in-out', style({ width: '*', 'margin-left': '*' }))
         ]),
         transition( ':leave', [
            animate( '300ms ease-in-out', style({ width: 0, 'margin-left': 0 }))
         ]),
      ]),
   ],
})
export class ActivityBarComponent implements OnInit {

   searchHover = false;
   searchActive = false;
   searchValue: FormControl;

   @ViewChild('searchInput') searchInput: ElementRef;

   constructor(
      public breadcrumbs: BreadcrumbService,
      public breakpoints: BreakpointService,
      public search: SearchService,
      private router: Router,
      private ks: KeyboardShortcutsService,
   ) { }

   ngOnInit() {
      this.searchValue = this.search.text;

      this.router.events.pipe(
         filter( event => event instanceof NavigationStart ),
      ).subscribe( _ => this.toggleSearchOff() );

      this.ks.register$({
         description: 'Search',
         key: 'S',
         keyDisplay: 'Shift + S',
         icon: 'search'
      }).pipe( filter( _ => this.search.showSearch ))
      .subscribe( _ => this.toggleSearchOn() );

      this.ks.escape$.subscribe( _ => this.toggleSearchOff() );
   }

   toggleSearchOn() {
      if ( !this.search.showSearch ) { return; }
      this.searchHover = false;
      this.searchActive = true;
      setTimeout( _ => this.searchInput && this.searchInput.nativeElement.focus(), 1 );
   }

   toggleSearchOff() {
      this.searchActive = false;
      this.searchValue.reset();
   }
}
