import { Injectable } from '@angular/core';
import { FormGroup, FormArray, Validators } from '@angular/forms';

import { startWith, takeWhile, filter } from 'rxjs/operators';

import { filesizeValidator, minDateValidator } from './validators';

import { ConfigMap, FormItemMap, ConfigKey, ExtendedKey } from './configs';
import { AppConfig, FormItem, AppConfigProperty } from './app.config';

@Injectable({
   providedIn: 'root'
})
export class AppConfigService {

   private _configs = ConfigMap;
   private _formItems = FormItemMap;

   private _config: AppConfig;
   get config() { return this._config; }

   constructor() {
      this.load();
   }

   load( config: ConfigKey = 'default' ) {
      if ( !this._configs[config] ) {
         config = 'default';
      }

      this._config = this.loadConfig( config );

      console.log( 'loaded opts', this._config );
   }

   private loadConfig( config: ConfigKey ) {
      const opts = this._configs[config];

      opts.booking = this.mergeItems( opts.booking );

      if ( opts.extends ) {
         const extended = this.loadConfig( opts.extends as ConfigKey );

         // merge booking
         opts.booking = this.mergeItems( extended.booking, opts.booking );
      }

      return opts;
   }

   private mergeItems( orig: FormItem[] = [], extended: FormItem[] = [] ) {
      const merged = [ ...orig ];

      merged.filter( obj => obj.extends ).map( obj => {
         obj.items = this.mergeItems( this._formItems[ obj.extends ], obj.items );
      });

      extended.map( obj => {
         const origItem = orig.filter( o => o.id === obj.id )[0];
         if ( origItem ) {
            const mergedItem = { ...origItem, ...obj };

            if ( obj?.extends ) {
               obj.items = this.mergeItems( obj.items, this._formItems[ obj.extends ] );
            }

            if ( obj?.items?.length ) {
               const items = this.mergeItems( origItem.items, obj.items );
               mergedItem.items = items;
            }

            merged.splice( merged.indexOf( origItem ), 1, mergedItem );
         }
      });

      return merged;
   }

   getExtendedConfig( property: ExtendedKey ): FormItem[] {
      return this._formItems[property];
   }

   getProperty( items: FormItem[], property: string ): AppConfigProperty {
      const properties = {};

      items?.filter( item => item[property] ).map( item => properties[item.id] = item[property] );

      items?.filter( item => item.extends || item.items )
         .map( item => properties[ item.id ] = this.getProperty( item.items, property ));

      return properties;
   }

   setValidators( group: FormGroup, config: FormItem[], update = true ): FormGroup {
      config.map( c => {
         const control = group.get( c.id );

         if ( !control ) { return; }

         if ( c?.display === false ) {
            // For display false we are just removing the control
            group.removeControl( c.id );

            return;
         }

         if ( c?.default ) { control.setValue( c.default ); }
         if ( c?.disable ) { control.disable(); }

         // let's create the validators for the loaded control
         const validators = [];
         if ( c?.required ) { validators.push( Validators.required ); }
         if ( c?.requireTrue ) { validators.push( Validators.requiredTrue ); }
         if ( c?.pattern ) { validators.push( Validators.pattern( c?.pattern )); }
         if ( c?.email ) { validators.push( Validators.email ); }
         if ( c?.min ) { validators.push( Validators.min( c?.min )); }
         if ( c?.max ) { validators.push( Validators.max( c?.max )); }
         if ( c?.minLength ) { validators.push( Validators.minLength( c?.minLength )); }
         if ( c?.maxLength ) { validators.push( Validators.maxLength( c?.maxLength )); }

         // custom validators
         if ( c?.filesize ) { validators.push( filesizeValidator( c?.filesize )); }
         if ( c?.minDate ) { validators.push( minDateValidator( group.get( c?.minDate ))); }

         // here is where we set the vaidators on the loaded control
         if ( validators.length ) {
            // console.log( 'setting validators', c, validators );
            control.setValidators( validators );
         }

         // checking for sub-items in the config
         if ( c?.items ) {
            if ( control instanceof FormGroup ) {
               // recursive setting of config from here
               this.setValidators( control, c?.items );
            }

            if ( control instanceof FormArray ) {
               let arrayLength = 0;
               control.valueChanges.pipe(
                  startWith( control.value ),
                  filter( value => value.length !== arrayLength ),
                  takeWhile( _ => group?.contains( c.id )),
               ).subscribe( value => {
                  arrayLength = value.length;
                  value.map(( _, i ) => this.setValidators( control.at( i ) as FormGroup, c.items, false ));
               });
            }
         }

         if ( c?.at && control instanceof FormArray ) {
            c.at.map( at => {
               if ( at.disable ) {
                  control.at( at.index )?.disable();
               }
            });
         }
      });

      if ( update ) {
         group.updateValueAndValidity();
      }

      return group;
   }
}
