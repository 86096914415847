import * as faker from 'faker';
import { optional } from '@library/common/functions';

import { Contact, ContactLengths } from '../base';

export class MockContact extends Contact {
   constructor() {
      super();

      this.id = faker.datatype.number();
      this.pinned = faker.datatype.boolean();
      if ( this.pinned && optional() ) { this.default = faker.datatype.boolean(); }
      if ( optional() ) { this.name = faker.name.findName().slice( 0, ContactLengths['name'] ); }
      if ( optional() ) { this.title = faker.name.title().slice( 0, ContactLengths['title'] ); }
      if ( optional() ) { this.phone = faker.phone.phoneNumber().slice( 0, ContactLengths['phone'] ); }
      if ( optional() ) { this.email = faker.internet.email().slice( 0, ContactLengths['email'] ); }
   }
}
