import { Component, OnInit } from '@angular/core';

import { BrandingService } from '@library/common/branding';

import { Preferences, EnvironmentService } from '@library/core/services';

@Component({
   selector: 'lib-theme-switcher',
   templateUrl: './theme-switcher.component.html',
   styleUrls: ['./theme-switcher.component.scss']
})
export class ThemeSwitcherComponent implements OnInit {
   constructor(
      public branding: BrandingService,
      public prefs: Preferences,
      public environment: EnvironmentService,
   ) { }

   ngOnInit() {
      if ( !this.environment.production ) {
         if ( this.prefs.getPref( 'theme' )) {
            this.branding.setTheme( this.prefs.getPref( 'theme' ));
         }
      }
   }

   loadTheme( theme: string ) {
      this.branding.setTheme( theme );
      this.prefs.setPref({ theme });
   }
}
