import { Injectable } from '@angular/core';

@Injectable({
   providedIn: 'root'
})
export class TextService {

   constructor() { }

   loginTitle = `Portal Login`;

   loginSubTitle = '';
}
