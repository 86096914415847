<ng-template #spinner>
   <mat-spinner diameter="24"></mat-spinner>
</ng-template>

<div class="attachments" *ngFor="let attachment of form.controls; index as i; first as first; last as last">
   <div class="item-wrapper" [formGroup]="attachment">
      <div class="type">
         <mat-form-field *ngIf="attachmentTypesEnum | async as types else spinner">
            <mat-label>Type</mat-label>
            <mat-select formControlName="type" required>
               <mat-option *ngFor="let type of types" [value]="type">{{type}}</mat-option>
            </mat-select>
         </mat-form-field>
      </div>

      <mat-form-field floatLabel="always" class="name">
         <mat-label>Name</mat-label>
         <input [id]="'name' + i.toString()" matInput formControlName="name" [placeholder]="attachment.get( 'filename' ).value" [maxlength]="attachmentService?.lengths?.name">
         <mat-error><lib-input-error [control]="attachment.get( 'name' )"></lib-input-error></mat-error>
         <mat-hint align="start" *ngIf="attachment.get( 'name' ).value">{{attachment.get( 'filename' ).value}}</mat-hint>
         <mat-hint align="end">
            <ng-container *ngIf="elementFocused('name' + i) && attachmentService?.lengths?.name && attachment.get('name')?.value?.length as l; else noFocus">
               <span>{{l || 0}} / {{attachmentService?.lengths?.name}}</span>
            </ng-container>

            <ng-template #noFocus>
               <span>{{attachment.get('size').value | filesize}}</span>
            </ng-template>
         </mat-hint>
      </mat-form-field>

      <div *ngIf="clearable" class="button">
         <button mat-icon-button class="warn-button" matTooltip="REMOVE ATTACHMENT" matTooltipPosition="before" (click)="form.removeAt( i )" tabindex="-1">
            <mat-icon>clear</mat-icon>
         </button>
      </div>
   </div>

   <mat-divider *ngIf="!last"></mat-divider>
</div>

<div class="add-button">
   <button mat-stroked-button color="accent" (click)="uploader.click()">
      <mat-icon>add</mat-icon> ADD ATTACHMENT
   </button>
</div>

<input hidden type="file" #uploader (change)="attachFile($event)" [accept]="attachmentService.allowedMimetypes.join(', ')" multiple="true">
