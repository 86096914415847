import * as faker from 'faker';

import { MockDomesticShipment } from './domestic-shipment.mock';
import {
   MockInternationalRoadShipment,
   MockInternationalAirShipment,
   MockInternationalOceanShipment,
} from './international-shipment.mock';

import {
   Shipment,
   ShipmentTypes,
   ShipmentModes,
} from '../shipment';

/**
 * default behavior is domestic road
 *
 * load => returns a data model with passed in shipment information
 * mock => returns a data model with mock data
 * book => returns a sanitized model for booking
 * group => returns the FormGroup for the requested model
 */
export class ShipmentFactory {
   static mock( s: any = {} ): Shipment<any, any> {
      const t = s['shipType'] || faker.random.arrayElement( ShipmentTypes );
      const m = s['mode'] || faker.random.arrayElement( ShipmentModes );
      switch ( t ) {
         case 'international':
            switch ( m ) {
               case 'ocean':
                  return new MockInternationalOceanShipment();
               case 'air':
                  return new MockInternationalAirShipment();
               case 'road':
               default:
                  return new MockInternationalRoadShipment();
            }
         case 'domestic':
         default:
            return new MockDomesticShipment();
      }
   }
}
