import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { ErrorMessage } from '@library/common/http';

let ID = 0;

@Injectable({
   providedIn: 'root',
})
export class ErrorMessageService {
   messages: BehaviorSubject<ErrorMessage[]> = new BehaviorSubject( [] );

   get none(): boolean {
      return !this.messages.getValue().length;
   }

   reset(): void {
      this.messages.next( [] );
   }

   resetErrors(): void {
      this.messages.next( this.messages.getValue().filter( e => e.type !== 'error' ));
   }

   clear( id: number ): void {
      const ms = this.messages.getValue();
      this.messages.next( ms.filter( m => m.id !== id ));
   }

   push( message: string ): void {
      const current = this.messages.getValue();
      if ( current.map( e => e.message ).includes( message )) { return; }

      current.push( new ErrorMessage({
         id: ++ID,
         type: 'error',
         code: 'Default',
         message
      }));

      this.messages.next( current );
   }

   add( message: ErrorMessage ): void {
      const current = this.messages.getValue();
      if ( current.map( e => e.message ).includes( message.message )) { return; }

      this.messages.next([ ...current, ({ ...message, id: ++ID }) ]);
   }
}
