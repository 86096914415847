import { Component, OnInit, Input } from '@angular/core';
import { FormArray } from '@angular/forms';
import { BreakpointService } from '@library/core/services';
import { CartageEnumsService } from '../../cartage.enums.service';
import { CartageItemTypes, CartageStatusGroup } from '../../models';

import { validateTime } from '@library/common/functions';
import { AmazingTimePickerService } from 'amazing-time-picker';

@Component({
  selector: 'app-tracking-update',
  templateUrl: './tracking-update.component.html',
  styleUrls: ['./tracking-update.component.scss', '../cartage-update.scss'],
})
export class TrackingUpdateComponent implements OnInit {

   @Input() readonly form: FormArray;
   @Input() readonly type: CartageItemTypes;
   @Input() readonly clearable: boolean = true;

   constructor(
      public enums: CartageEnumsService,
      public breakpoints: BreakpointService,

      private atp: AmazingTimePickerService,
   ) { }

   ngOnInit(): void {
      console.log('Type: ', this.type);

      this.enums.statusTypes( this.type ).subscribe( s => {
         console.log('Statuses: ', s);
      });
   }

   addGroup(): void {
      this.form.push( CartageStatusGroup() );
   }

   openClock( index: number ): void {
      const f = this.form.controls[ index ].get( 'time' );
      let time = f.value || '00:00';

      if ( !validateTime( f.value )) { time = '00:00'; }

      const timepicker = this.atp.open({ time });
      if ( timepicker ) {
         timepicker.afterClose().subscribe( t => {
            f.setValue( t );
         });
      }
   }
}
